import { ViewWrapper } from '../shared/layout/ViewWrapper'
import { OptimizationTotalSummary } from './OptimizationTotalSummary'
import { OptimizationTabs } from './tabs/OptimizationTabs'
import { OptimizationFilters } from './OptimizationFilters'
import { useContext, useState } from 'react'
import { FilterOption } from '../shared/filters/FilterSelect'
import { CurrencyFilterOption, getVendorFilterOptions } from '../shared/filters/Filters'
import { Vendor } from '../../utils/vendors'
import { CurrencyId, getCurrency } from '../../utils/Currency'
import { UserSettingsContext, UserSettingsState } from '../../state/context/UserSettingsContext'

export const Optimization = () => {
  const { userSettings } = useContext<UserSettingsState>(UserSettingsContext)
  const [selectedVendors, setSelectedVendors] = useState<FilterOption[]>([])
  const [selectedProjects, setSelectedProjects] = useState<FilterOption[]>([])
  const [selectedRecommendations, setSelectedRecommendations] = useState<FilterOption[]>([])
  const [selectedCurrency, setSelectedCurrency] = useState<CurrencyFilterOption>({
    value: userSettings.currency,
    label: getCurrency(userSettings.currency).symbolLong
  })

  if (!selectedVendors.length) setSelectedVendors(getVendorFilterOptions())

  return (
    <ViewWrapper>
      <OptimizationFilters
        selectedVendors={selectedVendors}
        setSelectedVendors={setSelectedVendors}
        selectedProjects={selectedProjects}
        setSelectedProjects={setSelectedProjects}
        selectedRecommendations={selectedRecommendations}
        setSelectedRecommendations={setSelectedRecommendations}
        selectedCurrency={selectedCurrency}
        setSelectedCurrency={setSelectedCurrency}
      />
      <OptimizationTotalSummary
        selectedVendors={selectedVendors.map(vendor => vendor.value as Vendor)}
        selectedProjects={selectedProjects.map(project => project.value)}
        selectedRecommendations={selectedRecommendations.map(recommendation => recommendation.value)}
        selectedCurrency={selectedCurrency.value as CurrencyId}
      />
      <OptimizationTabs
        selectedVendors={selectedVendors.map(vendor => vendor.value as Vendor)}
        selectedProjects={selectedProjects.map(project => project.value)}
        selectedRecommendations={selectedRecommendations.map(recommendation => recommendation.value)}
        selectedCurrency={selectedCurrency.value as CurrencyId}
      />
    </ViewWrapper>
  )
}
