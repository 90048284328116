export enum NotificationsTab {
  INBOX = 'INBOX',
  DELETED = 'DELETED'
}

export enum CostNotificationDataKey {
  VENDOR = 'costNotificationVendor',
  PROJECT_ID = 'costNotificationProjectId',
  PROJECT_NAME = 'costNotificationProjectName',
  SERVICE = 'costNotificationService'
}
