import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useErrorHandling } from '../../hooks/handleError'
import { useCancelToken } from '../../api/client'
import { getTicketSummary, TicketSummary } from '../../api/tickets'
import { Loading } from '../shared/Loading'
import { HeaderCounter, HeaderCountersWrapper, PageHeaderWrapper, PageTitle } from '../shared/PageHeaderComponents'
import { InfoTooltip } from '../shared/tooltips/InfoTooltip'
import { useMousePosition } from '../../hooks/useMousePosition'
import { MissingDataNotification } from '../shared/MissingDataNotification'

export const TicketsHeader = () => {
  const handleError = useErrorHandling()
  const { t } = useTranslation()
  const { createCancelToken } = useCancelToken()
  const mousePos = useMousePosition()
  const [ticketCount, setTicketCount] = useState<TicketSummary | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(true)
    const cancelToken = createCancelToken()
    getTicketSummary(cancelToken.token)
      .then(setTicketCount)
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      cancelToken.cancel()
      setLoading(false)
      setTicketCount(null)
    }
  }, [setTicketCount, handleError, createCancelToken])

  if (loading || !ticketCount)
    return (
      <PageHeaderWrapper>
        {loading ? <Loading paddingY={'1rem'} /> : <MissingDataNotification justify={'center'} />}
      </PageHeaderWrapper>
    )

  return (
    <PageHeaderWrapper>
      <div className={'flex flex-col gap-4'}>
        <div className={'flex gap-4 items-center'}>
          <PageTitle>{t('tickets.header.title')}</PageTitle>
          <InfoTooltip useIcon={true} styles={'max-w-80'} x={mousePos.x} y={mousePos.y}>
            {t('tickets.header.info')}
          </InfoTooltip>
        </div>
        <HeaderCountersWrapper>
          <HeaderCounter value={ticketCount.open} description={t('tickets.ticketStatus.open')} />
          <HeaderCounter value={ticketCount.pending} description={t('tickets.ticketStatus.pending')} />
          <HeaderCounter value={ticketCount.resolved} description={t('tickets.ticketStatus.resolved')} />
          <HeaderCounter value={ticketCount.closed} description={t('tickets.ticketStatus.closed')} />
        </HeaderCountersWrapper>
      </div>
    </PageHeaderWrapper>
  )
}
