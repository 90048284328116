import React from 'react'
import { TreeBranch } from '../../../shared/TreeBranch'
import { Vendor } from '../../../../utils/vendors'
import { useTranslation } from 'react-i18next'
import { OptimizationTabCollapsibleSummary, OptimizationTabTitleRow } from '../OptimizationTabsSharedComponents'
import {
  DataTableCell,
  DataTableLabel,
  DataTableRow,
  DataTableValue,
  TabInfoBox
} from '../../../shared/DataTableSharedComponents'
import { ClipboardCopy } from '../../../shared/ClipboardCopy'
import { formatDate, formatNumber, roundNumber } from '../../../../utils/formats'
import { AwsUtilization, AwsUtilizationSummary } from '../../../../api/optimization/aws'
import { getCurrency } from '../../../../utils/Currency'
import { AlertIndicator, AlertType } from '../../../shared/indicators/AlertIndicator'

interface Props {
  data: AwsUtilizationSummary[]
}

export const AwsTabUtilization = ({ data }: Props) => {
  const { t } = useTranslation()
  return (
    <TreeBranch
      headerData={
        <OptimizationTabCollapsibleSummary
          headerLeft={t('optimization.tabs.common.resourceUtilization')}
          headerRight={t('optimization.tabs.common.utilizationHeaderSummary', { count: data.length })}
          useExclamationMark={true}
        />
      }
      contentData={<UtilizationChecks checks={data} />}
    />
  )
}

interface UtilizationCheckProps {
  checks: AwsUtilizationSummary[]
}

const UtilizationChecks = ({ checks }: UtilizationCheckProps) => {
  const { t } = useTranslation()
  return (
    <>
      {checks.map((check, index) => (
        <TreeBranch
          id={`qa-optimization-${Vendor.AWS}-tab-content-${index}`}
          depth={1}
          key={index}
          headerData={
            <OptimizationTabCollapsibleSummary
              headerLeft={check.checkName}
              headerRight={
                check.annualSavings && check.currency
                  ? t('optimization.tabs.aws.utilizationAnnualSavings', {
                      amount: formatNumber(check.annualSavings),
                      currency: getCurrency(check.currency).symbol
                    })
                  : t('optimization.tabs.common.utilizationSubHeaderSummary', {
                      count: check.recommendations.reduce((acc, curr) => acc + curr.flaggedResources, 0)
                    })
              }
              useExclamationMark={true}
            />
          }
          contentData={
            <>
              <div className={'pb-2'}>
                <CheckDescription recommendation={check} />
                <OptimizationTabTitleRow
                  widerIndex={[0]}
                  labels={[
                    t('vendors.AWS.projectPhrase_one'),
                    t('optimization.tabs.aws.flaggedResources'),
                    check.recommendations.some(r => r.monthlySavings) && t('optimization.tabs.common.monthlySavings'),
                    check.recommendations.some(r => r.annualSavings) && t('optimization.tabs.common.annualSavings'),
                    t('optimization.tabs.aws.status'),
                    t('optimization.tabs.aws.lastUpdated')
                  ]}
                />
              </div>
              {check.recommendations.map((details, i) => (
                <TreeBranch
                  key={i}
                  depth={2}
                  headerData={
                    <RecommendationRow
                      details={details}
                      hasSavings={check.recommendations.some(r => r.monthlySavings)}
                    />
                  }
                  contentData={false}
                />
              ))}
            </>
          }
        />
      ))}
    </>
  )
}

interface CheckDescriptionProps {
  recommendation: AwsUtilizationSummary
}

const CheckDescription = ({ recommendation }: CheckDescriptionProps) => {
  const { t } = useTranslation()

  return (
    <TabInfoBox
      textSections={[
        {
          text: recommendation.recommendation.label,
          links: recommendation.recommendation.resources
        },
        {
          text: recommendation.description.label,
          links: recommendation.description.resources
        },
        {
          text: `${t('optimization.tabs.aws.additionalResources')}:`,
          links: recommendation.additionalResources
        }
      ]}
    />
  )
}

interface RecommendationRowProps {
  details: AwsUtilization
  hasSavings: boolean
}

const RecommendationRow = ({ details, hasSavings }: RecommendationRowProps) => {
  const { t } = useTranslation()
  const currencySymbol = details.currency && getCurrency(details.currency).symbol
  const rowStyle = hasSavings ? 'grid-cols-1 xl:grid-cols-7' : 'grid-cols-1 xl:grid-cols-5'

  return (
    <DataTableRow styles={rowStyle}>
      <DataTableCell styles={'xl:col-span-2'}>
        <DataTableLabel>{t('vendors.AWS.projectPhrase_one')}</DataTableLabel>
        <DataTableValue>
          <ClipboardCopy copyText={details.account.name} secondCopyText={details.account.id} limit={30} />
        </DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.aws.flaggedResources')}</DataTableLabel>
        <DataTableValue>{details.flaggedResources}</DataTableValue>
      </DataTableCell>
      {hasSavings && (
        <>
          <DataTableCell>
            <DataTableLabel>{t('optimization.tabs.common.monthlySavings')}</DataTableLabel>
            <DataTableValue>
              {details.monthlySavings
                ? `${formatNumber(roundNumber(details.monthlySavings, 0))} ${currencySymbol}`
                : '-'}
            </DataTableValue>
          </DataTableCell>
          <DataTableCell>
            <DataTableLabel>{t('optimization.tabs.common.annualSavings')}</DataTableLabel>
            <DataTableValue>
              {details.annualSavings ? `${formatNumber(roundNumber(details.annualSavings, 0))} ${currencySymbol}` : '-'}
            </DataTableValue>
          </DataTableCell>
        </>
      )}
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.aws.status')}</DataTableLabel>
        <DataTableValue>
          <AlertIndicator type={details.status as AlertType} />
        </DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.aws.lastUpdated')}</DataTableLabel>
        <DataTableValue>{formatDate(details.updatedAt, true, true, true)}</DataTableValue>
      </DataTableCell>
    </DataTableRow>
  )
}
