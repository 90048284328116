import React, { createContext, useMemo, useState } from 'react'
import { AuthInfo, VendorIntegration } from '../../api/auth'

export interface AuthInfoState {
  authInfo: AuthInfo
  setAuthInfo: (user: AuthInfo) => void
}

const defaultVendorIntegrationState: VendorIntegration = { integration: false, lastIntegrationAt: null }

export const defaultAuthInfoState: AuthInfoState = {
  authInfo: {
    id: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    roles: [],
    tenantId: '',
    tenantName: '',
    isSsoEnabled: false,
    awsIntegration: defaultVendorIntegrationState,
    gcpIntegration: defaultVendorIntegrationState,
    azureIntegration: defaultVendorIntegrationState,
    site247Integration: undefined,
    freshIntegration: undefined,
    awsAccess: false,
    gcpAccess: false,
    azureAccess: false,
    costsAccess: false,
    optimizationAccess: false,
    complianceAccess: false,
    sustainabilityAccess: false,
    infraAccess: false,
    ticketAccess: false,
    notificationsAccess: false,
    lastLoginAt: null,
    isLoggedIn: false
  },
  setAuthInfo: () => undefined
}

export const AuthInfoContext = createContext<AuthInfoState>(defaultAuthInfoState)

interface Props {
  children: JSX.Element
}

export const AuthInfoContextProvider = React.memo(({ children }: Props) => {
  const [authInfo, setAuthInfo] = useState<AuthInfo>(defaultAuthInfoState.authInfo)
  const value = useMemo(
    () => ({
      authInfo,
      setAuthInfo
    }),
    [authInfo, setAuthInfo]
  )

  return <AuthInfoContext.Provider value={value}>{children}</AuthInfoContext.Provider>
})
