import { Button, ButtonSize, ButtonStyle, ButtonType } from './Button'
import { CustomIcon, IconType } from '../CustomIcon'
import exportCsv from '../../../assets/svg/actions/export-csv.svg'

interface ExportButtonProps {
  clickHandler: () => void
  text: string
  disabled?: boolean
  type?: ButtonType
}

export const ExportButton = ({ clickHandler, text, disabled, type }: ExportButtonProps) => {
  if (type === ButtonType.ICON)
    return (
      <Button
        buttonStyle={ButtonStyle.PRIMARY}
        value={<CustomIcon styles={'w-6 h-6 bg-gray-50'} path={exportCsv} alt={text} iconType={IconType.VECTOR} />}
        size={ButtonSize.MEDIUM}
        clickHandler={clickHandler}
        disabled={disabled}
        type={ButtonType.ICON}
      />
    )
  return (
    <Button
      buttonStyle={ButtonStyle.SECONDARY}
      value={text}
      size={ButtonSize.SMALL}
      clickHandler={clickHandler}
      disabled={disabled}
    />
  )
}
