import Select, { Props } from 'react-select'
import { FilterOption, FilterOptionContainer, filterSelectAllOption } from '../../shared/filters/FilterSelect'
import { CostAnomalyProjectLimits, CostAnomalyServiceLimits } from '../../../api/user/notification-settings'
import { Vendor } from '../../../utils/vendors'
import { useTranslation } from 'react-i18next'
import React, { useContext, useEffect, useState } from 'react'
import { LimitsByCostAnomalyState } from '../../../api/notifications'
import { CheckboxOption } from '../../shared/buttons/CheckboxOption'
import { LightGrayText } from '../../shared/TextComponents'
import {
  SelectClearIndicator,
  selectControlStyles,
  SelectDropdownIndicator,
  selectGroupHeadingStyles,
  SelectIndicatorSeparator,
  selectMenuListStyles,
  selectNoOptionsStyles,
  selectOptionStyles
} from '../../shared/filters/ReactSelectStyles'
import { CostAnomalySettingsInputs } from './CostAnomalySettingsInputs'
import { SwitchButton } from '../../shared/buttons/SwitchButton'
import styled from 'styled-components'
import tw from 'twin.macro'
import { UserSettingsContext } from '../../../state/context/UserSettingsContext'

interface MultiEditModalBodyProps extends Props {
  options: FilterOption[]
  entries: CostAnomalyProjectLimits[] | CostAnomalyServiceLimits[]
  setNewEntries: (entries: CostAnomalyProjectLimits[] | CostAnomalyServiceLimits[]) => void
  selectedVendor: Vendor
}

export const CostAnomalyMultiEditModal = ({
  options,
  entries,
  setNewEntries,
  placeholder,
  selectedVendor
}: MultiEditModalBodyProps) => {
  const allOptions = [filterSelectAllOption, ...options]
  const { t } = useTranslation()
  const { userSettings } = useContext(UserSettingsContext)
  const [percent, setPercent] = useState<LimitsByCostAnomalyState>(
    userSettings.defaultCostAnomalyLimits.dailyChangePercent
  )
  const [impact, setImpact] = useState<LimitsByCostAnomalyState>(userSettings.defaultCostAnomalyLimits.estMonthlyImpact)
  const [notificationsEnabled, setNotificationsEnabled] = useState(true)
  const [emailsSubscribed, setEmailsSubscribed] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState<FilterOption[]>([])
  const [inputValue, setInputValue] = useState<string>('')
  const [matchingOptions, setMatchingOptions] = useState<FilterOption[]>([])

  useEffect(() => {
    inputValue !== ''
      ? setMatchingOptions(
          options.filter(
            option =>
              option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
              option.value.toLowerCase().includes(inputValue.toLowerCase())
          )
        )
      : setMatchingOptions(options)
  }, [inputValue])

  useEffect(() => {
    selectedOptions.length
      ? setNewEntries(
          entries
            .filter(e =>
              'project' in e
                ? selectedOptions.map(o => o.value).includes(e.project.id)
                : selectedOptions.map(o => o.value).includes(e.service)
            )
            .map(e =>
              'project' in e
                ? {
                    ...e,
                    costNotificationsDisabledAt: notificationsEnabled ? null : new Date(),
                    isSubscribedToEmail: emailsSubscribed,
                    dailyChangePercent: percent,
                    estMonthlyImpact: impact
                  }
                : {
                    ...e,
                    isSubscribedToEmail: emailsSubscribed,
                    dailyChangePercent: percent,
                    estMonthlyImpact: impact
                  }
            ) as CostAnomalyProjectLimits[] | CostAnomalyServiceLimits[]
        )
      : setNewEntries([])
  }, [selectedOptions, emailsSubscribed, notificationsEnabled, percent, impact])

  // @ts-ignore
  const onInputChange = (query, { action }) => {
    if (action !== 'set-value') {
      setInputValue(query)
    }
  }

  const filterOption = (option: FilterOption) => {
    return (
      (option.value === filterSelectAllOption.value && matchingOptions?.length >= 1) ||
      inputValue === '' ||
      option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.value.toLowerCase().includes(inputValue.toLowerCase())
    )
  }

  const handleSelect = (selected: FilterOption[]) => {
    if (selected.some(s => s.value === filterSelectAllOption.value)) {
      selectedOptions.length === options.length
        ? setSelectedOptions([])
        : matchingOptions.every(option => selectedOptions.some(s => s.value === option.value))
          ? setSelectedOptions([...selectedOptions.filter(s => !matchingOptions.some(o => s.value === o.value))])
          : setSelectedOptions([
              ...matchingOptions,
              ...selectedOptions.filter(s => !matchingOptions.some(o => s.value === o.value))
            ])
    } else setSelectedOptions(selected)
  }

  const getPlaceholder = () =>
    selectedOptions.length === options.length
      ? t('filters.allSelected', { count: selectedOptions.length })
      : selectedOptions.length > 0
        ? t('filters.multipleSelected', { count: selectedOptions.length })
        : placeholder

  const formatOptionLabel = (data: FilterOption) => {
    return (
      <FilterOptionContainer title={data.label}>
        <CheckboxOption
          label={data.label}
          type={'filter'}
          clickHandler={() => undefined}
          checked={
            selectedOptions.some(option => option.value === data.value) ||
            (data.value === filterSelectAllOption.value &&
              matchingOptions.every(option => selectedOptions.some(s => s.value === option.value)))
          }
        />
      </FilterOptionContainer>
    )
  }

  return (
    <div className={'flex flex-col gap-10 pb-4'}>
      <div className={'flex flex-col gap-5 w-full'}>
        <LightGrayText className={'first-letter:capitalize text-center'}>
          {t('userSettings.notifications.editMultipleDescription', {
            label:
              'project' in entries[0] ? t(`vendors.${selectedVendor}.projectPhrase_other`) : t('common.service_other')
          })}
        </LightGrayText>
        <Select
          unstyled
          classNames={{
            control: () => selectControlStyles + ' flex items-center',
            menuList: () => selectMenuListStyles + ' w-full shadow-xs',
            noOptionsMessage: () => selectNoOptionsStyles,
            placeholder: () => 'text-gray-200',
            option: state => selectOptionStyles + ` max-w-full ${state.isFocused && 'bg-gray-600 text-gray-50'}`,
            group: () => 'my-4',
            groupHeading: () => selectGroupHeadingStyles
          }}
          value={selectedOptions}
          filterOption={filterOption}
          inputValue={inputValue}
          onInputChange={onInputChange}
          controlShouldRenderValue={false}
          blurInputOnSelect={false}
          tabSelectsValue={false}
          placeholder={getPlaceholder()}
          formatOptionLabel={formatOptionLabel}
          closeMenuOnSelect={false}
          isSearchable={true}
          isClearable={true}
          options={allOptions}
          isMulti={true}
          hideSelectedOptions={false}
          onChange={selected => handleSelect(selected as FilterOption[])}
          components={{
            // @ts-ignore
            ClearIndicator: SelectClearIndicator,
            IndicatorSeparator: SelectIndicatorSeparator,
            DropdownIndicator: SelectDropdownIndicator
          }}
        />
      </div>

      <InputRowsWrapper>
        <CostAnomalySettingsInputs
          percent={percent}
          setPercent={setPercent}
          impact={impact}
          setImpact={setImpact}
          currency={entries[0].currency}
        />
      </InputRowsWrapper>

      <div className={'flex items-center justify-evenly w-full text-gray-50'}>
        {'costNotificationsDisabledAt' in entries[0] && (
          <SwitchButton
            label={t('userSettings.notifications.notifications')}
            labelPosition={'top'}
            colorScale={'primary'}
            checked={notificationsEnabled}
            clickHandler={() => {
              if (notificationsEnabled) {
                setEmailsSubscribed(false)
                setNotificationsEnabled(false)
              } else {
                setNotificationsEnabled(true)
              }
            }}
          />
        )}
        <SwitchButton
          label={t('userSettings.notifications.emailNotifications')}
          labelPosition={'top'}
          colorScale={'primary'}
          checked={emailsSubscribed}
          tooltipText={t('userSettings.notifications.emailsTooltip')}
          tooltipStyles={'mt-4'}
          clickHandler={() => {
            if (!emailsSubscribed) {
              setNotificationsEnabled(true)
              setEmailsSubscribed(true)
            } else {
              setEmailsSubscribed(false)
            }
          }}
        />
      </div>
    </div>
  )
}

const InputRowsWrapper = styled.div`
  ${tw`flex flex-col w-full divide-y divide-gray-500`}
  & > div {
    ${tw`py-5 first:pt-0 last:pb-0`}
  }
`
