import styled from 'styled-components'
import tw from 'twin.macro'
import { CapitalizedText } from '../shared/TextComponents'
import { ScrollContainer } from '../shared/containers/ScrollContainer'

export const AdminTabContent = styled.div`
  ${tw`flex flex-col gap-8 py-9 px-6`}
`
export const AdminTabTitle = styled(CapitalizedText)`
  ${tw`font-semibold text-gray-50 text-125`}
`
export const AdminContentWrapper = styled(ScrollContainer)`
  ${tw`flex flex-col w-full gap-8 p-8`}
`
