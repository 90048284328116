import { client } from '../client'
import { CostAnomalyLimits } from '../notifications'
import { Vendor } from '../../utils/vendors'
import { CancelToken } from 'axios'
import { PermittedVendorProject } from '../admin/users'
import { CurrencyId } from '../../utils/Currency'

export const getUserSettings = async (cancelToken: CancelToken): Promise<UserSettings> => {
  return await client.get('/user-settings', { cancelToken }).then(resp => resp.data)
}

export const getUserPermittedProjects = async (
  vendor: Vendor,
  cancelToken: CancelToken
): Promise<PermittedVendorProject[]> => {
  return await client
    .get<PermittedVendorProject[]>(`profile/permitted-projects/${vendor.toLowerCase()}`, { cancelToken })
    .then(resp => resp.data)
}

export const changeUserPreferredCurrency = async (
  currency: CurrencyId,
  cancelToken: CancelToken
): Promise<UserSettings> => {
  return await client.post('profile/currency', currency, { cancelToken }).then(resp => resp.data)
}

export interface UserSettings {
  currency: CurrencyId
  defaultCostAnomalyLimits: CostAnomalyLimits
  visibleVendors: Vendor[]
}
