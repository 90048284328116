import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

interface CustomTooltipProps {
  children: React.ReactNode
  lessPadding?: boolean
  pointerDirection: 'down' | 'up' | 'right' | 'left' | 'none'
}

type ConditionalProps = { showIcon: false; icon?: never; alt?: never } | { showIcon: true; icon: string; alt: string }

type Props = CustomTooltipProps & ConditionalProps

export const CustomTooltip = ({ children, lessPadding, pointerDirection, showIcon, icon, alt }: Props) => {
  return (
    <Tooltip direction={pointerDirection}>
      <ContentWrapper lessPadding={lessPadding} showIcon={showIcon}>
        {showIcon && (
          <div>
            <img style={{ width: 14, height: 14 }} src={icon} alt={alt} />
          </div>
        )}
        <Content>{children}</Content>
      </ContentWrapper>
      {pointerDirection === 'down' ? (
        <PointerDown />
      ) : pointerDirection === 'up' ? (
        <PointerUp />
      ) : pointerDirection === 'right' ? (
        <PointerRight />
      ) : pointerDirection === 'left' ? (
        <PointerLeft />
      ) : null}
    </Tooltip>
  )
}

interface ContentWrapperProps {
  showIcon: boolean
  lessPadding?: boolean
}

const ContentWrapper = styled.div<ContentWrapperProps>`
  ${tw`flex justify-center items-center bg-gray-50 rounded-lg`}
  ${props => props.showIcon && tw`flex-row gap-2`}
${props => (props.lessPadding ? tw`py-2 px-3` : tw`py-3 px-4`)}
`

const Content = styled.div`
  ${tw`flex flex-col justify-center items-center text-center text-basic-black text-75 tracking-wide`}
`

interface TooltipProps {
  direction?: string
}

const Tooltip = styled.div<TooltipProps>`
  ${props =>
    props.direction === 'down'
      ? tw`flex flex-col justify-center`
      : props.direction === 'up'
      ? tw`flex flex-col-reverse justify-center`
      : props.direction === 'left'
      ? tw`flex flex-row-reverse items-center`
      : props.direction === 'right'
      ? tw`flex flex-row items-center`
      : null}
`

const PointerDown = styled.div<TooltipProps>`
  ${tw`mx-auto -mt-0.5 border-t-13 border-t-gray-50 border-r-12 border-r-transparent border-l-12 border-l-transparent`}
`

const PointerUp = styled.div<TooltipProps>`
  ${tw`mx-auto -mb-0.5 border-b-13 border-b-gray-50 border-r-12 border-r-transparent border-l-12 border-l-transparent`}
`

const PointerLeft = styled.div<TooltipProps>`
  ${tw`my-auto -mr-0.5 border-r-13 border-r-gray-50 border-t-12 border-t-transparent border-b-12 border-b-transparent`}
`

const PointerRight = styled.div<TooltipProps>`
  ${tw`my-auto -ml-0.5 border-l-13 border-l-gray-50 border-t-12 border-t-transparent border-b-12 border-b-transparent`}
`
