import React, { useState } from 'react'
import { ViewWrapper } from '../shared/layout/ViewWrapper'
import { FilterOption } from '../shared/filters/FilterSelect'
import { ComplianceFilters } from './ComplianceFilters'
import { ComplianceTotalSummary } from './total-summary/ComplianceTotalSummary'
import { ComplianceTabs } from './tabs/ComplianceTabs'
import { Vendor } from '../../utils/vendors'
import { getVendorFilterOptions } from '../shared/filters/Filters'

export const Compliance = () => {
  const [selectedVendors, setSelectedVendors] = useState<FilterOption[]>([])
  const [selectedProjects, setSelectedProjects] = useState<FilterOption[]>([])
  const [selectedResourceGroups, setSelectedResourceGroups] = useState<FilterOption[]>([])
  const [selectedStandards, setSelectedStandards] = useState<FilterOption[]>([])

  if (!selectedVendors.length) setSelectedVendors(getVendorFilterOptions())

  return (
    <ViewWrapper>
      <ComplianceFilters
        selectedVendors={selectedVendors}
        setSelectedVendors={setSelectedVendors}
        selectedProjects={selectedProjects}
        setSelectedProjects={setSelectedProjects}
        selectedResourceGroups={selectedResourceGroups}
        setSelectedResourceGroups={setSelectedResourceGroups}
        selectedStandards={selectedStandards}
        setSelectedStandards={setSelectedStandards}
      />
      <ComplianceTotalSummary
        selectedVendors={selectedVendors.map(v => v.value as Vendor)}
        selectedProjects={selectedProjects.map(p => p.value)}
        selectedResourceGroups={selectedResourceGroups.map(r => r.value)}
        selectedStandards={selectedStandards.map(s => s.value)}
      />
      <ComplianceTabs
        selectedVendors={selectedVendors.map(v => v.value as Vendor)}
        selectedProjects={selectedProjects.map(p => p.value)}
        selectedResourceGroups={selectedResourceGroups.map(r => r.value)}
        selectedStandards={selectedStandards.map(s => s.value)}
      />
    </ViewWrapper>
  )
}
