import { useTranslation } from 'react-i18next'
import React, { useContext } from 'react'
import { ModalContext } from '../../../state/context/ModalContext'
import { Prompt, useHistory } from 'react-router-dom'
import * as H from 'history'
import { ModalActions, ModalText } from './Modal'
import { Button, ButtonSize, ButtonStyle, ButtonType } from '../buttons/Button'

interface PromptModalProps {
  showModal: boolean
  returnClickHandler?: () => void
}

export const UnsavedChangesPromptModal = ({ showModal, returnClickHandler }: PromptModalProps) => {
  const { t } = useTranslation()
  const { modal, setModal } = useContext(ModalContext)
  const history = useHistory()

  const handlePrompt = (location: H.Location) => {
    if (showModal && history.location.pathname !== location.pathname) {
      setModal({
        header: t('common.unsavedChangesModal.header'),
        body: (
          <>
            <div>
              <ModalText>{t('common.unsavedChangesModal.text')}</ModalText>
            </div>
            <ModalActions>
              <Button
                value={t('common.unsavedChangesModal.discardAndLeave')}
                buttonStyle={ButtonStyle.SECONDARY}
                size={ButtonSize.XSMALL}
                type={ButtonType.MODAL}
                clickHandler={() => {
                  history.push(location.pathname)
                  setModal(null)
                  return true
                }}
              />
              <Button
                value={t('common.unsavedChangesModal.goBack')}
                buttonStyle={ButtonStyle.PRIMARY}
                size={ButtonSize.XSMALL}
                type={ButtonType.MODAL}
                clickHandler={() => {
                  returnClickHandler && returnClickHandler()
                  setModal(null)
                }}
              />
            </ModalActions>
          </>
        )
      })
      return false
    }
    return true
  }

  return <Prompt when={!modal && showModal} message={location => handlePrompt(location)} />
}
