import styled from 'styled-components'
import tw from 'twin.macro'

interface InputContainerProps {
  active?: boolean
}

export const InputContainer = styled.div<InputContainerProps>`
  ${tw`flex justify-between bg-gray-700/60 border rounded-sm border-gray-400 gap-1.5 py-1.5 px-2 hover:border-gray-300`}
  ${({ active }) => active && tw`border-gray-300`}
  input::placeholder {
    ${tw`text-gray-200`}
  }
`

export const PlainInput = styled.input`
  ${tw`bg-gray-700/60 text-gray-50 w-full border-none outline-none cursor-pointer`}
  ::placeholder {
    ${tw`text-90`}
  }
`

export const CustomInput = styled.input`
  ${tw`w-full rounded-sm py-1.5 px-4 bg-gray-700 text-gray-100 focus:text-gray-50 border border-gray-400/80 
  focus:border-gray-100 focus:outline-none focus:shadow-input-focus
  disabled:bg-gray-500/60`}

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  [type='number'] {
    -moz-appearance: textfield;
  }
`

export const CustomLabel = styled.div`
  ${tw`inline-block mb-1 text-gray-200 font-semibold text-90 first-letter:capitalize`}
`
