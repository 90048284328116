import { useTranslation } from 'react-i18next'
import React, { useContext, useEffect, useState } from 'react'
import { useCancelToken } from '../../../api/client'
import { useErrorHandling } from '../../../hooks/handleError'
import { getUserDetails, OrganizationUser } from '../../../api/admin/users'
import { Loading } from '../../shared/Loading'
import { formatDate } from '../../../utils/formats'
import { TabOptionsWrapper } from '../../shared/tabs/TabSharedComponents'
import { Tab } from '../../shared/tabs/Tab'
import { useHistory, useParams } from 'react-router-dom'
import { DataContainer } from '../../shared/containers/DataContainer'
import { EditAccessRights } from './tabs/EditAccessRights'
import { EditVisibility } from './tabs/EditVisibility'
import { GrayText } from '../../shared/TextComponents'
import { AuthInfoContext, AuthInfoState } from '../../../state/context/AuthInfoContext'
import { NestedPageHeader, NestedPageHeaderTitles } from '../../shared/NestedPageHeader'
import { AdminContentWrapper } from '../adminStyles'
import { ScrollContainer } from '../../shared/containers/ScrollContainer'

export enum UserEditTab {
  ACCESS_RIGHTS,
  VISIBILITY
}

export const UserDetails = () => {
  const { authInfo } = useContext<AuthInfoState>(AuthInfoContext)
  const { t } = useTranslation()
  const history = useHistory()
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const { userId } = useParams<{ userId: string }>()
  const [loading, setLoading] = useState<boolean>(true)
  const [user, setUser] = useState<OrganizationUser | null>(null)
  const [selectedTab, setSelectedTab] = useState<UserEditTab>(UserEditTab.ACCESS_RIGHTS)

  useEffect(() => {
    const cancelToken = createCancelToken()
    setLoading(true)
    getUserDetails(userId, cancelToken.token)
      .then(setUser)
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      cancelToken.cancel()
      setLoading(false)
    }
  }, [createCancelToken, handleError, userId])

  useEffect(() => {
    user && user.tenantId !== authInfo.tenantId && history.push('/admin/users')
  }, [authInfo, history, user])

  if (!user || loading)
    return (
      <DataContainer looseSpacing={true}>
        <div className={'flex items-center h-[20vh]'}>
          {loading ? (
            <Loading paddingY={'1rem'} />
          ) : (
            <GrayText className={'m-auto underline cursor-pointer'} onClick={() => history.push('/admin/users')}>
              {t('common.return')}
            </GrayText>
          )}
        </div>
      </DataContainer>
    )

  return (
    <>
      <div className={'flex flex-col w-full'}>
        <NestedPageHeader backButtonPath={'/admin/users'}>
          <NestedPageHeaderTitles
            mainHeading={`${user.firstName} ${user.lastName}`}
            children={<GrayText>{`${t('admin.updated')} ${formatDate(user.updatedAt, true, true)}`}</GrayText>}
          />
        </NestedPageHeader>
        <AdminContentWrapper>
          <UserDetailsTabOptions selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          <ScrollContainer className={'max-h-[62vh] lg:max-h-[73vh]'}>
            {selectedTab === UserEditTab.ACCESS_RIGHTS ? (
              <EditAccessRights user={user} setUser={setUser} />
            ) : (
              <EditVisibility user={user} setUser={setUser} />
            )}
          </ScrollContainer>
        </AdminContentWrapper>
      </div>
    </>
  )
}

interface TabOptionsProps {
  selectedTab: UserEditTab
  setSelectedTab: (tabOption: UserEditTab) => void
}

export const UserDetailsTabOptions = ({ selectedTab, setSelectedTab }: TabOptionsProps) => {
  const { t } = useTranslation()
  return (
    <TabOptionsWrapper>
      <Tab
        styles={'lg:max-w-60'}
        tabLabel={t('admin.tabs.rolesAndAccessRights.tabLabel')}
        tabValue={UserEditTab.ACCESS_RIGHTS}
        selectedTab={selectedTab}
        handleSelection={() => setSelectedTab(UserEditTab.ACCESS_RIGHTS)}
      />
      <Tab
        tabLabel={t('admin.tabs.visibility.tabLabel')}
        tabValue={UserEditTab.VISIBILITY}
        selectedTab={selectedTab}
        handleSelection={() => setSelectedTab(UserEditTab.VISIBILITY)}
      />
    </TabOptionsWrapper>
  )
}
