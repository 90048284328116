import React, { useEffect, useRef, useState } from 'react'
import { DataContainer } from '../shared/containers/DataContainer'
import { CountUp } from '../shared/CountUp'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import {
  ConsumptionSummary,
  EmissionBreakdown,
  EmissionEquivalent,
  getSustainabilityTotalSummary,
  SustainabilityTotalSummary
} from '../../api/sustainability'
import { roundNumber } from '../../utils/formats'
import { Loading } from '../shared/Loading'
import { MissingDataNotification } from '../shared/MissingDataNotification'
import { BarChartsDisplay, ScoreBarChart } from '../shared/charts/ScoreBarChart'
import { CustomIcon, IconType } from '../shared/CustomIcon'
import beer from '../../assets/svg/objects/illustrations/beer.svg'
import googleSearch from '../../assets/svg/objects/illustrations/google-search.svg'
import meat from '../../assets/svg/objects/illustrations/meat.svg'
import streaming from '../../assets/svg/objects/illustrations/streaming.svg'
import bread from '../../assets/svg/objects/illustrations/bread.svg'
import plane from '../../assets/svg/objects/illustrations/plane.svg'
import trees from '../../assets/svg/objects/illustrations/trees.svg'
import car from '../../assets/svg/objects/illustrations/car.svg'
import cheese from '../../assets/svg/objects/illustrations/cheese.svg'
import milk from '../../assets/svg/objects/illustrations/milk.svg'
import iPhone from '../../assets/svg/objects/illustrations/iPhone.svg'
import { Vendor } from '../../utils/vendors'
import { useErrorHandling } from '../../hooks/handleError'
import { useCancelToken } from '../../api/client'
import { GrayText } from '../shared/TextComponents'
import { TimeframeOption } from '../../utils/classes'

interface SustainabilitySummaryProps {
  selectedVendors: Vendor[]
  selectedProjects: string[]
  selectedResourceGroups: string[]
  selectedServices: string[]
  selectedRegions: string[]
  selectedTimeframe: TimeframeOption
}

export const SustainabilitySummary = ({
  selectedVendors,
  selectedProjects,
  selectedResourceGroups,
  selectedServices,
  selectedRegions,
  selectedTimeframe
}: SustainabilitySummaryProps) => {
  const { t } = useTranslation()
  const handleError = useErrorHandling()
  const { createCancelToken } = useCancelToken()
  const initialMount = useRef(true)
  const [loading, setLoading] = useState(false)
  const [summaryData, setSummaryData] = useState<SustainabilityTotalSummary | null>(null)

  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false
      setLoading(true)
    }
    getSustainabilityTotalSummary(
      selectedVendors,
      selectedProjects,
      selectedResourceGroups,
      selectedServices,
      selectedRegions,
      selectedTimeframe,
      createCancelToken().token
    )
      .then(setSummaryData)
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => setLoading(false)
  }, [
    handleError,
    createCancelToken,
    selectedVendors,
    selectedProjects,
    selectedResourceGroups,
    selectedServices,
    selectedRegions,
    selectedTimeframe
  ])

  if (loading || !summaryData)
    return (
      <DataContainer>
        <Loading paddingY={'5.97rem'} />
      </DataContainer>
    )

  return (
    <DataContainer looseSpacing={true}>
      <GrayText className={'absolute top-4 right-5 text-75 text-right max-w-[50%]'}>
        {t('sustainability.reportingInfo')}
      </GrayText>
      <div className={'flex w-full justify-between flex-col transition-all ease-in-out gap-10 md:gap-28 lg:flex-row'}>
        <EmissionNumbers data={summaryData.totals} selectedTimeframe={selectedTimeframe} />
        <EmissionsBreakdown data={summaryData.breakdown} />
      </div>
    </DataContainer>
  )
}

interface EmissionNumbersProps {
  selectedTimeframe: TimeframeOption
  data: ConsumptionSummary
}

const EmissionNumbers = ({ selectedTimeframe, data }: EmissionNumbersProps) => {
  const { t } = useTranslation()
  const equivalents = [
    { label: EmissionEquivalent.BEER, icon: beer },
    { label: EmissionEquivalent.TREES, icon: trees },
    { label: EmissionEquivalent.PLANE, icon: plane },
    { label: EmissionEquivalent.MEAT, icon: meat },
    { label: EmissionEquivalent.BREAD, icon: bread },
    { label: EmissionEquivalent.CAR, icon: car },
    { label: EmissionEquivalent.STREAMING, icon: streaming },
    { label: EmissionEquivalent.GOOGLESEARCH, icon: googleSearch },
    { label: EmissionEquivalent.CHEESE, icon: cheese },
    { label: EmissionEquivalent.MILK, icon: milk },
    { label: EmissionEquivalent.IPHONE, icon: iPhone }
  ]

  const iconSrc = (equivalent: string) => equivalents.find(item => item.label === equivalent)?.icon ?? ''

  const consumptions = (data: ConsumptionSummary) => {
    return (
      <TotalsContainer className={'leading-tight'}>
        <span>
          <Amount size={'xlarge'}>
            <CountUp id={'qa-produced-emissions'} countTo={roundNumber(data.cloudEmissions ?? 0, 0)} />{' '}
          </Amount>
          {t('common.units.kgCO2')} {t('sustainability.summary.producedEmissions')}
        </span>
        <span>
          <Amount dataStyle={'kwh'} size={'xlarge'}>
            <CountUp id={'qa-consumed-electricity'} countTo={roundNumber(data.kwhSpend ?? 0, 0)} />{' '}
          </Amount>
          {t('common.units.kWh')} {t('sustainability.electricity')}
        </span>
      </TotalsContainer>
    )
  }

  const savings = (data: ConsumptionSummary) => {
    return (
      <TotalsContainer>
        <AmountWrapper>
          <span>{t('sustainability.summary.savingIntro')} </span>
          <span>
            <Amount size={'large'}>
              <CountUp id={'qa-emission-savings'} countTo={roundNumber(data.emissionSavings ?? 0, 0)} />{' '}
            </Amount>
            {t('common.units.kgCO2')} {t('sustainability.summary.savingComparison')}
          </span>
        </AmountWrapper>
        <EquivalencyContainer>
          <CustomIcon
            iconType={IconType.FLAT}
            path={iconSrc(data.savingsEquivalency?.equivalent ?? '')}
            styles={'w-15 h-11'}
          />
          <AmountWrapper>
            <span>{t('sustainability.summary.equivalency.intro')} </span>
            <span>
              <Amount dataStyle={'equivalent'} size={'default'}>
                <CountUp
                  id={'qa-emissions-equivalency'}
                  countTo={roundNumber(data.savingsEquivalency?.value ?? 0, 0)}
                />{' '}
              </Amount>
              {t(`sustainability.summary.equivalency.references.${data.savingsEquivalency?.equivalent}`)}
            </span>
          </AmountWrapper>
        </EquivalencyContainer>
      </TotalsContainer>
    )
  }

  return (
    <Wrapper className={'md:min-w-max xl:min-w-85'}>
      <Heading>
        {selectedTimeframe === TimeframeOption.LAST_MONTH
          ? t('sustainability.heading.lastMonths')
          : t('sustainability.heading.fromYear', {
              year: new Date().getFullYear() - (selectedTimeframe === TimeframeOption.LAST_YEAR ? 1 : 0)
            })}
      </Heading>
      {data && data.cloudEmissions && data.kwhSpend && data.emissionSavings && data.savingsEquivalency ? (
        <div className={'flex flex-col divide-y divide-gray-500'}>
          {consumptions(data)}
          {savings(data)}
        </div>
      ) : (
        <TotalsContainer>
          <MissingDataNotification paddingY={0} justify={'start'} />
        </TotalsContainer>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${tw`flex flex-col gap-3 md:gap-8`}
`

const TotalsContainer = styled.div`
  ${tw`flex flex-col text-gray-50 min-w-80 gap-4 py-2 first:pb-8 last:pt-8`}
`

const AmountWrapper = styled.div`
  ${tw`flex flex-col justify-center text-gray-50`}
  span:first-child {
    ${tw`first-letter:capitalize`}
  }
`

const EquivalencyContainer = styled.div`
  ${tw`flex items-center gap-3`}
`

interface AmountProps {
  size: 'xlarge' | 'large' | 'default'
  dataStyle?: 'kwh' | 'equivalent'
}

const Amount = styled.span<AmountProps>`
  ${tw`bg-clip-text font-bold text-transparent bg-gradient-to-b transition-all ease-in-out`}
  ${props => (props.size === 'xlarge' ? tw`text-200` : props.size === 'large' ? tw`text-150` : tw`text-125`)}
  ${props =>
    props.dataStyle === 'kwh'
      ? tw`from-tertiary-400 to-tertiary-500`
      : props.dataStyle === 'equivalent'
        ? tw`from-primary-200 to-primary-400`
        : tw`from-tertiary-100 to-tertiary-300`}
`

interface CategoriesProps {
  data: EmissionBreakdown[]
}

const EmissionsBreakdown = ({ data }: CategoriesProps) => {
  const { t } = useTranslation()
  const noData = data.every(item => item.percent === null)

  return (
    <Wrapper className={'w-full'}>
      <div>
        <Heading>{t('sustainability.summary.breakdownHeading')}</Heading>
        {noData && <MissingDataNotification paddingY={!data.length ? 5 : 0} justify={'start'} />}
      </div>
      <BarChartsWrapper>
        {data.map((category, index) => (
          <ScoreBarChart
            labelId={`qa-emissions-breakdown-${category.category}`}
            key={index}
            chartLabel={`${category.category.toLowerCase()} ${category.percent ?? 0} %`}
            displayStyle={BarChartsDisplay.pink}
            colorIndex={index}
            percent={category.percent ?? 0}
          />
        ))}
      </BarChartsWrapper>
    </Wrapper>
  )
}

const Heading = styled.div`
  ${tw`first-letter:capitalize text-gray-50 font-semibold text-112`}
`

const BarChartsWrapper = styled.div`
  ${tw`flex flex-col w-full max-w-[74rem] h-full justify-between py-2 gap-3 md:gap-5`}
`
