import React, { useState } from 'react'
import { ComplianceChartData, ComplianceChartEntry } from '../../../api/compliance/common'
import { Vendor } from '../../../utils/vendors'
import { formatNumber } from '../../../utils/formats'
import { CustomTooltip } from '../../shared/CustomTooltip'
import { useTranslation } from 'react-i18next'
import { Loading } from '../../shared/Loading'
import { ToggleGroup } from '../../shared/buttons/ToggleGroup'
import { SwitchButton } from '../../shared/buttons/SwitchButton'
import { CustomLineChart, LineChartContainer, LineChartHeader } from '../../shared/charts/CustomLineChart'
import { Timescale } from '../../../utils/classes'
import { AccessibleVendors } from '../../../api/auth'

interface ChartProps {
  selectedVendors: Vendor[]
  chartData: ComplianceChartData[]
  chartTimescale: Timescale
  setChartTimescale: (timeFrame: Timescale) => void
  loading: boolean
}

export const ComplianceHistoryChart = ({
  selectedVendors,
  chartData,
  chartTimescale,
  setChartTimescale,
  loading
}: ChartProps) => {
  const { t } = useTranslation()
  const [hideComboLine, setHideComboLine] = useState(true)
  const timeFrameOptions = [
    {
      clickHandler: () => setChartTimescale(Timescale.MONTH),
      checked: chartTimescale === Timescale.MONTH,
      value: t('compliance.tabs.summary.MONTH')
    },
    {
      clickHandler: () => setChartTimescale(Timescale.YEAR),
      checked: chartTimescale === Timescale.YEAR,
      value: t('compliance.tabs.summary.YEAR')
    }
  ]

  const gcpCounts = chartData
    ?.filter(vendor => vendor.vendor === Vendor.GCP)
    ?.flatMap(vendor => vendor.chartEntries?.map(entry => entry.totalCount ?? 0)) ?? [0]
  const gcpMaxDomain = Math.max(...gcpCounts) < 1 ? 4 : Math.max(...gcpCounts)

  const comboLineToggleIsUsable = selectedVendors.includes(Vendor.AWS) && selectedVendors.includes(Vendor.AZURE)
  const showComboLine = comboLineToggleIsUsable && !hideComboLine
  const percentAxisTicks = [0, 0.25, 0.5, 0.75, 1]

  if (loading)
    return (
      <LineChartContainer>
        <Loading paddingY={'20%'} />
      </LineChartContainer>
    )

  return (
    <LineChartContainer>
      <LineChartHeader
        heading={`${t('compliance.tabs.summary.summaryHeading')}`}
        actions={
          <>
            <ToggleGroup minWidth={'12rem'} maxWidth={'12rem'} buttons={timeFrameOptions} />
            {AccessibleVendors().includes(Vendor.AWS) && AccessibleVendors().includes(Vendor.AZURE) && (
              <SwitchButton
                clickHandler={() => setHideComboLine(!hideComboLine)}
                checked={showComboLine}
                label={`${t(`vendors.AWS.short`)} & ${t('vendors.AZURE.short')} ${t(
                  'compliance.tabs.summary.showCombined'
                )}`}
                disabled={!comboLineToggleIsUsable}
              />
            )}
          </>
        }
      />
      <CustomLineChart
        chartData={chartData}
        domain={[0, 1]}
        tooltip={<ComplianceTooltip />}
        tooltipLabel={({ datum }) => (datum.vendor === Vendor.GCP ? datum.totalCount : datum.compliantPercent)}
        selectedVendors={selectedVendors}
        showVendorLegend={true}
        timescale={chartTimescale}
        showLeftAxis={selectedVendors.includes(Vendor.AWS) || selectedVendors.includes(Vendor.AZURE)}
        leftAxisTicks={percentAxisTicks}
        formatLeftTicks={t => `${Math.round(t * 100)} %`}
        leftYAxisDataKey={datum =>
          datum.vendor === Vendor.GCP
            ? datum.totalCount && datum.totalCount / gcpMaxDomain
            : datum.compliantPercent && datum.compliantPercent / 100
        }
        showRightYAxis={selectedVendors.includes(Vendor.GCP)}
        rightAxisTicks={
          gcpMaxDomain === 1 ? [1] : gcpMaxDomain === 2 || gcpMaxDomain === 3 ? [0, 0.5, 1] : [0, 0.25, 0.5, 0.75, 1]
        }
        formatRightTicks={t => (!chartData.length ? '' : `${Math.ceil(t * gcpMaxDomain)}`)}
        hideComboLine={hideComboLine}
      />
    </LineChartContainer>
  )
}

interface TooltipProps {
  active?: boolean
  x?: number
  y?: number
  datum?: ComplianceChartEntry
}

const ComplianceTooltip = ({ active, x, y, datum }: TooltipProps) => {
  const { t } = useTranslation()
  if (!active || !datum || !x || !y || !datum.totalCount) return null
  const width = 152
  const height = 92
  const posX = x - width / 2
  const posY = datum.vendor === Vendor.GCP ? y - height + 12 : y - height - 8
  return (
    <g>
      <foreignObject x={posX} y={posY} height={height} width={width}>
        <CustomTooltip pointerDirection={'down'} showIcon={false}>
          {datum.vendor !== Vendor.GCP && <div className={'font-semibold'}>{`${datum.compliantPercent} %`}</div>}
          {datum.vendor === Vendor.GCP
            ? `${formatNumber(datum.totalCount)}`
            : `${formatNumber(datum.compliantCount)} / ${formatNumber(datum.totalCount)}`}
          <div>
            {datum.vendor === Vendor.GCP
              ? t('compliance.tabs.summary.failedControls')
              : t('compliance.tabs.summary.compliantControls')}
          </div>
        </CustomTooltip>
      </foreignObject>
    </g>
  )
}
