import React, { useContext, useEffect, useRef, useState } from 'react'
import { downloadInfraCsv, getInfraBreakDown, InfraBreakDown } from '../../../api/infra'
import { useCancelToken } from '../../../api/client'
import { useErrorHandling } from '../../../hooks/handleError'
import { Loading } from '../../shared/Loading'
import { TabContent, TabNoDataMessage, TabsContainer } from '../../shared/tabs/TabSharedComponents'
import { VendorTabs } from '../../shared/tabs/VendorTabs'
import { Vendor } from '../../../utils/vendors'
import { Tree } from '../../shared/Tree'
import { InfraRegion } from './InfraRegion'
import { ExportButton } from '../../shared/buttons/ExportButton'
import { useTranslation } from 'react-i18next'
import { MessageContext, MessageState, MessageType } from '../../../state/context/MessageContext'
import { ButtonType } from '../../shared/buttons/Button'

interface BreakdownProps {
  selectedVendors: Vendor[]
  selectedRegions: string[]
  selectedProjects: string[]
  selectedResourceGroups: string[]
  selectedResources: string[]
}

export const InfraTabs = ({
  selectedVendors,
  selectedRegions,
  selectedProjects,
  selectedResourceGroups,
  selectedResources
}: BreakdownProps) => {
  const { createCancelToken } = useCancelToken()
  const { t } = useTranslation()
  const { setMessage } = useContext<MessageState>(MessageContext)
  const handleError = useErrorHandling()
  const [regionData, setRegionData] = useState<InfraBreakDown[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [selectedTab, setSelectedTab] = useState<Vendor>(Vendor.ALL)
  const [downloadInFlight, setDownloadInFlight] = useState<boolean>(false)
  const isInitialMount = useRef(true)

  useEffect(() => {
    const cancelToken = createCancelToken()
    if (isInitialMount.current) {
      isInitialMount.current = false
      setLoading(true)
    }
    getInfraBreakDown(
      selectedVendors,
      selectedRegions,
      selectedProjects,
      selectedResourceGroups,
      selectedResources,
      cancelToken.token
    )
      .then(setRegionData)
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      cancelToken.cancel()
      setLoading(false)
    }
  }, [
    createCancelToken,
    handleError,
    selectedProjects,
    selectedRegions,
    selectedResourceGroups,
    selectedResources,
    selectedVendors
  ])

  useEffect(() => {
    !regionData.length ? setSelectedTab(Vendor.ALL) : setSelectedTab(regionData[0].vendor as Vendor)
  }, [regionData])

  const data = regionData.filter(item => item.vendor === selectedTab)

  const exportAction = () => {
    const cancelToken = createCancelToken()
    setDownloadInFlight(true)
    downloadInfraCsv(
      selectedVendors,
      selectedProjects,
      selectedResourceGroups,
      selectedRegions,
      selectedResources,
      cancelToken.token
    )
      .then(() =>
        setMessage({
          message: t('common.downloadSuccess'),
          type: MessageType.SUCCESS
        })
      )
      .catch(handleError)
      .finally(() => setDownloadInFlight(false))

    return () => {
      setDownloadInFlight(false)
      cancelToken.cancel()
    }
  }

  if (loading)
    return (
      <TabsContainer>
        <VendorTabs handleSelection={setSelectedTab} selectedVendor={selectedTab} />
        <Loading paddingY={'1rem'} />
      </TabsContainer>
    )

  return (
    <TabsContainer>
      <VendorTabs
        handleSelection={setSelectedTab}
        selectedVendor={selectedTab}
        actionComponent={
          <ExportButton
            type={ButtonType.ICON}
            clickHandler={exportAction}
            text={t('common.export')}
            disabled={downloadInFlight}
          />
        }
      />
      <TabContent>
        <Tree>
          {!data.length || !selectedVendors.includes(selectedTab) ? (
            <TabNoDataMessage selectedVendors={selectedVendors} vendor={selectedTab} />
          ) : (
            data.map(vendorData =>
              vendorData.regions.map(region => (
                <InfraRegion key={`${vendorData.vendor}-${region.id}`} vendor={selectedTab} region={region} />
              ))
            )
          )}
        </Tree>
      </TabContent>
    </TabsContainer>
  )
}
