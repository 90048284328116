import React, { useContext, useEffect, useState } from 'react'
import { TFunction, Trans, useTranslation } from 'react-i18next'
import {
  CostAnomalyNotification,
  DECREASING_COST_ANOMALY_STATES,
  DisabledCostNotificationData,
  getCostAnomalyNotifications,
  INCREASING_COST_ANOMALY_STATES,
  NotificationState,
  restoreCostAnomalyNotificationsToInbox,
  updateCostAnomalyNotificationsStatus
} from '../../api/notifications'
import {
  NoNotificationsText,
  NotificationContentWrapper,
  NotificationHeading,
  NotificationsListWrapper,
  NotificationTextWrapper,
  NotificationWrapper,
  ViewNotificationCostsButton
} from './SharedComponents'
import { NotificationsTab } from '../shared/Enums'
import { VendorIndicator, VendorIndicatorState } from '../shared/indicators/VendorIndicator'
import { GrayText } from '../shared/TextComponents'
import { formatDate, formatNumber, roundNumber } from '../../utils/formats'
import { ModalContext } from '../../state/context/ModalContext'
import { NotificationsContext } from '../../state/context/NotificationsContext'
import { MessageContext, MessageType } from '../../state/context/MessageContext'
import { useErrorHandling } from '../../hooks/handleError'
import { useCancelToken } from '../../api/client'
import { MeatballsMenu, MeatballsMenuOption } from '../shared/MeatballsMenu'
import { CostAnomalySettingsModalBody, CostAnomalySettingsModalHeader } from './CostAnomalySettingsModal'
import {
  disableCostNotifications,
  enableCostNotifications,
  subscribeProjectEmailNotifications,
  subscribeServiceEmailNotifications,
  unsubscribeProjectEmailNotifications,
  unsubscribeServiceEmailNotifications
} from '../../api/user/notification-settings'
import { CustomIcon, IconType } from '../shared/CustomIcon'
import envelope from '../../assets/svg/objects/envelope.svg'
import { getCurrency } from '../../utils/Currency'

interface NotificationsListProps {
  selectedTab: NotificationsTab.INBOX | NotificationsTab.DELETED
  tabNotifications: CostAnomalyNotification[]
  filteredNotifications: CostAnomalyNotification[]
  updateReadStatus: (notifications: CostAnomalyNotification[], status: NotificationState) => void
  moveToBin: (notifications: CostAnomalyNotification[]) => void
  restoreToInbox: (notifications: CostAnomalyNotification[]) => void
  deletePermanently: (notifications: CostAnomalyNotification[]) => void
}

export const CostNotifications = ({
  selectedTab,
  tabNotifications,
  filteredNotifications,
  updateReadStatus,
  moveToBin,
  restoreToInbox,
  deletePermanently
}: NotificationsListProps) => {
  const { t } = useTranslation()

  if (!tabNotifications.length || !filteredNotifications.length)
    return (
      <NoNotificationsText>
        {!tabNotifications.length
          ? t(`notifications.${selectedTab}.noNotifications`)
          : t(`notifications.noMatchingNotifications`)}
      </NoNotificationsText>
    )

  const wrapper = document.getElementById('cost-anomaly-notifications-list')
  const listContainerHeight = window.innerHeight - 32 - (wrapper?.offsetTop ?? 0)

  return (
    <NotificationsListWrapper id={'cost-anomaly-notifications-list'} height={listContainerHeight}>
      {filteredNotifications.map(notification => (
        <CostNotification
          key={notification.notificationId}
          selectedTab={selectedTab}
          notification={notification}
          updateReadStatus={updateReadStatus}
          moveToBin={moveToBin}
          restoreToInbox={restoreToInbox}
          deletePermanently={deletePermanently}
        />
      ))}
    </NotificationsListWrapper>
  )
}

interface NotificationProps {
  selectedTab: NotificationsTab.INBOX | NotificationsTab.DELETED
  notification: CostAnomalyNotification
  updateReadStatus: (notifications: CostAnomalyNotification[], status: NotificationState) => void
  moveToBin: (notifications: CostAnomalyNotification[]) => void
  restoreToInbox: (notifications: CostAnomalyNotification[]) => void
  deletePermanently: (notifications: CostAnomalyNotification[]) => void
}

const CostNotification = ({
  selectedTab,
  notification,
  updateReadStatus,
  moveToBin,
  restoreToInbox,
  deletePermanently
}: NotificationProps) => {
  const { t } = useTranslation()

  return (
    <NotificationWrapper>
      <VendorIndicator
        vendor={notification.vendor}
        padding={1.5}
        onClick={() => {
          notification.notificationState === NotificationState.UNREAD
            ? updateReadStatus([notification], NotificationState.READ)
            : updateReadStatus([notification], NotificationState.UNREAD)
        }}
        state={
          notification.notificationState === NotificationState.READ
            ? VendorIndicatorState.INACTIVE
            : VendorIndicatorState.ACTIVE
        }
      />
      <NotificationHeader
        notification={notification}
        selectedTab={selectedTab}
        updateReadStatus={updateReadStatus}
        moveToBin={moveToBin}
        restoreToInbox={restoreToInbox}
        deletePermanently={deletePermanently}
      />
      <NotificationContentWrapper>
        <NotificationTexts notification={notification} />
        <div className={'flex justify-between items-center w-full gap-12'}>
          <ViewNotificationCostsButton notification={notification} updateReadState={updateReadStatus} />
          <GrayText className={'text-right text-80'}>
            {notification.disabledAt &&
              t('notifications.costAnomalies.costAnomaly.disabledTimestamp', {
                project: t(`vendors.${notification.vendor}.projectPhrase_one`),
                time: formatDate(new Date(notification.disabledAt))
              })}
          </GrayText>
        </div>
      </NotificationContentWrapper>
    </NotificationWrapper>
  )
}

interface NotificationHeaderProps {
  notification: CostAnomalyNotification
  selectedTab: NotificationsTab.INBOX | NotificationsTab.DELETED
  updateReadStatus: (notifications: CostAnomalyNotification[], status: NotificationState) => void
  moveToBin: (notifications: CostAnomalyNotification[]) => void
  restoreToInbox: (notifications: CostAnomalyNotification[]) => void
  deletePermanently: (notifications: CostAnomalyNotification[]) => void
}

const NotificationHeader = ({
  notification,
  selectedTab,
  updateReadStatus,
  moveToBin,
  restoreToInbox,
  deletePermanently
}: NotificationHeaderProps) => {
  const { t } = useTranslation()
  const { setModal } = useContext(ModalContext)
  const { setNotifications } = useContext(NotificationsContext)
  const { setMessage } = useContext(MessageContext)
  const handleError = useErrorHandling()
  const { createCancelToken } = useCancelToken()
  const [loading, setLoading] = useState(false)
  const emailsTooltip =
    notification.emailSubscriptions?.project && notification.emailSubscriptions.service
      ? t('notifications.costAnomalies.costAnomaly.bothEmailsSubscribed', {
          project: t(`vendors.${notification.vendor}.projectPhrase_one`)
        })
      : notification.emailSubscriptions?.project
        ? t('notifications.costAnomalies.costAnomaly.projectEmailsSubscribed', {
            project: t(`vendors.${notification.vendor}.projectPhrase_one`)
          })
        : notification.emailSubscriptions?.service
          ? t('notifications.costAnomalies.costAnomaly.serviceEmailsSubscribed')
          : undefined
  const [options, setOptions] = useState<MeatballsMenuOption[]>([])
  const [showNestedOptions, setShowNestedOptions] = useState(false)

  const restoreAs = (notifications: CostAnomalyNotification[], status: NotificationState) => {
    const cancelToken = createCancelToken()
    restoreCostAnomalyNotificationsToInbox(notifications, cancelToken.token)
      .then(() => {
        updateCostAnomalyNotificationsStatus(notifications, status, cancelToken.token)
          .then(resp => {
            setNotifications(resp)
            setMessage({
              type: MessageType.SUCCESS,
              message: t('notifications.costAnomalies.toastText.restoreSuccess')
            })
          })
          .catch(handleError)
      })
      .catch(handleError)
  }

  const changeNotificationLimits = (notification: CostAnomalyNotification) => {
    setModal({
      index: 1000,
      header: <CostAnomalySettingsModalHeader notification={notification} />,
      body: <CostAnomalySettingsModalBody notification={notification} />
    })
  }

  const disableNotifications = (notification: CostAnomalyNotification) => {
    const cancelToken = createCancelToken()
    const projectData: DisabledCostNotificationData = {
      vendor: notification.vendor,
      project: notification.project
    }
    disableCostNotifications(projectData, cancelToken.token)
      .then(() => {
        setLoading(true)
        getCostAnomalyNotifications(cancelToken.token)
          .then(setNotifications)
          .catch(handleError)
          .finally(() => {
            setLoading(false)
            setMessage({
              type: MessageType.SUCCESS,
              message: t('notifications.costAnomalies.toastText.notificationsDisableSuccess')
            })
          })
      })
      .catch(handleError)
  }

  const enableNotifications = (notification: CostAnomalyNotification) => {
    const cancelToken = createCancelToken()
    const data = {
      vendor: notification.vendor,
      project: notification.project
    }
    enableCostNotifications([data], cancelToken.token)
      .then(() => {
        setLoading(true)
        getCostAnomalyNotifications(cancelToken.token)
          .then(setNotifications)
          .catch(handleError)
          .finally(() => {
            setLoading(false)
            setMessage({
              type: MessageType.SUCCESS,
              message: t('notifications.costAnomalies.toastText.notificationsEnableSuccess')
            })
          })
      })
      .catch(handleError)
  }

  const enableServiceEmailNotifications = (notification: CostAnomalyNotification) => {
    const cancelToken = createCancelToken()
    subscribeServiceEmailNotifications(notification.vendor, notification.service, cancelToken.token)
      .then(() => {
        setLoading(true)
        getCostAnomalyNotifications(cancelToken.token)
          .then(setNotifications)
          .catch(handleError)
          .finally(() => {
            setLoading(false)
            setMessage({
              type: MessageType.SUCCESS,
              message: t('notifications.costAnomalies.toastText.emailsSubscribed')
            })
          })
      })
      .catch(handleError)
  }

  const disableServiceEmailNotifications = (notification: CostAnomalyNotification) => {
    const cancelToken = createCancelToken()
    unsubscribeServiceEmailNotifications(notification.vendor, notification.service, cancelToken.token)
      .then(() => {
        setLoading(true)
        getCostAnomalyNotifications(cancelToken.token)
          .then(setNotifications)
          .catch(handleError)
          .finally(() => {
            setLoading(false)
            setMessage({
              type: MessageType.SUCCESS,
              message: t('notifications.costAnomalies.toastText.emailsUnsubscribed')
            })
          })
      })
      .catch(handleError)
  }

  const enableProjectEmailNotifications = (notification: CostAnomalyNotification) => {
    const cancelToken = createCancelToken()
    subscribeProjectEmailNotifications(notification.vendor, notification.project.id, cancelToken.token)
      .then(() => {
        setLoading(true)
        getCostAnomalyNotifications(cancelToken.token)
          .then(setNotifications)
          .catch(handleError)
          .finally(() => {
            setLoading(false)
            setMessage({
              type: MessageType.SUCCESS,
              message: t('notifications.costAnomalies.toastText.emailsSubscribed')
            })
          })
      })
      .catch(handleError)
  }

  const disableProjectEmailNotifications = (notification: CostAnomalyNotification) => {
    const cancelToken = createCancelToken()
    unsubscribeProjectEmailNotifications(notification.vendor, notification.project.id, cancelToken.token)
      .then(() => {
        setLoading(true)
        getCostAnomalyNotifications(cancelToken.token)
          .then(setNotifications)
          .catch(handleError)
          .finally(() => {
            setLoading(false)
            setMessage({
              type: MessageType.SUCCESS,
              message: t('notifications.costAnomalies.toastText.emailsUnsubscribed')
            })
          })
      })
      .catch(handleError)
  }

  useEffect(() => {
    !showNestedOptions
      ? setOptions(
          getNotificationMenuOptions(
            t,
            loading,
            notification,
            selectedTab,
            updateReadStatus,
            moveToBin,
            restoreToInbox,
            restoreAs,
            deletePermanently,
            disableNotifications,
            enableNotifications,
            changeNotificationLimits,
            setShowNestedOptions
          )
        )
      : setOptions([
          {
            label: t('common.return'),
            clickHandler: () => setShowNestedOptions(false)
          },
          {
            label: t('notifications.costAnomalies.costAnomaly.menuOptions.projectEmailNotifications', {
              project: t(`vendors.${notification.vendor}.projectPhrase_one`)
            }),
            isCheckbox: true,
            checked: notification.emailSubscriptions?.project,
            disabled: loading,
            clickHandler: () =>
              notification.emailSubscriptions?.project
                ? disableProjectEmailNotifications(notification)
                : enableProjectEmailNotifications(notification)
          },
          {
            label: t('notifications.costAnomalies.costAnomaly.menuOptions.serviceEmailNotifications'),
            disabled: loading,
            isCheckbox: true,
            checked: notification.emailSubscriptions?.service,
            clickHandler: () =>
              notification.emailSubscriptions?.service
                ? disableServiceEmailNotifications(notification)
                : enableServiceEmailNotifications(notification)
          }
        ])
  }, [
    loading,
    deletePermanently,
    moveToBin,
    showNestedOptions,
    notification,
    restoreToInbox,
    selectedTab,
    t,
    updateReadStatus
  ])

  return (
    <div className={'flex justify-between items-center gap-6'}>
      <div>
        <NotificationHeading>
          {INCREASING_COST_ANOMALY_STATES.includes(notification.anomalyState)
            ? t('notifications.costAnomalies.costAnomaly.increasingCostAnomalyHeading')
            : DECREASING_COST_ANOMALY_STATES.includes(notification.anomalyState)
              ? t('notifications.costAnomalies.costAnomaly.decreasingCostAnomalyHeading')
              : t('notifications.costAnomalies.costAnomaly.recoveredCostAnomalyHeading')}
        </NotificationHeading>
        <div className={'flex items-center gap-2'}>
          <GrayText className={'text-80'}>{formatDate(new Date(notification.date), true, false)}</GrayText>
          {notification.emailSubscriptions && (
            <CustomIcon
              path={envelope}
              iconType={IconType.VECTOR}
              styles={'w-4 h-4 bg-gray-300'}
              tooltipText={emailsTooltip}
              tooltipStyles={'min-w-44 mt-3'}
            />
          )}
        </div>
      </div>
      <MeatballsMenu options={options} iconSize={'w-6 h-6'} />
    </div>
  )
}

interface NotificationTextsProps {
  notification: CostAnomalyNotification
}

const NotificationTexts = ({ notification }: NotificationTextsProps) => {
  const { t } = useTranslation()
  const currencySymbol = getCurrency(notification.currency).symbol
  const anomalyColor = INCREASING_COST_ANOMALY_STATES.includes(notification.anomalyState)
    ? 'text-primary-500'
    : DECREASING_COST_ANOMALY_STATES.includes(notification.anomalyState)
      ? 'text-success-100'
      : 'text-gray-50'

  return (
    <div className={'flex flex-col gap-3'}>
      <NotificationTextWrapper>
        <Trans>
          {t('notifications.costAnomalies.costAnomaly.costAnomalyMessageIntro', {
            projectPhrase: t(`vendors.${notification.vendor}.projectPhrase_one`),
            projectName: notification.project.name,
            service: notification.service
          })}
        </Trans>{' '}
        <span className={`inline-block font-semibold ${anomalyColor}`}>
          {t(`notifications.costAnomalies.costAnomaly.${notification.anomalyState}`)}
        </span>
      </NotificationTextWrapper>
      <div>
        <NotificationTextWrapper>
          {t('notifications.costAnomalies.costAnomaly.changeFromLastMonth')}:{' '}
          <div className={`inline-block font-semibold ${anomalyColor}`}>{notification.dailyChangePercent} %</div>
        </NotificationTextWrapper>
        <NotificationTextWrapper>
          <Trans>
            {t('notifications.costAnomalies.costAnomaly.impactOnMonthlyCosts', {
              cost: formatNumber(roundNumber(notification.estMonthlyImpact, 2), 2),
              currency: currencySymbol
            })}
          </Trans>
        </NotificationTextWrapper>
      </div>
      <div>
        <NotificationTextWrapper>
          <Trans>
            {t('notifications.costAnomalies.costAnomaly.lastMonthDailyCost', {
              cost: formatNumber(roundNumber(notification.lastMonthDailyCost, 2), 2),
              currency: currencySymbol
            })}
          </Trans>
        </NotificationTextWrapper>
        <NotificationTextWrapper>
          <Trans>
            {t('notifications.costAnomalies.costAnomaly.currentMonthDailyCost', {
              cost: formatNumber(roundNumber(notification.currentMonthDailyCost, 2), 2),
              currency: currencySymbol
            })}
          </Trans>
        </NotificationTextWrapper>
      </div>
    </div>
  )
}

const getNotificationMenuOptions = (
  t: TFunction,
  loading: boolean,
  notification: CostAnomalyNotification,
  selectedTab: NotificationsTab,
  updateReadStatus: (notifications: CostAnomalyNotification[], status: NotificationState) => void,
  moveToBin: (notifications: CostAnomalyNotification[]) => void,
  restoreToInbox: (notifications: CostAnomalyNotification[]) => void,
  restoreAs: (notifications: CostAnomalyNotification[], status: NotificationState) => void,
  deletePermanently: (notifications: CostAnomalyNotification[]) => void,
  disableNotifications: (request: CostAnomalyNotification) => void,
  enableNotifications: (notification: CostAnomalyNotification) => void,
  changeNotificationLimits: (notification: CostAnomalyNotification) => void,
  setShowNestedOptions: (showNested: boolean) => void
): MeatballsMenuOption[] => {
  const options: MeatballsMenuOption[] = [
    {
      label:
        notification.notificationState === NotificationState.READ
          ? t('notifications.costAnomalies.costAnomaly.menuOptions.markAsUnread')
          : t('notifications.costAnomalies.costAnomaly.menuOptions.markAsRead'),
      disabled: loading,
      clickHandler: () =>
        updateReadStatus(
          [notification],
          notification.notificationState === NotificationState.READ ? NotificationState.UNREAD : NotificationState.READ
        )
    }
  ]

  selectedTab === NotificationsTab.INBOX
    ? options.push({
        label: t('notifications.costAnomalies.costAnomaly.menuOptions.delete'),
        disabled: loading,
        clickHandler: () => moveToBin([notification])
      })
    : options.push(
        {
          label: t('notifications.costAnomalies.costAnomaly.menuOptions.restore'),
          disabled: loading,
          clickHandler: () => restoreToInbox([notification])
        },
        {
          label:
            notification.notificationState === NotificationState.READ
              ? t('notifications.costAnomalies.costAnomaly.menuOptions.restoreAsUnread')
              : t('notifications.costAnomalies.costAnomaly.menuOptions.restoreAsRead'),
          disabled: loading,
          clickHandler: () => {
            restoreAs(
              [notification],
              notification.notificationState === NotificationState.READ
                ? NotificationState.UNREAD
                : NotificationState.READ
            )
          }
        },
        {
          label: t('notifications.costAnomalies.costAnomaly.menuOptions.deletePermanently'),
          disabled: loading,
          clickHandler: () => deletePermanently([notification])
        }
      )

  options.push(
    {
      label: t('notifications.costAnomalies.costAnomaly.menuOptions.changeLimits'),
      disabled: loading,
      clickHandler: () => changeNotificationLimits(notification)
    },
    {
      label: t('notifications.emailNotifications'),
      clickHandler: () => setShowNestedOptions(true),
      nestedOptions: true
    },
    {
      label: t('notifications.costAnomalies.costAnomaly.menuOptions.getNotifications', {
        projectPhrase: t(`vendors.${notification.vendor}.projectPhrase_one`)
      }),
      isCheckbox: true,
      checked: !notification.disabledAt,
      disabled: loading,
      clickHandler: () =>
        !notification.disabledAt ? disableNotifications(notification) : enableNotifications(notification)
    }
  )

  return options
}
