import { AzureUtilization, AzureUtilizationRecommendation } from '../../../../api/optimization/azure'
import { useTranslation } from 'react-i18next'
import { Tree } from '../../../shared/Tree'
import { TreeBranch } from '../../../shared/TreeBranch'
import { Vendor } from '../../../../utils/vendors'
import { OptimizationTabCollapsibleSummary, OptimizationTabTitleRow } from '../OptimizationTabsSharedComponents'
import React from 'react'
import {
  DataTableCell,
  DataTableLabel,
  DataTableRow,
  DataTableValue,
  TabInfoBox
} from '../../../shared/DataTableSharedComponents'
import { ClipboardCopy } from '../../../shared/ClipboardCopy'
import { formatNumber } from '../../../../utils/formats'
import { getCurrency } from '../../../../utils/Currency'

interface OtherRecommendationsTabProps {
  data: AzureUtilization[]
}

export const AzureTabUtilization = ({ data }: OtherRecommendationsTabProps) => {
  const { t } = useTranslation()
  const translation = data.every(r => r.annualSavings !== null)
    ? t('optimization.tabs.azure.utilizationHeaderSummaryAnnualSavings', {
        amount: data.reduce((acc, r) => acc + (r.annualSavings?.amount ?? 0), 0),
        currency: data[0].annualSavings?.currency ? getCurrency(data[0].annualSavings.currency).symbol : 'NA'
      })
    : t('optimization.tabs.common.utilizationHeaderSummary', {
        count: data.length
      })

  return (
    <Tree>
      {data.length > 0 && (
        <TreeBranch
          id={`qa-optimization-${Vendor.AZURE}-tab-commitments`}
          headerData={
            <OptimizationTabCollapsibleSummary
              headerLeft={t('optimization.tabs.common.resourceUtilization')}
              headerRight={translation}
            />
          }
          contentData={<Recommendations data={data} />}
        />
      )}
    </Tree>
  )
}

interface RecommendationsProps {
  data: AzureUtilization[]
}

const Recommendations = ({ data }: RecommendationsProps) => {
  const { t } = useTranslation()
  const titles = [
    t('vendors.AZURE.projectPhrase_one'),
    t('common.resourceName'),
    t('common.identifier'),
    t('common.resourceType_one')
  ]

  return (
    <>
      {data.map((recommendationGroup, index) => (
        <TreeBranch
          id={`qa-optimization-${Vendor.AZURE}-tab-content-${index}`}
          key={index}
          depth={1}
          headerData={
            <OptimizationTabCollapsibleSummary
              headerLeft={recommendationGroup.recommendationName}
              headerRight={t('optimization.tabs.common.utilizationSubHeaderSummary', {
                count: recommendationGroup.recommendations.length
              })}
            />
          }
          contentData={
            <>
              {recommendationGroup.solution && <TabInfoBox textSections={[{ text: recommendationGroup.solution }]} />}
              <OptimizationTabTitleRow
                customColumns={
                  recommendationGroup.annualSavings === null
                    ? 'repeat(4, 1fr)'
                    : 'minmax(15rem, 1fr) repeat(3, minmax(8rem, 1fr)) repeat(2, minmax(8.5rem, auto))'
                }
                labels={
                  recommendationGroup.annualSavings === null
                    ? titles
                    : titles.concat(
                        t('optimization.tabs.common.monthlySavings'),
                        t('optimization.tabs.common.annualSavings')
                      )
                }
              />
              {recommendationGroup.recommendations.map((recommendation, i) => (
                <TreeBranch
                  key={i}
                  depth={2}
                  contentData={false}
                  headerData={
                    <RecommendationRow recommendationGroup={recommendationGroup} recommendation={recommendation} />
                  }
                />
              ))}
            </>
          }
        />
      ))}
    </>
  )
}

interface RecommendationRowProps {
  recommendationGroup: AzureUtilization
  recommendation: AzureUtilizationRecommendation
}

const RecommendationRow = ({ recommendationGroup, recommendation }: RecommendationRowProps) => {
  const { t } = useTranslation()
  const hasSavings = recommendationGroup.annualSavings !== null
  const currency = getCurrency(recommendation.savingsCurrency)
  return (
    <DataTableRow
      customColumns={
        hasSavings
          ? 'minmax(15rem, 1fr) repeat(3, minmax(8rem, 1fr)) repeat(2, minmax(8.5rem, auto))'
          : 'repeat(4, 1fr)'
      }
    >
      <DataTableCell>
        <DataTableLabel>{t('vendors.AZURE.projectPhrase_one')}</DataTableLabel>
        <DataTableValue>
          <ClipboardCopy copyText={recommendation.sub.name} secondCopyText={recommendation.sub.id} limit={25} />
        </DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('common.resourceName')}</DataTableLabel>
        <DataTableValue>{recommendation.resource.name}</DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('common.identifier')}</DataTableLabel>
        <ClipboardCopy copyText={recommendation.resource.id} limit={20} />
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('common.resourceType_one')}</DataTableLabel>
        <DataTableValue>{recommendation.resource.type}</DataTableValue>
      </DataTableCell>
      {recommendationGroup.annualSavings !== null && (
        <>
          <DataTableCell>
            <DataTableLabel>{t('optimization.tabs.common.monthlySavings')}</DataTableLabel>
            <DataTableValue>
              {formatNumber(recommendation.monthlySavings)} {currency.symbol}
            </DataTableValue>
          </DataTableCell>
          <DataTableCell>
            <DataTableLabel>{t('optimization.tabs.common.annualSavings')}</DataTableLabel>
            <DataTableValue>
              {formatNumber(recommendation.annualSavings)} {currency.symbol}
            </DataTableValue>
          </DataTableCell>
        </>
      )}
    </DataTableRow>
  )
}
