import React, { useState } from 'react'
import { CustomIcon, IconType } from '../CustomIcon'
import arrow from '../../../assets/svg/directional/arrow-heavy.svg'
import { InfoTooltip } from '../tooltips/InfoTooltip'
import { useMousePosition } from '../../../hooks/useMousePosition'
import { ColoredText } from '../TextComponents'
import { CountUp } from '../CountUp'
import { formatNumber, roundNumber } from '../../../utils/formats'

interface ChangeIndicatorProps {
  id?: string
  change: number | null
  tooltip?: string
  bold?: boolean
  counter?: boolean
}

export const CostChangeIndicator = ({ id, change, bold, counter, tooltip }: ChangeIndicatorProps) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const mousePos = useMousePosition()
  const color = change === null ? 'gray-50' : change === 0 ? 'tertiary-500' : change > 0 ? 'primary-500' : 'success-100'

  return change === null ? (
    <ColoredText
      className={`flex gap-1 items-center cursor-default ${bold ? 'font-bold' : 'font-semibold'} bg-${color}`}
    >
      -
    </ColoredText>
  ) : (
    <>
      <ColoredText
        className={`flex gap-1 items-center cursor-default ${bold ? 'font-bold' : 'font-semibold'} bg-${color}`}
        onMouseMove={() => {
          setShowTooltip(true)
        }}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {change === 0 ? (
          '-'
        ) : (
          <CustomIcon
            iconType={IconType.VECTOR}
            path={arrow}
            styles={`bg-${color} w-4 h-4 ${change < 0 && 'rotate-180'}`}
          />
        )}
        {counter ? (
          <CountUp id={id} countTo={roundNumber(Math.abs(change), 0)} />
        ) : (
          <span id={id}>{formatNumber(roundNumber(Math.abs(change), 0))}</span>
        )}
        %
      </ColoredText>
      {tooltip && showTooltip && (
        <InfoTooltip x={mousePos.x - 112} y={mousePos.y + 14}>
          {tooltip}
        </InfoTooltip>
      )}
    </>
  )
}
