import { CostAnomalyNotification } from '../../api/notifications'
import { createContext, useState } from 'react'

export interface NotificationsDrawerState {
  notificationsOpen: boolean
  setNotificationsOpen: (isOpen: boolean) => void
}

const defaultNotificationsDrawerState: NotificationsDrawerState = {
  notificationsOpen: false,
  setNotificationsOpen: () => undefined
}

export const NotificationsDrawerContext = createContext<NotificationsDrawerState>(defaultNotificationsDrawerState)

export interface NotificationsState {
  notifications: CostAnomalyNotification[]
  setNotifications: (notifications: CostAnomalyNotification[]) => void
}

const defaultNotificationsState: NotificationsState = {
  notifications: [],
  setNotifications: () => undefined
}

export const NotificationsContext = createContext<NotificationsState>(defaultNotificationsState)

interface Props {
  children: JSX.Element
}

export const NotificationsContextProvider = ({ children }: Props) => {
  const [notificationsOpen, setNotificationsOpen] = useState<boolean>(defaultNotificationsDrawerState.notificationsOpen)
  const [notifications, setNotifications] = useState<CostAnomalyNotification[]>(defaultNotificationsState.notifications)
  const drawerValue = {
    notificationsOpen,
    setNotificationsOpen
  }
  const notificationsValue = {
    notifications,
    setNotifications
  }

  return (
    <NotificationsDrawerContext.Provider value={drawerValue}>
      <NotificationsContext.Provider value={notificationsValue}>{children}</NotificationsContext.Provider>
    </NotificationsDrawerContext.Provider>
  )
}
