import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
// workaround for CRA 5 bug https://github.com/facebook/create-react-app/issues/11770
// eslint-disable-next-line import/no-webpack-loader-syntax
import loadingSpinner from '!file-loader!../../assets/svg/brand/loading-merry-go-round.svg'
import { LayoutContainer } from '../../layout/Dashboard'

interface Props {
  height?: number
  centerScreen?: boolean
  paddingY?: string
}

export const Loading = ({ height = 80, centerScreen = true, paddingY }: Props) => {
  if (centerScreen)
    return (
      <CenterScreenContainer paddingY={paddingY}>
        <Spinner src={loadingSpinner} alt={'loading'} height={height} />
      </CenterScreenContainer>
    )

  return <Spinner src={loadingSpinner} alt={'loading'} height={height} />
}

const CenterScreenContainer = styled.div<Props>`
  ${tw`flex flex-col justify-center items-center w-full h-full`}
  padding-top: ${props => props.paddingY};
  padding-bottom: ${props => props.paddingY};
`

const Spinner = styled.img<{ height: number }>`
  height: ${props => props.height}px;
`

export const FullScreenLoading = () => {
  return (
    <LayoutContainer className={'flex items-center'}>
      <Loading />
    </LayoutContainer>
  )
}
