import React, { useState } from 'react'
import { SustainabilitySummary } from './SustainabilitySummary'
import { SustainabilityChart } from './SustainabilityChart'
import { ViewWrapper } from '../shared/layout/ViewWrapper'
import { SustainabilityFilters } from './SustainabilityFilters'
import { FilterOption } from '../shared/filters/FilterSelect'
import { Vendor } from '../../utils/vendors'
import { SustainabilityTabs } from './SustainabilityTabs'
import { getVendorFilterOptions } from '../shared/filters/Filters'
import { TimeframeOption } from '../../utils/classes'

export const Sustainability = () => {
  const [selectedVendors, setSelectedVendors] = useState<FilterOption[]>([])
  const [selectedProjects, setSelectedProjects] = useState<FilterOption[]>([])
  const [selectedResourceGroups, setSelectedResourceGroups] = useState<FilterOption[]>([])
  const [selectedServices, setSelectedServices] = useState<FilterOption[]>([])
  const [selectedRegions, setSelectedRegions] = useState<FilterOption[]>([])
  const [selectedTimeframe, setSelectedTimeframe] = useState<TimeframeOption>(TimeframeOption.CURRENT_YEAR)
  if (!selectedVendors.length) setSelectedVendors(getVendorFilterOptions())

  return (
    <ViewWrapper>
      <SustainabilityFilters
        selectedTimeframe={selectedTimeframe}
        setSelectedTimeframe={setSelectedTimeframe}
        selectedVendors={selectedVendors}
        setSelectedVendors={setSelectedVendors}
        selectedProjects={selectedProjects}
        setSelectedProjects={setSelectedProjects}
        selectedResourceGroups={selectedResourceGroups}
        setSelectedResourceGroups={setSelectedResourceGroups}
        selectedServices={selectedServices}
        setSelectedServices={setSelectedServices}
        selectedRegions={selectedRegions}
        setSelectedRegions={setSelectedRegions}
      />
      <SustainabilitySummary
        selectedTimeframe={selectedTimeframe}
        selectedVendors={selectedVendors.map(vendor => vendor.value as Vendor)}
        selectedProjects={selectedProjects.map(project => project.value)}
        selectedResourceGroups={selectedResourceGroups.map(rg => rg.value)}
        selectedServices={selectedServices.map(service => service.value)}
        selectedRegions={selectedRegions.map(region => region.value)}
      />
      <SustainabilityChart
        selectedVendors={selectedVendors.map(vendor => vendor.value as Vendor)}
        selectedProjects={selectedProjects.map(project => project.value)}
        selectedResourceGroups={selectedResourceGroups.map(rg => rg.value)}
        selectedServices={selectedServices.map(service => service.value)}
        selectedRegions={selectedRegions.map(region => region.value)}
      />
      <SustainabilityTabs
        selectedTimeframe={selectedTimeframe}
        selectedVendors={selectedVendors.map(vendor => vendor.value as Vendor)}
        selectedProjects={selectedProjects.map(project => project.value)}
        selectedResourceGroups={selectedResourceGroups.map(rg => rg.value)}
        selectedServices={selectedServices.map(service => service.value)}
        selectedRegions={selectedRegions.map(region => region.value)}
      />
    </ViewWrapper>
  )
}
