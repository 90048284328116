import styled from 'styled-components'
import tw from 'twin.macro'
import React from 'react'

export enum BarChartsDisplay {
  CIS = 'CIS',
  score = 'score',
  progress = 'progress',
  pink = 'pink'
}

interface BarChartProps {
  displayStyle: BarChartsDisplay
  colorIndex?: number
  percent: number
  chartLabel?: string
  chartLegend?: string
  legendAlign?: 'left' | 'right'
  height?: string
  labelId?: string
  legendId?: string
}

export const ScoreBarChart = ({
  displayStyle,
  colorIndex,
  percent,
  chartLabel,
  chartLegend,
  legendAlign,
  height,
  labelId,
  legendId
}: BarChartProps) => {
  const indexedPercent = percent < 50 ? 0 : percent >= 50 && percent < 75 ? 1 : 2
  const progressColorStyles = [
    'from-tertiary-600 to-error-100',
    'from-tertiary-500 via-tertiary-500 to-tertiary-400',
    'from-success-500 via-success-100 to-success-100'
  ]

  const pinkColorStyles = [
    'from-primary-500 to-primary-400',
    'from-primary-400 to-primary-300',
    'from-primary-300 to-primary-200',
    'from-primary-200 to-primary-100'
  ]

  const colorStyles = [
    {
      name: 'CIS',
      color: 'from-tertiary-800 via-tertiary-800 to-tertiary-700'
    },
    {
      name: 'score',
      color: 'from-success-500 via-success-100 to-success-100'
    },
    { name: 'progress', color: progressColorStyles[indexedPercent] },
    { name: 'pink', color: pinkColorStyles[colorIndex ?? 0] }
  ]

  const color = colorStyles.find(item => item.name === displayStyle)?.color

  return (
    <BarChartWrapper>
      <BarChartResultsWrapper>
        {chartLabel && <ChartLabel id={labelId}>{chartLabel}</ChartLabel>}
        <BaseBar className={height ? `${height}` : `h-3`}>
          {percent > 0 ? (
            <ScoreBar
              className={`${color}`}
              style={{
                width: `${percent}%`,
                minWidth: '4%'
              }}
            />
          ) : (
            <></>
          )}
        </BaseBar>
        {chartLegend && (
          <ChartLegend id={legendId} className={legendAlign && `text-${legendAlign}`}>
            {chartLegend}
          </ChartLegend>
        )}
      </BarChartResultsWrapper>
    </BarChartWrapper>
  )
}

const BarChartWrapper = styled.div`
  ${tw`flex flex-col w-full gap-4 justify-center lg:justify-start xl:gap-6`}
`

const BarChartResultsWrapper = styled.div`
  ${tw`flex flex-col gap-1`}
`

const ChartLabel = styled.div`
  ${tw`capitalize text-gray-50`}
`

const BaseBar = styled.div`
  ${tw`rounded-lg relative bg-gray-700 w-full`}
`

const ScoreBar = styled.div`
  ${tw`rounded-lg bg-gradient-to-r absolute h-full`}
`

const ChartLegend = styled.div`
  ${tw`first-letter:uppercase text-gray-200`}
`
