import React from 'react'
import { useHistory } from 'react-router-dom'
import { CustomIcon, IconType } from './CustomIcon'
import caret from '../../assets/svg/directional/caret.svg'
import { CapitalizedText } from './TextComponents'

interface NestedPageHeaderProps {
  backButtonPath?: string
  children: React.ReactNode
}

export const NestedPageHeader = ({ backButtonPath, children }: NestedPageHeaderProps) => {
  const history = useHistory()

  return (
    <div className='px-12 py-10 flex gap-8 w-full border-b border-gray-500 justify-between items-center'>
      {backButtonPath && (
        <div
          className={'group rounded-full border p-2.5 border-gray-200 cursor-pointer hover:border-gray-50'}
          onClick={() => history.push(backButtonPath)}
        >
          <CustomIcon
            styles={'w-6 h-6 bg-gray-200 rotate-90 group-hover:bg-gray-50'}
            iconType={IconType.VECTOR}
            path={caret}
          />
        </div>
      )}
      <div className={'flex justify-between items-center w-full gap-8'}>{children}</div>
    </div>
  )
}

interface NestedPageHeaderTitlesProps {
  mainHeading?: string
  children?: React.ReactNode
}

export const NestedPageHeaderTitles = ({ mainHeading, children }: NestedPageHeaderTitlesProps) => {
  return (
    <div className={'flex flex-col w-full gap-1'}>
      {mainHeading && <CapitalizedText className={'font-bold text-gray-50 text-150'}>{mainHeading}</CapitalizedText>}
      {children}
    </div>
  )
}
export const NestedPageHeaderActions = ({ children }: { children: React.ReactNode }) => {
  return <div className={'flex flex-row w-full gap-4 items-center justify-end'}>{children}</div>
}
