import styled from 'styled-components'
import tw from 'twin.macro'
import { CapitalizedText, GrayText } from '../shared/TextComponents'
import { CostAnomalyNotification, NotificationState } from '../../api/notifications'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { CostNotificationDataKey } from '../shared/Enums'
import { toast } from 'react-toastify'
import { ScrollContainer } from '../shared/containers/ScrollContainer'
import { NotificationsDrawerContext } from '../../state/context/NotificationsContext'
import { ToastActionComponent } from '../../global-message/GlobalMessage'

interface NotificationsListWrapperProps {
  height?: number
}

export const NotificationsListWrapper = styled(ScrollContainer)<NotificationsListWrapperProps>`
  ${tw`flex flex-col w-full divide-y divide-gray-500 shadow-none`}
  height: ${({ height }) => height}px;
`

export const NotificationWrapper = styled.div`
  ${tw`grid gap-4 p-4 items-center`}
  grid-template-columns: auto 1fr;

  div {
    ${tw`transition-all ease-in-out duration-300`}
  }
`

export const NotificationContentWrapper = styled.div`
  ${tw`flex flex-col gap-4 row-start-2 col-start-2`}
`

export const NotificationsDescriptionWrapper = styled.div`
  ${tw`flex items-center justify-end gap-8 p-4 min-h-20`}
`

export const NoNotificationsText = styled(GrayText)`
  ${tw`p-4`}
`

export const NotificationHeading = styled(CapitalizedText)`
  ${tw`text-gray-50 font-semibold`}
`

export const NotificationTextWrapper = styled(CapitalizedText)`
  ${tw`text-90 text-gray-200`}
  strong {
    ${tw`font-semibold text-gray-50`}
  }
`

interface ViewNotificationCostsButtonProps {
  notification: CostAnomalyNotification
  updateReadState: (notifications: CostAnomalyNotification[], status: NotificationState) => void
}

export const ViewNotificationCostsButton = ({ notification, updateReadState }: ViewNotificationCostsButtonProps) => {
  const { t } = useTranslation()
  const { setNotificationsOpen } = useContext(NotificationsDrawerContext)
  const history = useHistory()

  const directToNotificationCosts = () => {
    sessionStorage.setItem(CostNotificationDataKey.VENDOR, notification.vendor)
    sessionStorage.setItem(CostNotificationDataKey.PROJECT_ID, notification.project.id)
    sessionStorage.setItem(CostNotificationDataKey.PROJECT_NAME, notification.project.name)
    sessionStorage.setItem(CostNotificationDataKey.SERVICE, notification.service)

    history.push('/costs', { from: 'cost-notification' })
    setNotificationsOpen(false)
    toast.dismiss()
  }

  return (
    <ToastActionComponent
      label={t('notifications.costAnomalies.costAnomaly.viewCosts')}
      onClick={() => {
        directToNotificationCosts()
        updateReadState([notification], NotificationState.READ)
      }}
    />
  )
}
