import {
  IntegrationAccountStatusRow,
  IntegrationDetailRow,
  IntegrationDetailRowsWrapper,
  IntegrationDetailsHeader
} from '../Shared'
import { AwsAccountType } from './AwsIntegrationDetails'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AwsIntegrationData } from '../../../../api/admin/integrations/aws'
import { formatDate } from '../../../../utils/formats'
import { AdminTabContent } from '../../adminStyles'

interface AwsIntegrationTimeBlocksProps {
  selectedIntegration: AwsIntegrationData
}

export const AwsIntegrationTimestamps = ({ selectedIntegration }: AwsIntegrationTimeBlocksProps) => {
  return (
    <>
      <MasterAccountSection selectedIntegration={selectedIntegration} />
      <SecurityAccountSection selectedIntegration={selectedIntegration} />
      <ConfigAccountSection selectedIntegration={selectedIntegration} />
    </>
  )
}

interface AccountSectionProps {
  selectedIntegration: AwsIntegrationData
}

const MasterAccountSection = ({ selectedIntegration }: AccountSectionProps) => {
  const { t } = useTranslation()
  return (
    <AdminTabContent>
      <IntegrationDetailsHeader
        header={t('admin.integrations.aws.account', { type: AwsAccountType.MASTER })}
        headerAction={false}
      />
      <IntegrationDetailRowsWrapper
        hasActions={false}
        detailRows={
          <>
            <IntegrationDetailRow
              label={t('admin.integrations.organization')}
              value={formatDate(selectedIntegration.master.lastIntegrations?.organization, true, true, true)}
            />
            <IntegrationDetailRow
              label={t('sideNav.costs')}
              value={formatDate(selectedIntegration.master.lastIntegrations?.costs, true, true, true)}
            />
            <IntegrationDetailRow
              label={t('sideNav.optimization')}
              value={formatDate(selectedIntegration.master.lastIntegrations?.optimization, true, true, true)}
            />
            <IntegrationDetailRow
              label={t('sideNav.sustainability')}
              value={formatDate(selectedIntegration.master.lastIntegrations?.sustainability, true, true, true)}
            />
          </>
        }
      />
    </AdminTabContent>
  )
}

const SecurityAccountSection = ({ selectedIntegration }: AccountSectionProps) => {
  const { t } = useTranslation()
  const [collapsedSecurityAccounts, setCollapsedSecurityAccounts] = useState<number[]>([])
  return (
    <AdminTabContent>
      <IntegrationDetailsHeader
        header={t('admin.integrations.aws.account', { type: AwsAccountType.SECURITY })}
        headerAction={false}
      />
      {selectedIntegration.security.map((account, index) => (
        <IntegrationDetailRowsWrapper
          key={index}
          hasActions={false}
          detailRows={
            <>
              <IntegrationDetailRow
                label={t('sideNav.compliance')}
                value={formatDate(account.lastIntegrationAt, true, true, true)}
              />
              <IntegrationDetailRow
                label={t('admin.integrations.accountId')}
                isCollapsed={collapsedSecurityAccounts.includes(index)}
                collapseHandler={() =>
                  !collapsedSecurityAccounts.includes(index)
                    ? setCollapsedSecurityAccounts([...collapsedSecurityAccounts, index])
                    : setCollapsedSecurityAccounts(collapsedSecurityAccounts.filter(item => item !== index))
                }
                value={account.accountId}
              />
              {collapsedSecurityAccounts.includes(index) && (
                <>
                  <IntegrationDetailRow label={t('common.region_one')} value={account.region} />
                  <IntegrationAccountStatusRow accountDeleteDate={account.deletedAt} />
                </>
              )}
            </>
          }
        />
      ))}
    </AdminTabContent>
  )
}

const ConfigAccountSection = ({ selectedIntegration }: AccountSectionProps) => {
  const { t } = useTranslation()
  const [collapsedConfigAccounts, setCollapsedConfigAccounts] = useState<number[]>([])
  return (
    <AdminTabContent>
      <IntegrationDetailsHeader
        header={t('admin.integrations.aws.account', { type: AwsAccountType.CONFIG })}
        headerAction={false}
      />
      {selectedIntegration.config.map((account, index) => (
        <IntegrationDetailRowsWrapper
          key={index}
          hasActions={false}
          detailRows={
            <>
              <IntegrationDetailRow
                label={t('sideNav.infra')}
                value={formatDate(account.lastIntegrationAt, true, true, true)}
              />
              <IntegrationDetailRow
                label={t('admin.integrations.accountId')}
                isCollapsed={collapsedConfigAccounts.includes(index)}
                collapseHandler={() =>
                  !collapsedConfigAccounts.includes(index)
                    ? setCollapsedConfigAccounts([...collapsedConfigAccounts, index])
                    : setCollapsedConfigAccounts(collapsedConfigAccounts.filter(item => item !== index))
                }
                value={account.accountId}
              />
              {collapsedConfigAccounts.includes(index) && (
                <>
                  <IntegrationDetailRow label={t('common.region_one')} value={account.region} />
                  <IntegrationDetailRow
                    label={t('admin.integrations.aws.aggregatorName')}
                    value={account.aggregatorName}
                  />
                  <IntegrationAccountStatusRow accountDeleteDate={account.deletedAt} />
                </>
              )}
            </>
          }
        />
      ))}
    </AdminTabContent>
  )
}
