import {
  IntegrationAccountStatusRow,
  IntegrationDetailRow,
  IntegrationDetailRowsWrapper,
  IntegrationDetailsHeader
} from '../Shared'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { GcpIntegrationData } from '../../../../api/admin/integrations/gcp'
import { formatDate } from '../../../../utils/formats'
import { AdminTabContent } from '../../adminStyles'

interface GcpIntegrationTimestampsProps {
  selectedIntegration: GcpIntegrationData
}

export const GcpIntegrationTimestamps = ({ selectedIntegration }: GcpIntegrationTimestampsProps) => {
  return (
    <>
      <OrganizationSection selectedIntegration={selectedIntegration} />
      <BillingAccountsSection selectedIntegration={selectedIntegration} />
    </>
  )
}

interface SectionProps {
  selectedIntegration: GcpIntegrationData
}

const OrganizationSection = ({ selectedIntegration }: SectionProps) => {
  const { t } = useTranslation()
  return (
    <AdminTabContent>
      <IntegrationDetailsHeader header={t('admin.integrations.gcp.organization')} headerAction={false} />
      <IntegrationDetailRowsWrapper
        hasActions={false}
        detailRows={
          <>
            <IntegrationDetailRow
              label={t('admin.integrations.organization')}
              value={formatDate(selectedIntegration.lastIntegrations.organization, true, true, true)}
            />
            <IntegrationDetailRow
              label={t('sideNav.optimization')}
              value={formatDate(selectedIntegration.lastIntegrations.optimization, true, true, true)}
            />
            <IntegrationDetailRow
              label={t('sideNav.compliance')}
              value={formatDate(selectedIntegration.lastIntegrations.compliance, true, true, true)}
            />
            <IntegrationDetailRow
              label={t('sideNav.infra')}
              value={formatDate(selectedIntegration.lastIntegrations.infra, true, true, true)}
            />
          </>
        }
      />
    </AdminTabContent>
  )
}

const BillingAccountsSection = ({ selectedIntegration }: SectionProps) => {
  const { t } = useTranslation()
  const [collapsedAccounts, setCollapsedAccounts] = useState<number[]>([])
  return (
    <AdminTabContent>
      <IntegrationDetailsHeader header={t('admin.integrations.gcp.billingAccount_other')} headerAction={false} />
      {selectedIntegration.billingAccounts.map((account, index) => (
        <IntegrationDetailRowsWrapper
          key={index}
          hasActions={false}
          detailRows={
            <>
              <IntegrationDetailRow
                label={t('sideNav.costs')}
                value={formatDate(account.lastIntegrations.costs, true, true, true)}
              />
              <IntegrationDetailRow
                label={t('sideNav.sustainability')}
                value={formatDate(account.lastIntegrations.sustainability, true, true, true)}
              />
              <IntegrationDetailRow
                label={t('admin.integrations.accountId')}
                isCollapsed={collapsedAccounts.includes(index)}
                collapseHandler={() =>
                  !collapsedAccounts.includes(index)
                    ? setCollapsedAccounts([...collapsedAccounts, index])
                    : setCollapsedAccounts(collapsedAccounts.filter(item => item !== index))
                }
                value={account.accountId}
              />
              {collapsedAccounts.includes(index) && (
                <>
                  <IntegrationDetailRow label={t('admin.integrations.gcp.projectId')} value={account.projectId} />
                  <IntegrationDetailRow label={t('admin.integrations.gcp.tableId')} value={account.tableId} />
                  <IntegrationAccountStatusRow accountDeleteDate={account.deletedAt} />
                </>
              )}
            </>
          }
        />
      ))}
    </AdminTabContent>
  )
}
