import { useCancelToken } from '../../../../api/client'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'
import { MessageContext, MessageType } from '../../../../state/context/MessageContext'
import { ModalContext } from '../../../../state/context/ModalContext'
import { useErrorHandling } from '../../../../hooks/handleError'
import {
  addAwsConfigAccount,
  addAwsSecurityAccount,
  AwsConfigAccount,
  AwsIntegrationData,
  AwsMasterAccount,
  AwsSecurityAccount,
  editAwsMasterAccount,
  getAwsIntegrationDetails,
  getAwsRegionOptions,
  recoverAwsConfigAccount,
  recoverAwsIntegration,
  recoverAwsSecurityAccount,
  softDeleteAwsConfigAccount,
  softDeleteAwsIntegration,
  softDeleteAwsSecurityAccount
} from '../../../../api/admin/integrations/aws'
import { NestedPageHeader } from '../../../shared/NestedPageHeader'
import { AdminContentWrapper } from '../../adminStyles'
import {
  DetailedIntegrationModal,
  IntegrationDetailsInfoSection,
  IntegrationDetailsPageHeader,
  IntegrationDetailsWrapper,
  IntegrationModalHighlight,
  IntegrationsModalBody,
  IntegrationsModalInputs,
  IntegrationsTab,
  IntegrationsTabs,
  LoadingIntegrationDetails
} from '../Shared'
import { CustomInput, CustomLabel } from '../../../shared/filters/InputComponents'
import { Controller, useForm } from 'react-hook-form'
import { FilterOption } from '../../../shared/filters/FilterSelect'
import { Button, ButtonStyle, ButtonType } from '../../../shared/buttons/Button'
import { ModalActions } from '../../../shared/modal/Modal'
import { AwsRegionMenu } from './AwsIntegrations'
import { TabsContainer } from '../../../shared/tabs/TabSharedComponents'
import { AwsIntegrationAccounts } from './AwsIntegrationAccounts'
import { AwsIntegrationTimestamps } from './AwsIntegrationTimestamps'

export enum AwsAccountType {
  MASTER = 'master',
  SECURITY = 'security',
  CONFIG = 'config'
}

export const AwsIntegrationDetails = () => {
  const { createCancelToken } = useCancelToken()
  const { t } = useTranslation()
  const history = useHistory()
  const { masterAccountId } = useParams<{
    masterAccountId: string
  }>()
  const { setMessage } = useContext(MessageContext)
  const { setModal } = useContext(ModalContext)
  const handleError = useErrorHandling()
  const [selectedIntegration, setSelectedIntegration] = useState<AwsIntegrationData | null>(null)

  useEffect(() => {
    getAwsIntegrationDetails(masterAccountId, createCancelToken().token)
      .then(resp => {
        setSelectedIntegration(resp)
      })
      .catch(handleError)
    return () => {
      setSelectedIntegration(null)
      createCancelToken().cancel()
    }
  }, [masterAccountId, createCancelToken, handleError])

  if (!selectedIntegration || !selectedIntegration.master) return <LoadingIntegrationDetails />

  const editIntegration = (request: AwsMasterAccount) => {
    editAwsMasterAccount(request)
      .then(resp => {
        setSelectedIntegration(resp)
        setModal(null)
        setMessage({
          type: MessageType.SUCCESS,
          message: t('admin.integrations.aws.editSuccessToast')
        })
      })
      .catch(handleError)
  }

  const openDeleteModal = () => {
    const deleteAction = () =>
      softDeleteAwsIntegration(selectedIntegration.master.accountId)
        .then(() => {
          history.push('/admin/integrations/aws')
          setModal(null)
          setMessage({
            type: MessageType.SUCCESS,
            message: t('admin.integrations.aws.accountRemoveSuccessToast', { type: AwsAccountType.MASTER })
          })
        })
        .catch(handleError)

    setModal({
      header: t('admin.integrations.confirmRemoveRequest'),
      body: (
        <DetailedIntegrationModal
          description={t('admin.integrations.aws.removeDescription')}
          detailRows={[
            { label: t('admin.integrations.accountId'), value: selectedIntegration.master.accountId },
            { label: t('admin.integrations.name'), value: selectedIntegration.master.name }
          ]}
          action={deleteAction}
          actionLabel={t('admin.integrations.remove')}
        />
      )
    })
  }

  const recoverIntegration = () => {
    recoverAwsIntegration(selectedIntegration.master.accountId)
      .then(() => {
        getAwsIntegrationDetails(masterAccountId, createCancelToken().token)
          .then(resp => {
            setSelectedIntegration(resp)
            setModal(null)
            setMessage({
              type: MessageType.SUCCESS,
              message: t('admin.integrations.aws.accountRecoverSuccessToast', { type: AwsAccountType.MASTER })
            })
          })
          .catch(handleError)
      })
      .catch(handleError)
  }

  const openAccountAddModal = (type: AwsAccountType, account?: AwsSecurityAccount | AwsConfigAccount) => {
    const recoverAccount = (account: AwsSecurityAccount | AwsConfigAccount) => {
      type === 'config'
        ? recoverAwsConfigAccount(masterAccountId, account as AwsConfigAccount)
            .then(resp => {
              setSelectedIntegration(resp)
              setModal(null)
              setMessage({
                type: MessageType.SUCCESS,
                message: t('admin.integrations.aws.accountRecoverSuccessToast', { type: type })
              })
            })
            .catch(handleError)
        : recoverAwsSecurityAccount(masterAccountId, account as AwsSecurityAccount)
            .then(resp => {
              setSelectedIntegration(resp)
              setModal(null)
              setMessage({
                type: MessageType.SUCCESS,
                message: t('admin.integrations.aws.accountRecoverSuccessToast', { type: type })
              })
            })
            .catch(handleError)
    }

    const addAccount = (account: AwsSecurityAccount | AwsConfigAccount) => {
      if (type === 'config') {
        addAwsConfigAccount(masterAccountId, account as AwsConfigAccount)
          .then(resp => {
            const existingAccount = resp.config.find(
              a =>
                a.accountId === account.accountId &&
                a.region.value === account.region.value &&
                a.aggregatorName === (account as AwsConfigAccount).aggregatorName
            )
            if (existingAccount?.deletedAt) {
              setModal({
                header: t('admin.integrations.aws.accountRecoveryHeader', { type: type }),
                body: (
                  <DetailedIntegrationModal
                    description={t('admin.integrations.aws.accountRecoveryMessage', { type: type })}
                    detailRows={[
                      { label: t('admin.integrations.accountId'), value: existingAccount.accountId },
                      { label: t('common.region_one'), value: existingAccount.region },
                      { label: t('admin.integrations.aws.aggregatorName'), value: existingAccount.aggregatorName }
                    ]}
                    action={() => recoverAccount(existingAccount)}
                    actionLabel={t('admin.integrations.recover')}
                  />
                )
              })
            } else {
              setSelectedIntegration(resp)
              setModal(null)
              setMessage({
                type: MessageType.SUCCESS,
                message: t('admin.integrations.aws.accountAddSuccessToast', { type: type })
              })
            }
          })
          .catch(handleError)
      } else
        addAwsSecurityAccount(masterAccountId, account as AwsSecurityAccount)
          .then(resp => {
            const existingAccount = resp.security.find(
              a => a.accountId === account.accountId && a.region.value === account.region.value
            )
            if (existingAccount?.deletedAt) {
              setModal({
                header: t('admin.integrations.aws.accountRecoveryHeader', { type: type }),
                body: (
                  <DetailedIntegrationModal
                    description={t('admin.integrations.aws.accountRecoveryMessage', { type: type })}
                    detailRows={[
                      { label: t('admin.integrations.accountId'), value: existingAccount.accountId },
                      { label: t('common.region_one'), value: existingAccount.region }
                    ]}
                    action={() => recoverAccount(existingAccount)}
                    actionLabel={t('admin.integrations.recover')}
                  />
                )
              })
            } else {
              setSelectedIntegration(resp)
              setModal(null)
              setMessage({
                type: MessageType.SUCCESS,
                message: t('admin.integrations.aws.accountAddSuccessToast', { type: type })
              })
            }
          })
          .catch(handleError)
    }

    if (account) {
      recoverAccount(account)
    } else
      setModal({
        header: t('admin.integrations.aws.addAccount', { type: type }),
        body: <AddAccountModal submitAction={addAccount} type={type} />
      })
  }

  const openAccountDeleteModal = (type: AwsAccountType, account: AwsSecurityAccount | AwsConfigAccount) => {
    const deleteAction = () => {
      type === 'config'
        ? softDeleteAwsConfigAccount(masterAccountId, account as AwsConfigAccount)
            .then(resp => {
              setSelectedIntegration(resp)
              setModal(null)
              setMessage({
                type: MessageType.SUCCESS,
                message: t('admin.integrations.aws.accountRemoveSuccessToast', { type: type })
              })
            })
            .catch(handleError)
        : softDeleteAwsSecurityAccount(masterAccountId, account as AwsSecurityAccount)
            .then(resp => {
              setSelectedIntegration(resp)
              setModal(null)
              setMessage({
                type: MessageType.SUCCESS,
                message: t('admin.integrations.aws.accountRemoveSuccessToast', { type: type })
              })
            })
            .catch(handleError)
    }

    const detailRows = [
      { label: t('admin.integrations.accountId'), value: account.accountId },
      { label: t('common.region_one'), value: account.region.label }
    ]
    'aggregatorName' in account &&
      detailRows.push({ label: t('admin.integrations.aws.aggregatorName'), value: account.aggregatorName })

    setModal({
      header: t('admin.integrations.confirmRemoveRequest'),
      body: (
        <DetailedIntegrationModal
          description={t('admin.integrations.aws.removeAccountDescription', { type: type })}
          detailRows={detailRows}
          action={deleteAction}
          actionLabel={t('admin.integrations.remove')}
        />
      )
    })
  }

  return (
    <div className={'flex flex-col w-full'}>
      <NestedPageHeader backButtonPath={'/admin/integrations/aws'}>
        <IntegrationDetailsPageHeader
          name={selectedIntegration.master.name}
          lastIntegrationAt={selectedIntegration.master.lastIntegrationAt}
          buttonValue={selectedIntegration.master.deletedAt && t('admin.integrations.recover')}
          buttonAction={!!selectedIntegration.master?.deletedAt && recoverIntegration}
          deleteAction={!selectedIntegration.master?.deletedAt && openDeleteModal}
        />
      </NestedPageHeader>
      <AdminContentWrapper className={'max-h-[80vh] xl:max-h-[86vh]'}>
        <IntegrationDetails
          selectedIntegration={selectedIntegration}
          submitAction={editIntegration}
          openAccountAddModal={openAccountAddModal}
          openAccountDeleteModal={openAccountDeleteModal}
        />
      </AdminContentWrapper>
    </div>
  )
}

interface IntegrationDetailsProps {
  selectedIntegration: AwsIntegrationData
  submitAction: (request: AwsMasterAccount) => void
  openAccountAddModal: (type: AwsAccountType, account?: AwsSecurityAccount | AwsConfigAccount) => void
  openAccountDeleteModal: (type: AwsAccountType, account: AwsSecurityAccount | AwsConfigAccount) => void
}

const IntegrationDetails = ({
  selectedIntegration,
  submitAction,
  openAccountAddModal,
  openAccountDeleteModal
}: IntegrationDetailsProps) => {
  const [selectedTab, setSelectedTab] = useState<IntegrationsTab>(IntegrationsTab.DETAILS)
  return (
    <TabsContainer>
      <IntegrationsTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <IntegrationDetailsWrapper>
        <IntegrationDetailsInfoSection removeDate={selectedIntegration.master.deletedAt} selectedTab={selectedTab} />

        {selectedTab === IntegrationsTab.DETAILS ? (
          <AwsIntegrationAccounts
            selectedIntegration={selectedIntegration}
            submitAction={submitAction}
            openAccountAddModal={openAccountAddModal}
            openAccountDeleteModal={openAccountDeleteModal}
          />
        ) : (
          <AwsIntegrationTimestamps selectedIntegration={selectedIntegration} />
        )}
      </IntegrationDetailsWrapper>
    </TabsContainer>
  )
}

interface AddAccountModalProps {
  type: AwsAccountType
  submitAction: (request: AwsSecurityAccount | AwsConfigAccount) => void
}

export const AddAccountModal = ({ type, submitAction }: AddAccountModalProps) => {
  const { t } = useTranslation()
  const { setModal } = useContext(ModalContext)
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const [regions, setRegions] = useState<FilterOption[]>([])
  const {
    register,
    control,
    handleSubmit,
    formState: { isValid, isSubmitting }
  } = useForm<AwsSecurityAccount | AwsConfigAccount>({
    defaultValues: {
      accountId: '',
      region: { label: '', value: '' },
      aggregatorName: type === 'config' ? '' : undefined
    }
  })

  useEffect(() => {
    getAwsRegionOptions(createCancelToken().token).then(setRegions).catch(handleError)
  }, [createCancelToken, handleError])

  const onSubmit = (data: AwsSecurityAccount) => {
    submitAction(data)
  }

  return (
    <form>
      <IntegrationsModalBody>
        <IntegrationModalHighlight type={'create'} path={'aws-get-started-guide'} />
        <IntegrationsModalInputs>
          <div>
            <CustomLabel>{t('admin.integrations.accountId')} *</CustomLabel>
            <CustomInput
              type={'number'}
              {...register('accountId', {
                required: true
              })}
              autoFocus={true}
            />
          </div>
          <div>
            <CustomLabel>{t('common.region_one')} *</CustomLabel>
            <Controller
              name={'region'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <AwsRegionMenu regions={regions} value={value} onChange={onChange} />
              )}
              rules={{ required: true }}
            />
          </div>
          {type === 'config' && (
            <div>
              <CustomLabel>{t('admin.integrations.aws.aggregatorName')} *</CustomLabel>
              <CustomInput
                {...register('aggregatorName', {
                  required: true,
                  minLength: 1
                })}
              />
            </div>
          )}
        </IntegrationsModalInputs>
      </IntegrationsModalBody>
      <ModalActions>
        <Button
          value={t('common.cancel')}
          clickHandler={() => setModal(null)}
          type={ButtonType.MODAL}
          buttonStyle={ButtonStyle.SECONDARY}
        />
        <Button
          disabled={!isValid || isSubmitting}
          value={t('admin.integrations.addNew')}
          clickHandler={handleSubmit(onSubmit)}
          type={ButtonType.MODAL}
        />
      </ModalActions>
    </form>
  )
}
