import React, { createContext, useMemo, useState } from 'react'
import { CurrencyId } from '../../utils/Currency'
import { CostAnomalyLimits } from '../../api/notifications'
import { UserSettings } from '../../api/user/profile'
import { Vendors } from '../../utils/vendors'

export interface UserSettingsState {
  userSettings: UserSettings
  setUserSettings: (settings: UserSettings) => void
}

const defaultCostAnomalyLimits: CostAnomalyLimits = {
  dailyChangePercent: {
    decrease: -10,
    increase: 10
  },
  estMonthlyImpact: {
    decrease: -100,
    increase: 100
  }
}

export const defaultUserSettingsState: UserSettingsState = {
  userSettings: {
    currency: CurrencyId.EUR,
    defaultCostAnomalyLimits: defaultCostAnomalyLimits,
    visibleVendors: Vendors
  },
  setUserSettings: () => undefined
}

export const UserSettingsContext = createContext<UserSettingsState>(defaultUserSettingsState)

interface Props {
  children: JSX.Element
}

export const UserSettingsContextProvider = React.memo(({ children }: Props) => {
  const [userSettings, setUserSettings] = useState<UserSettings>(defaultUserSettingsState.userSettings)
  const value = useMemo(
    () => ({
      userSettings,
      setUserSettings
    }),
    [userSettings, setUserSettings]
  )

  return <UserSettingsContext.Provider value={value}>{children}</UserSettingsContext.Provider>
})
