// session storage
export const setSignupEmail = (email: string) => sessionStorage.setItem('signupEmail', email)
export const getSignupEmail = () => {
  const email = sessionStorage.getItem('signupEmail')
  return email && email !== 'null' ? email : null
}
export const removeSignupEmail = () => sessionStorage.removeItem('signupEmail')

export const setFirstLogin = () => sessionStorage.setItem('firstLogin', 'true')
export const firstLogin = () => sessionStorage.getItem('firstLogin') === 'true'
export const removeFirstLogin = () => sessionStorage.removeItem('firstLogin')

// local storage
export const setNotificationProject = (project: string) => localStorage.setItem('notificationProject', project)
export const getNotificationProject = () => localStorage.getItem('notificationProject')
export const removeNotificationProject = () => localStorage.removeItem('notificationProject')
export const setNotificationService = (service: string) => localStorage.setItem('notificationService', service)
export const getNotificationService = () => localStorage.getItem('notificationService')
export const removeNotificationService = () => localStorage.removeItem('notificationService')
