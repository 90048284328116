import { Vendor, Vendors } from '../../../utils/vendors'
import styled from 'styled-components'
import tw from 'twin.macro'
import React, { useContext } from 'react'
import { TabOptionsWrapper } from './TabSharedComponents'
import { Tab } from './Tab'
import { useHistory } from 'react-router-dom'
import { AuthInfoContext } from '../../../state/context/AuthInfoContext'

interface TabsProps {
  id?: string
  useRoute?: boolean
  showSummaryTab?: boolean
  handleSelection: (vendor: Vendor) => void
  selectedVendor: Vendor
  actionComponent?: React.ReactNode
  showAll?: boolean
}

export const VendorTabs = ({
  id,
  useRoute,
  showSummaryTab,
  handleSelection,
  actionComponent,
  selectedVendor,
  showAll
}: TabsProps) => {
  const { authInfo } = useContext(AuthInfoContext)
  return (
    <TabOptions>
      <div className={'flex w-full gap-2'}>
        {showSummaryTab && (
          <VendorTab id={id} vendor={Vendor.ALL} handleSelection={handleSelection} selectedVendor={selectedVendor} />
        )}
        {Vendors.filter(vendor =>
          !authInfo.awsAccess || (!showAll && !authInfo.awsIntegration.integration) ? vendor !== Vendor.AWS : true
        )
          .filter(vendor =>
            !authInfo.azureAccess || (!showAll && !authInfo.azureIntegration.integration)
              ? vendor !== Vendor.AZURE
              : true
          )
          .filter(vendor =>
            !authInfo.gcpAccess || (!showAll && !authInfo.gcpIntegration.integration) ? vendor !== Vendor.GCP : true
          )
          .map(vendor => (
            <VendorTab
              id={id}
              useRoute={useRoute}
              handleSelection={handleSelection}
              key={vendor}
              vendor={vendor}
              selectedVendor={selectedVendor}
            />
          ))}
      </div>
      {actionComponent && <div className={'pr-4'}>{actionComponent}</div>}
    </TabOptions>
  )
}

const TabOptions = styled(TabOptionsWrapper)`
  ${tw`flex justify-between items-center mx-2`}
`

interface VendorTabProps {
  vendor: Vendor
  id?: string
  useRoute?: boolean
  handleSelection: (vendor: Vendor) => void
  selectedVendor: Vendor
}

const VendorTab = ({ vendor, id, useRoute, handleSelection, selectedVendor }: VendorTabProps) => {
  const history = useHistory()
  const initialPath = history.location.pathname
    .split('/')
    .filter(path => !Vendors.find(vendor => vendor === path.toUpperCase()))
    .join('/')
  return (
    <Tab
      id={id}
      selectedTab={selectedVendor}
      tabValue={vendor}
      handleSelection={() => {
        handleSelection(vendor)
        useRoute && history.push(`${initialPath}/${vendor.toLowerCase()}`)
      }}
    />
  )
}
