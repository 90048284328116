import React, { useEffect } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useScreenSize } from '../../../hooks/useScreenSize'

interface DataContainerProps {
  children: React.ReactNode
  looseSpacing?: boolean
  flexCol?: boolean
  scrollOverflow?: boolean
  subNav?: boolean
  filter?: boolean
}

export const DataContainer = ({
  children,
  looseSpacing = false,
  flexCol = false,
  scrollOverflow = false,
  subNav = false,
  filter = false
}: DataContainerProps) => {
  const screenSize = useScreenSize()

  const scrollToBottom = () => {
    const dataContainer =
      document.getElementById('data-container') || document.getElementById('sub-nav-layout-container')
    window.addEventListener('click', () => {
      dataContainer &&
        dataContainer.scrollTo({
          top: dataContainer.scrollHeight,
          behavior: 'smooth'
        })
    })
  }

  useEffect(() => {
    scrollOverflow && scrollToBottom()
    return () => window.removeEventListener('click', scrollToBottom)
  }, [scrollOverflow])

  return (
    <>
      {subNav ? (
        <BasicStyledContainer
          id={'sub-nav-layout-container'}
          className={'flex-col lg:flex-row'}
          style={{ minHeight: `${screenSize.height - 24}px` }}
        >
          {children}
        </BasicStyledContainer>
      ) : (
        <CenteredDataContainer
          id={'data-container'}
          className={'no-scrollbar'}
          looseSpacing={looseSpacing}
          flexCol={flexCol}
          scrollOverflow={scrollOverflow}
          filter={filter}
        >
          {children}
        </CenteredDataContainer>
      )}
    </>
  )
}

const BasicStyledContainer = styled.div`
  ${tw`flex rounded-md bg-gray-600 shadow-container`}
`

interface StyledContainerProps {
  looseSpacing: boolean
  flexCol: boolean
  scrollOverflow: boolean
  filter?: boolean
}

const CenteredDataContainer = styled(BasicStyledContainer)<StyledContainerProps>`
  ${tw`relative items-center justify-center`}
  ${({ flexCol }) => (flexCol ? tw`flex-col` : tw`flex-row`)}
  ${({ looseSpacing }) => (looseSpacing ? tw`pb-6 p-8 xl:p-12` : tw`p-8`)}
  ${({ filter }) => filter && tw`pb-6`}
  ${({ scrollOverflow }) => (scrollOverflow ? tw`overflow-y-auto` : tw`overflow-y-visible`)}
`
