import { NestedPageHeader } from '../../../shared/NestedPageHeader'
import {
  DetailedIntegrationModal,
  IntegrationDetailRow,
  IntegrationDetailRowsWrapper,
  IntegrationDetailsHeader,
  IntegrationDetailsInfoSection,
  IntegrationDetailsPageHeader,
  IntegrationDetailsWrapper,
  IntegrationsTab,
  IntegrationsTabs,
  LoadingIntegrationDetails
} from '../Shared'
import { AdminContentWrapper, AdminTabContent } from '../../adminStyles'
import React, { useContext, useEffect, useState } from 'react'
import {
  AzureIntegration,
  deleteAzureIntegration,
  editAzureIntegration,
  getAzureIntegrationDetails,
  recoverAzureIntegration
} from '../../../../api/admin/integrations/azure'
import { useTranslation } from 'react-i18next'
import { MessageContext, MessageType } from '../../../../state/context/MessageContext'
import { useHistory, useParams } from 'react-router-dom'
import { useErrorHandling } from '../../../../hooks/handleError'
import { ModalContext } from '../../../../state/context/ModalContext'
import { useCancelToken } from '../../../../api/client'
import { TabsContainer } from '../../../shared/tabs/TabSharedComponents'
import { useForm } from 'react-hook-form'
import { CustomInput, CustomLabel } from '../../../shared/filters/InputComponents'
import { UnsavedChangesPromptModal } from '../../../shared/modal/UnsavedChangesPropmtModal'
import { formatDate } from '../../../../utils/formats'

export const AzureIntegrationDetails = () => {
  const { t } = useTranslation()
  const { createCancelToken } = useCancelToken()
  const { azureTenantId } = useParams<{
    azureTenantId: string
  }>()
  const { setMessage } = useContext(MessageContext)
  const { setModal } = useContext(ModalContext)
  const [selectedIntegration, setSelectedIntegration] = useState<AzureIntegration | null>(null)
  const history = useHistory()
  const handleError = useErrorHandling()

  useEffect(() => {
    getAzureIntegrationDetails(azureTenantId, createCancelToken().token).then(setSelectedIntegration).catch(handleError)
    return () => {
      setSelectedIntegration(null)
      createCancelToken().cancel()
    }
  }, [azureTenantId, createCancelToken, handleError])

  if (!selectedIntegration) return <LoadingIntegrationDetails />

  const openDeleteModal = () => {
    const deleteAction = () => {
      deleteAzureIntegration(selectedIntegration.azureTenantId)
        .then(() => {
          setModal(null)
          setMessage({
            type: MessageType.SUCCESS,
            message: t('admin.integrations.azure.deleteSuccessToast')
          })
          history.push('/admin/integrations/azure')
        })
        .catch(handleError)
    }

    setModal({
      header: t('admin.integrations.confirmRemoveRequest'),
      body: (
        <DetailedIntegrationModal
          description={t('admin.integrations.azure.removeDescription')}
          detailRows={[
            { label: t('admin.integrations.azure.tenantId'), value: selectedIntegration.azureTenantId },
            { label: t('admin.integrations.name'), value: selectedIntegration.name }
          ]}
          action={deleteAction}
          actionLabel={t('admin.integrations.remove')}
        />
      )
    })
  }

  const recoverIntegration = () => {
    recoverAzureIntegration(azureTenantId)
      .then(resp => {
        setSelectedIntegration(resp)
        setModal(null)
        setMessage({
          type: MessageType.SUCCESS,
          message: t('admin.integrations.azure.recoverSuccessToast')
        })
      })
      .catch(handleError)
  }

  const editIntegration = (request: AzureIntegration) => {
    editAzureIntegration(request, createCancelToken().token).then(data => {
      setSelectedIntegration(data)
      setMessage({
        type: MessageType.SUCCESS,
        message: t('admin.integrations.azure.editSuccessToast')
      })
    })
  }

  return (
    <div className={'flex flex-col w-full'}>
      <NestedPageHeader backButtonPath={'/admin/integrations/azure'}>
        <IntegrationDetailsPageHeader
          name={selectedIntegration.name}
          lastIntegrationAt={selectedIntegration.lastIntegrationAt}
          buttonValue={selectedIntegration.deletedAt && t('admin.integrations.recover')}
          buttonAction={!!selectedIntegration?.deletedAt && recoverIntegration}
          deleteAction={!selectedIntegration?.deletedAt && openDeleteModal}
        />
      </NestedPageHeader>
      <AdminContentWrapper className={'max-h-[80vh] xl:max-h-[86vh]'}>
        <IntegrationDetails selectedIntegration={selectedIntegration} submitAction={editIntegration} />
      </AdminContentWrapper>
    </div>
  )
}

interface IntegrationDetailsProps {
  selectedIntegration: AzureIntegration
  submitAction: (request: AzureIntegration) => void
}

const IntegrationDetails = ({ selectedIntegration, submitAction }: IntegrationDetailsProps) => {
  const [selectedTab, setSelectedTab] = useState<IntegrationsTab>(IntegrationsTab.DETAILS)
  return (
    <TabsContainer>
      <IntegrationsTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <IntegrationDetailsWrapper>
        <IntegrationDetailsInfoSection removeDate={selectedIntegration.deletedAt} selectedTab={selectedTab} />
        {selectedTab === IntegrationsTab.DETAILS ? (
          <AzureTenantForm selectedIntegration={selectedIntegration} submitAction={submitAction} />
        ) : (
          <IntegrationTimestamps selectedIntegration={selectedIntegration} />
        )}
      </IntegrationDetailsWrapper>
    </TabsContainer>
  )
}

interface AzureTenantFormProps {
  selectedIntegration: AzureIntegration
  submitAction: (request: AzureIntegration) => void
}

const AzureTenantForm = ({ selectedIntegration, submitAction }: AzureTenantFormProps) => {
  const { t } = useTranslation()
  const [isOnEditMode, setIsOnEditMode] = useState(false)
  const [defaultValues, setDefaultValues] = useState<AzureIntegration>({ ...selectedIntegration })
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting, isDirty }
  } = useForm<AzureIntegration>({ defaultValues: defaultValues })

  const onSubmit = (data: AzureIntegration) => {
    submitAction(data)
    setDefaultValues(data)
    reset(data)
  }

  return (
    <form>
      <UnsavedChangesPromptModal showModal={isDirty} />
      <AdminTabContent>
        <IntegrationDetailsHeader
          header={t('admin.integrations.azure.azureTenant')}
          headerAction={'formActions'}
          isEditable={!selectedIntegration.deletedAt}
          isOnEditMode={isOnEditMode}
          disableSubmit={!isValid || isSubmitting}
          isDirty={isDirty}
          submitAction={event => {
            if (isOnEditMode) {
              setIsOnEditMode(false)
              handleSubmit(onSubmit)()
            } else {
              setIsOnEditMode(true)
            }
            event.preventDefault()
          }}
          resetAction={() => reset(defaultValues)}
        />
        <IntegrationDetailRowsWrapper
          hasActions={false}
          detailRows={
            <>
              <IntegrationDetailRow
                label={t('admin.integrations.azure.tenantId')}
                value={selectedIntegration.azureTenantId}
              />
              {isOnEditMode ? (
                <div className={'p-2 bg-gray-500/20'}>
                  <CustomLabel>{t('admin.integrations.name')} *</CustomLabel>
                  <CustomInput
                    {...register('name', {
                      value: selectedIntegration.name,
                      required: true,
                      minLength: 1
                    })}
                  />
                </div>
              ) : (
                <IntegrationDetailRow label={t('admin.integrations.name')} value={selectedIntegration.name} />
              )}
            </>
          }
        />
      </AdminTabContent>
    </form>
  )
}

interface IntegrationTimestampsProps {
  selectedIntegration: AzureIntegration
}

const IntegrationTimestamps = ({ selectedIntegration }: IntegrationTimestampsProps) => {
  const { t } = useTranslation()
  return (
    <AdminTabContent>
      <IntegrationDetailsHeader header={t('admin.integrations.azure.azureTenant')} headerAction={false} />
      <IntegrationDetailRowsWrapper
        hasActions={false}
        detailRows={
          <>
            <IntegrationDetailRow
              label={t('admin.integrations.organization')}
              value={formatDate(selectedIntegration.lastIntegrations.organization, true, true, true)}
            />
            <IntegrationDetailRow
              label={t('sideNav.costs')}
              value={formatDate(selectedIntegration.lastIntegrations.costs, true, true, true)}
            />
            <IntegrationDetailRow
              label={t('sideNav.optimization')}
              value={formatDate(selectedIntegration.lastIntegrations.optimization, true, true, true)}
            />
            <IntegrationDetailRow
              label={t('sideNav.compliance')}
              value={formatDate(selectedIntegration.lastIntegrations.compliance, true, true, true)}
            />
            <IntegrationDetailRow
              label={t('sideNav.infra')}
              value={formatDate(selectedIntegration.lastIntegrations.infra, true, true, true)}
            />
            <IntegrationDetailRow
              label={t('sideNav.sustainability')}
              value={formatDate(selectedIntegration.lastIntegrations.sustainability, true, true, true)}
            />
          </>
        }
      />
    </AdminTabContent>
  )
}
