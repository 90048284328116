import React, { useContext, useEffect, useState } from 'react'
import { initI18n } from './utils/i18n'
import Routes from './layout/Routes'
import { FullScreenLoading } from './components/shared/Loading'
import { useCancelToken } from './api/client'
import { AuthInfoContext, AuthInfoState } from './state/context/AuthInfoContext'
import { getAuthInfo } from './api/auth'
import { useHistory } from 'react-router-dom'
import { GlobalMessage } from './global-message/GlobalMessage'
import { UserSettingsContext, UserSettingsState } from './state/context/UserSettingsContext'
import { getUserSettings } from './api/user/profile'

initI18n('en')
const App = () => {
  const { createCancelToken } = useCancelToken()
  const { setAuthInfo } = useContext<AuthInfoState>(AuthInfoContext)
  const { setUserSettings } = useContext<UserSettingsState>(UserSettingsContext)
  const history = useHistory()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const cancelToken = createCancelToken()
    setLoading(true)
    Promise.all([
      getAuthInfo(cancelToken.token).then(setAuthInfo),
      getUserSettings(cancelToken.token).then(setUserSettings)
    ])
      .catch(console.error)
      .finally(() => setLoading(false))

    return () => createCancelToken().cancel()
  }, [createCancelToken, history, setAuthInfo, setUserSettings])

  if (loading) return <FullScreenLoading />

  return (
    <>
      <Routes />
      <GlobalMessage />
    </>
  )
}

export default App
