import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { OrganizationUser, PermittedVendorProject } from '../../../../../api/admin/users'
import { Vendor } from '../../../../../utils/vendors'
import { UserRole } from '../../../../../api/auth'
import { CustomIcon, IconType } from '../../../../shared/CustomIcon'
import close from '../../../../../assets/svg/actions/close-heavy.svg'
import { GrayText } from '../../../../shared/TextComponents'
import { SearchInput } from '../../../../shared/filters/SearchInput'
import { CheckboxOption } from '../../../../shared/buttons/CheckboxOption'
import styled from 'styled-components'
import tw from 'twin.macro'
import Select from 'react-select'
import {
  selectControlStyles,
  SelectDropdownIndicator,
  SelectIndicatorSeparator,
  selectMenuListStyles,
  selectNoOptionsStyles,
  selectOptionStyles
} from '../../../../shared/filters/ReactSelectStyles'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'
import { ScrollTable } from '../../../../shared/containers/ScrollTable'
import { filterSelectAllOption } from '../../../../shared/filters/FilterSelect'

interface ProjectAccessProps {
  vendor: Vendor
  editedUser: OrganizationUser
  vendorUserRole: UserRole
  projectOptions: PermittedVendorProject[]
  selectedProjects: PermittedVendorProject[]
  setSelectedProjects: (projects: PermittedVendorProject[]) => void
}

export const ProjectAccess = ({
  vendor,
  editedUser,
  vendorUserRole,
  projectOptions,
  selectedProjects,
  setSelectedProjects
}: ProjectAccessProps) => {
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState<string>('')

  return (
    <>
      {editedUser.roles.includes(vendorUserRole) && (
        <div className={'flex flex-col gap-4 px-6 pb-12'}>
          <div className={'flex gap-4 flex-col lg:flex-row'}>
            <ProjectOptionsMenu
              projectOptions={projectOptions}
              selectedProjects={selectedProjects}
              setSelectedProjects={setSelectedProjects}
            />
            {selectedProjects.length > 0 && (
              <SearchInput
                id={vendor}
                placeholder={`${t('common.search')} ${t(`vendors.${vendor}.projectPhrase_one`)}`}
                size={'default'}
                searchText={searchText}
                setSearchText={setSearchText}
              />
            )}
          </div>
          <SelectedProjects
            vendor={vendor}
            searchText={searchText}
            selectedProjects={selectedProjects}
            setSelectedProjects={setSelectedProjects}
          />
        </div>
      )}
    </>
  )
}

interface ProjectOptionsMenuProps {
  projectOptions: PermittedVendorProject[]
  selectedProjects: PermittedVendorProject[]
  setSelectedProjects: (projects: PermittedVendorProject[]) => void
}

const ProjectOptionsMenu = ({ projectOptions, selectedProjects, setSelectedProjects }: ProjectOptionsMenuProps) => {
  const { t } = useTranslation()
  const [allSelected, setAllSelected] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [filteredOptions, setFilteredOptions] = useState<PermittedVendorProject[]>(projectOptions)
  const selectAllOption = {
    vendorTenantId: 'selectAll',
    ...filterSelectAllOption
  }
  const allOptions = [selectAllOption, ...projectOptions]

  useEffect(() => {
    setAllSelected(
      filteredOptions.every(option =>
        selectedProjects.find(selected => JSON.stringify(option) === JSON.stringify(selected))
      )
    )
  }, [filteredOptions, selectedProjects])

  useEffect(() => {
    if (searchText !== '') {
      setFilteredOptions(
        projectOptions.filter(
          option =>
            option.label.toLowerCase().includes(searchText.toLowerCase()) ||
            option.value.toLowerCase().includes(searchText.toLowerCase())
        )
      )
    } else {
      setFilteredOptions(projectOptions)
    }
  }, [projectOptions, searchText])

  const formatOptionLabel = (option: PermittedVendorProject) => {
    const checked =
      option.vendorTenantId === selectAllOption.vendorTenantId
        ? allSelected
        : selectedProjects.some(selected => option.value === selected.value)
    return (
      <CheckboxOption
        label={option.label}
        type={'filter'}
        clickHandler={() => handleSelect(option)}
        checked={checked}
      />
    )
  }

  const placeholder = () =>
    allSelected
      ? t('filters.allSelected', { count: selectedProjects.length })
      : selectedProjects.length > 0
        ? t('filters.multipleSelected', { count: selectedProjects.length })
        : t('common.select')

  const handleSelect = (selected: PermittedVendorProject) => {
    const project = projectOptions.find(project => JSON.stringify(project) === JSON.stringify(selected))
    const projectIsSelected = selectedProjects.some(
      selectedProject => JSON.stringify(selectedProject) === JSON.stringify(project)
    )
    project &&
      (projectIsSelected
        ? setSelectedProjects(
            selectedProjects.filter(selectedProject => JSON.stringify(selectedProject) !== JSON.stringify(project))
          )
        : setSelectedProjects(selectedProjects.concat(project)))
  }

  const handleSelectAll = () => {
    if (filteredOptions.length === projectOptions.length) {
      allSelected ? setSelectedProjects([]) : setSelectedProjects(filteredOptions)
    } else {
      allSelected
        ? setSelectedProjects(
            selectedProjects.filter(
              project => !filteredOptions.find(option => JSON.stringify(project) === JSON.stringify(option))
            )
          )
        : setSelectedProjects(
            selectedProjects.concat(
              filteredOptions.filter(
                option => !selectedProjects.find(selected => JSON.stringify(selected) === JSON.stringify(option))
              )
            )
          )
    }
  }

  const filterOption = (option: FilterOptionOption<PermittedVendorProject>) => {
    return (
      (option.data.vendorTenantId === selectAllOption.vendorTenantId && filteredOptions.length > 1) ||
      filteredOptions.some(filteredOption => JSON.stringify(filteredOption) === JSON.stringify(option.data))
    )
  }

  return (
    <Select
      unstyled
      controlShouldRenderValue={false}
      closeMenuOnSelect={false}
      filterOption={filterOption}
      placeholder={placeholder()}
      formatOptionLabel={formatOptionLabel}
      inputValue={searchText}
      onInputChange={value => setSearchText(value)}
      classNames={{
        control: () => selectControlStyles + ' w-75',
        menuList: () => selectMenuListStyles + ' min-h-85 max-h-96 min-w-75 w-max max-w-125',
        noOptionsMessage: () => selectNoOptionsStyles,
        placeholder: () => 'text-gray-200',
        option: state => selectOptionStyles + ` max-w-full ${state.isFocused && 'bg-gray-500/30 text-gray-50'}`
      }}
      options={allOptions}
      onChange={selected => {
        selected &&
          (selected.vendorTenantId === selectAllOption.vendorTenantId ? handleSelectAll() : handleSelect(selected))
        setSearchText(searchText)
      }}
      components={{
        DropdownIndicator: SelectDropdownIndicator,
        IndicatorSeparator: SelectIndicatorSeparator
      }}
    />
  )
}

interface SelectedProjectsProps {
  vendor: Vendor
  searchText: string
  selectedProjects: PermittedVendorProject[]
  setSelectedProjects: (projects: PermittedVendorProject[]) => void
}

const SelectedProjects = ({ vendor, searchText, selectedProjects, setSelectedProjects }: SelectedProjectsProps) => {
  const { t } = useTranslation()
  const [filteredProjects, setFilteredProjects] = useState<PermittedVendorProject[]>(selectedProjects)

  useEffect(() => {
    if (searchText !== '') {
      setFilteredProjects(
        selectedProjects.filter(
          project =>
            project.label.toLowerCase().includes(searchText.toLowerCase()) ||
            project.value?.toLowerCase().includes(searchText.toLowerCase())
        )
      )
    } else {
      setFilteredProjects(selectedProjects)
    }
  }, [searchText, selectedProjects])

  if (selectedProjects.length === 0)
    return (
      <GrayText>
        {t('admin.tabs.rolesAndAccessRights.accessToAll', {
          projectPhrase: t(`vendors.${vendor}.projectPhrase_other`)
        })}
      </GrayText>
    )

  return (
    <div className={'w-fit max-w-200'}>
      <ScrollTable
        sortable={false}
        spacing={'small'}
        styles={'h-fit max-h-[36vh]'}
        customColumns={'minmax(17rem, 1fr)'}
        titles={[t(`vendors.${vendor}.projectPhrase_other`)]}
        rows={filteredProjects.map(project => (
          <Wrapper>
            <div className={'text-gray-50'}>
              {project.label}
              {project.label !== project.value && <div className={'text-gray-200'}>{project.value}</div>}
            </div>
            <RemoveOption
              clickHandler={() => setSelectedProjects(selectedProjects.filter(selected => selected !== project))}
            />
          </Wrapper>
        ))}
      />
    </div>
  )
}

const Wrapper = styled.div`
  ${tw`flex w-full gap-6 justify-between items-center`}
`

interface RemoveOptionProps {
  clickHandler: () => void
}

const RemoveOption = ({ clickHandler }: RemoveOptionProps) => {
  return (
    <div className={'min-w-max'}>
      <CustomIcon
        styles={'w-4 h-4 bg-gray-200 hover:bg-gray-50'}
        iconType={IconType.VECTOR}
        path={close}
        onClick={clickHandler}
      />
    </div>
  )
}
