import styled from 'styled-components'
import { ScrollContainer } from '../containers/ScrollContainer'
import tw from 'twin.macro'
import React, { useRef, useState } from 'react'
import { useKeyPress } from '../../../hooks/useKeyPress'
import { CSSTransition } from 'react-transition-group'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'

interface SideDrawerProps {
  id?: string
  drawerOpen: boolean
  setDrawerOpen: (isOpen: boolean) => void
  content: JSX.Element
  transitionStyles: string
  paddingX: number
  zIndex?: number
}

export const SideDrawer = ({
  id,
  drawerOpen,
  setDrawerOpen,
  content,
  transitionStyles,
  paddingX,
  zIndex = 999
}: SideDrawerProps) => {
  const [isTransitioned, setIsTransitioned] = useState(false)
  const drawerNodeRef = useRef(null)
  const transitionNodeRef = useRef(null)

  useKeyPress(() => setDrawerOpen(false), ['Escape'])
  useOnClickOutside(drawerNodeRef, () => setDrawerOpen(false))

  return (
    <CSSTransition
      nodeRef={drawerNodeRef}
      in={drawerOpen}
      timeout={300}
      unmountOnExit
      onEntered={() => setIsTransitioned(true)}
      onExit={() => setIsTransitioned(false)}
    >
      {() => {
        return (
          <DrawerWrapper
            id={id}
            className={transitionStyles}
            paddingX={paddingX}
            ref={drawerNodeRef}
            style={{ zIndex: zIndex }}
          >
            <CSSTransition nodeRef={transitionNodeRef} in={isTransitioned} timeout={100}>
              {() => {
                return <DrawerContentWrapper ref={transitionNodeRef}>{content}</DrawerContentWrapper>
              }}
            </CSSTransition>
          </DrawerWrapper>
        )
      }}
    </CSSTransition>
  )
}

interface DrawerWrapperProps {
  id?: string
  paddingX: number
}

const DrawerWrapper = styled(ScrollContainer)<DrawerWrapperProps>`
  ${tw`bg-opacity-60 p-0 w-0 fixed top-0 h-screen bg-gray-600 border-gray-700 border-r border-l transition-all shadow-lg duration-300 ease-in-out`}
  ${({ id }) => id === 'filters-drawer' && tw`bg-opacity-75`}
  backdrop-filter: blur(4px);

  &.enter {
    ${tw`border-gray-600`}
    ::-webkit-scrollbar-thumb {
      ${tw`bg-transparent`}
    }
  }

  &.enter-active {
    ${tw`w-3/4 lg:w-[40%] border-gray-500 py-6`}
    ${({ id }) => id === 'filters-drawer' && tw`lg:w-[30%]`}
    ${({ id }) => id === 'sidenav-drawer' && tw`sm:w-[30%]`}
    padding-left: ${({ paddingX }) => (paddingX * 4) / 16}rem;
    padding-right: ${({ paddingX }) => (paddingX * 4) / 16}rem;

    ::-webkit-scrollbar-thumb {
      ${tw`bg-transparent`}
    }
  }

  &.enter-done {
    ${tw`w-3/4 lg:w-[40%] lg:max-w-200 border-gray-500 py-6`}
    ${({ id }) => id === 'filters-drawer' && tw`lg:w-[30%]`}
    ${({ id }) => id === 'sidenav-drawer' && tw`min-w-70 sm:w-[30%]`}
    padding-left: ${({ paddingX }) => (paddingX * 4) / 16}rem;
    padding-right: ${({ paddingX }) => (paddingX * 4) / 16}rem;
  }

  &.exit {
    ${tw`w-0 border-gray-700`}
    ::-webkit-scrollbar-thumb {
      ${tw`bg-transparent`}
    }
  }

  &.exit-active {
    ${tw`w-0 bg-transparent border-none`}
    ::-webkit-scrollbar-thumb {
      ${tw`bg-transparent`}
    }
  }

  &.exit-done {
    ${tw`w-0 p-0 border-none`}
  }
`

const DrawerContentWrapper = styled.div`
  ${tw`-mt-8 opacity-0 transition-all ease-in-out duration-300 z-1000`}
  &.enter-done {
    ${tw`opacity-100 mt-0`}
  }

  &.exit-active {
    ${tw`opacity-0 -mt-8`}
  }
`
