import { ChangeType, LimitsByCostAnomalyState } from '../../api/notifications'
import React, { useEffect, useRef, useState } from 'react'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { Button, ButtonSize, ButtonStyle } from './buttons/Button'
import styled from 'styled-components'
import tw from 'twin.macro'

interface NotificationLimitsInputProps {
  limits: LimitsByCostAnomalyState
  setLimits: (limits: LimitsByCostAnomalyState) => void
  min: number
  max: number
  type: ChangeType
  highlight?: boolean
}

export const NotificationLimitsInput = ({
  limits,
  setLimits,
  min,
  max,
  type,
  highlight = false
}: NotificationLimitsInputProps) => {
  const [value, setValue] = useState(type === ChangeType.INCREASING ? limits.increase : limits.decrease)
  const [focused, setFocused] = useState(false)
  const wrapperRef = useRef(null)

  useOnClickOutside(wrapperRef, () => {
    setFocused(false)
  })

  useEffect(() => {
    !focused &&
      (type === ChangeType.INCREASING
        ? setLimits({ ...limits, increase: value > max ? max : value < min ? min : value })
        : setLimits({ ...limits, decrease: value > max ? max : value < min ? min : value }))
  }, [focused])

  return (
    <NumberInputWrapper ref={wrapperRef} focused={focused || highlight}>
      <Button
        value={'-'}
        buttonStyle={ButtonStyle.GHOST}
        size={ButtonSize.XSMALL}
        disabled={value <= min}
        clickHandler={() => {
          setFocused(true)
          setValue(value - 1)
        }}
      />
      <NumberInput
        value={isNaN(value) ? '' : value}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onChange={e =>
          setValue(
            type === ChangeType.DECREASING ? -Math.abs(parseInt(e.target.value)) : Math.abs(parseInt(e.target.value))
          )
        }
        type={'number'}
        min={min}
        max={max}
      />
      <Button
        value={'+'}
        buttonStyle={ButtonStyle.GHOST}
        size={ButtonSize.XSMALL}
        disabled={value >= max}
        clickHandler={() => {
          setFocused(true)
          setValue(value + 1)
        }}
      />
    </NumberInputWrapper>
  )
}

interface NumberInputWrapperProps {
  focused: boolean
}

const NumberInputWrapper = styled.div<NumberInputWrapperProps>`
  ${tw`flex w-fit items-center justify-center gap-2 border border-gray-500/40 rounded p-1`}
  ${({ focused }) => (focused ? tw`border-primary-500` : tw`border-gray-400`)}
`

interface NumberInputProps {
  value: number | string
}

const NumberInput = styled.input<NumberInputProps>`
  ${tw`min-w-14 bg-transparent text-gray-100 shadow-none outline-none rounded m-0 p-0 h-fit text-center focus:font-semibold focus:text-gray-50 focus:shadow-none`}
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    appearance: none;
  }
`
