import { formatNumber } from '../../../utils/formats'
import React, { useState } from 'react'
import { Vendor } from '../../../utils/vendors'
import styled from 'styled-components'
import tw from 'twin.macro'
import { MissingDataNotification } from '../MissingDataNotification'
import { Loading } from '../Loading'
import { BarChartsDisplay, ScoreBarChart } from '../charts/ScoreBarChart'
import { InfoTooltip } from '../tooltips/InfoTooltip'
import { CostChangeIndicator } from '../indicators/CostChangeIndicator'
import { useMousePosition } from '../../../hooks/useMousePosition'
import { useTranslation } from 'react-i18next'

export interface DataBlockData {
  vendor: Vendor
  label: string
  value: number
  unit?: string
  change?: number
}

interface DataBlockProps {
  id: string
  headerIcon: React.ReactElement
  heading: string
  subHeading?: string
  data: DataBlockData[]
  scoreBarChart?: boolean
  forecastIndicator?: boolean
  changeIndicator?: boolean
  changeIndicatorTooltip?: string
  loading: boolean
}

export const DataBlock = ({
  id,
  headerIcon,
  heading,
  subHeading,
  data,
  scoreBarChart,
  forecastIndicator,
  changeIndicator,
  changeIndicatorTooltip,
  loading
}: DataBlockProps) => {
  const { t } = useTranslation()
  const [showTooltip, setShowTooltip] = useState(false)
  const [tooltipVendor, setTooltipVendor] = useState<Vendor>()
  const mousePosition = useMousePosition()

  if (loading || !data.length) {
    return (
      <Block>
        <IconHeadingWrapper>
          {headerIcon}
          <div>
            <Heading>{heading}</Heading>
            <SubHeading>{subHeading}</SubHeading>
          </div>
        </IconHeadingWrapper>
        <div className={'flex h-full p-5 justify-center items-center'}>
          {loading ? <Loading height={60} paddingY={'4.3rem'} /> : <MissingDataNotification justify={'center'} />}
        </div>
      </Block>
    )
  }

  return (
    <>
      <Block>
        <IconHeadingWrapper>
          {headerIcon}
          <div>
            <Heading>{heading}</Heading>
            <SubHeading>{subHeading}</SubHeading>
          </div>
        </IconHeadingWrapper>
        <RowContainer
          onMouseOut={() => {
            setShowTooltip(false)
          }}
        >
          {data.map((item, index) => (
            <Row
              key={index}
              onMouseMove={() => {
                setTooltipVendor(item.vendor)
              }}
            >
              <span
                className={'cursor-default'}
                onMouseOver={() => {
                  setShowTooltip(true)
                }}
              >
                {item.label}
              </span>
              <ValueToVendorIndicator useScoreBarChart={scoreBarChart}>
                <span id={`${id}-${index}`}>
                  {formatNumber(item.value)} {item.unit}
                </span>
                {forecastIndicator
                  ? (item.change || item.change === 0) && (
                      <CostChangeIndicator
                        change={item.change}
                        tooltip={`${
                          item.change === 0
                            ? t('costs.noForecastChange')
                            : item.change && item.change > 0
                              ? t('costs.forecastIncrease')
                              : t('costs.forecastDecrease')
                        } ${t('costs.fromLastMonth')}`}
                      />
                    )
                  : (item.change || item.change === 0) &&
                    changeIndicator && <CostChangeIndicator change={item.change} tooltip={changeIndicatorTooltip} />}
                {scoreBarChart && <ScoreBarChart displayStyle={BarChartsDisplay.progress} percent={item.value} />}
                <VendorIndicator
                  vendor={item.vendor}
                  onMouseOver={() => {
                    setShowTooltip(true)
                  }}
                />
              </ValueToVendorIndicator>
            </Row>
          ))}
        </RowContainer>
      </Block>
      {showTooltip && (
        <InfoTooltip vendor={tooltipVendor} x={mousePosition.x} y={mousePosition.y}>
          {tooltipVendor}
        </InfoTooltip>
      )}
    </>
  )
}

const Block = styled.div`
  ${tw`flex flex-col px-5 flex-1 min-w-80 max-w-190 rounded-md border border-gray-500 divide-y divide-gray-500 text-gray-50 shadow-xs xl:min-w-100`}
`

const IconHeadingWrapper = styled.div`
  ${tw`flex py-4 items-center gap-3`}
`

const Heading = styled.div`
  ${tw`font-bold first-letter:capitalize`}
`

const SubHeading = styled.div`
  ${tw`text-gray-200 first-letter:capitalize`}
`

const RowContainer = styled.div`
  ${tw`flex flex-col gap-5 py-5`}
`

const Row = styled.div`
  ${tw`flex justify-between gap-8 w-full`}
`

interface ValueToVendorIndicatorProps {
  useScoreBarChart?: boolean
}

const ValueToVendorIndicator = styled.div<ValueToVendorIndicatorProps>`
  ${tw`flex items-center justify-end gap-3 text-right`}
  span {
    ${props => (props.useScoreBarChart ? tw`w-full min-w-[45px]` : tw`w-max`)}
  }

  div:first-of-type {
    ${props => props.useScoreBarChart && tw`w-full`}
  }
`

interface VendorIndicatorProps {
  vendor: Vendor
}

const VendorIndicator = styled.div<VendorIndicatorProps>`
  ${tw`min-w-3 h-3 rounded-full`}
  ${({ vendor }) =>
    vendor === Vendor.AWS ? tw`bg-brand-aws` : vendor === Vendor.AZURE ? tw`bg-brand-azure` : tw`bg-brand-gcp`}
`
