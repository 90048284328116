import { Vendor } from '../../../utils/vendors'
import { useTranslation } from 'react-i18next'
import React, { useContext, useEffect, useState } from 'react'
import { LightGrayText, WhiteText } from '../../shared/TextComponents'
import { SearchInput } from '../../shared/filters/SearchInput'
import {
  DisabledCostNotificationData,
  getCostAnomalyNotifications,
  LimitsByCostAnomalyState
} from '../../../api/notifications'
import { CustomIcon, IconType } from '../../shared/CustomIcon'
import revert from '../../../assets/svg/actions/return.svg'
import { Loading } from '../../shared/Loading'
import { MeatballsMenu } from '../../shared/MeatballsMenu'
import { MessageContext, MessageType } from '../../../state/context/MessageContext'
import { NotificationsContext } from '../../../state/context/NotificationsContext'
import { useCancelToken } from '../../../api/client'
import { useErrorHandling } from '../../../hooks/handleError'
import { CostAnomalyLimitsTab } from './UserNotificationSettings'
import { getCurrency } from '../../../utils/Currency'
import { UserSettingsContext } from '../../../state/context/UserSettingsContext'
import {
  changeCostAnomalyProjectLimits,
  changeCostAnomalyServiceLimits,
  CostAnomalyProjectLimits,
  CostAnomalyServiceLimits,
  disableCostNotifications,
  enableCostNotifications,
  getCostAnomalyProjectLimits,
  getCostAnomalyServiceLimits,
  restoreAllDefaultCostAnomalyProjectLimits,
  restoreAllDefaultCostAnomalyServiceLimits,
  restoreDefaultCostAnomalyProjectLimits,
  restoreDefaultCostAnomalyServiceLimits,
  subscribeProjectEmailNotifications,
  subscribeServiceEmailNotifications,
  unsubscribeProjectEmailNotifications,
  unsubscribeServiceEmailNotifications
} from '../../../api/user/notification-settings'
import { DimmedContainer, DimmedListRow, DimmedRow } from '../../master-admin/Shared'
import { ScrollContainer } from '../../shared/containers/ScrollContainer'
import styled from 'styled-components'
import tw from 'twin.macro'
import { SortingMenu } from '../../shared/containers/ScrollTable'
import { MissingDataNotification } from '../../shared/MissingDataNotification'
import { SwitchButton } from '../../shared/buttons/SwitchButton'
import {
  getNotificationProject,
  getNotificationService,
  removeNotificationProject,
  removeNotificationService
} from '../../../state/storage'
import { ModalContext } from '../../../state/context/ModalContext'
import { ModalActions } from '../../shared/modal/Modal'
import { Button, ButtonType } from '../../shared/buttons/Button'
import { CostAnomalySettingsInputs } from './CostAnomalySettingsInputs'
import { CostAnomalyMultiEditModal } from './CostAnomalyMultiEditModal'

interface CostAnomalySettingsProps {
  selectedTab: CostAnomalyLimitsTab
  selectedVendor: Vendor
  projectSearchText: string
  setProjectSearchText: (text: string) => void
  serviceSearchText: string
  setServiceSearchText: (text: string) => void
}

export const CostAnomalySettings = ({
  selectedTab,
  selectedVendor,
  projectSearchText,
  setProjectSearchText,
  serviceSearchText,
  setServiceSearchText
}: CostAnomalySettingsProps) => {
  const { t } = useTranslation()
  const { setMessage } = useContext(MessageContext)
  const { setNotifications } = useContext(NotificationsContext)
  const { modal, setModal } = useContext(ModalContext)
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const project = getNotificationProject()
  const service = getNotificationService()
  const [loading, setLoading] = useState(false)
  const [projects, setProjects] = useState<CostAnomalyProjectLimits[]>([])
  const [services, setServices] = useState<CostAnomalyServiceLimits[]>([])
  const [newEntries, setNewEntries] = useState<CostAnomalyProjectLimits[] | CostAnomalyServiceLimits[]>([])
  const [showModified, setShowModified] = useState(false)
  const [showUnmodified, setShowUnmodified] = useState(false)
  const [showEnabled, setShowEnabled] = useState(false)
  const [showDisabled, setShowDisabled] = useState(false)
  const [showSubscribed, setShowSubscribed] = useState(false)
  const [showUnsubscribed, setShowUnsubscribed] = useState(false)
  const title =
    selectedTab === CostAnomalyLimitsTab.PROJECTS
      ? t(`vendors.${selectedVendor}.projectPhrase_one`)
      : t('common.service_one')
  const [entries, setEntries] = useState<CostAnomalyProjectLimits[] | CostAnomalyServiceLimits[]>([])
  const [sortedEntries, setSortedEntries] = useState<CostAnomalyProjectLimits[] | CostAnomalyServiceLimits[]>(projects)
  const [sortingTitle, setSortingTitle] = useState(title)
  const [sortAsc, setSortAsc] = useState(true)

  useEffect(() => {
    const cancelToken = createCancelToken()
    setLoading(true)
    Promise.all([
      getCostAnomalyProjectLimits(selectedVendor, cancelToken.token).then(setProjects),
      getCostAnomalyServiceLimits(selectedVendor, cancelToken.token).then(setServices)
    ])
      .catch(handleError)
      .finally(() => setLoading(false))

    if (project) {
      setProjectSearchText(project)
      removeNotificationProject()
    }
    if (service) {
      setServiceSearchText(service)
      removeNotificationService()
    }

    return () => cancelToken.cancel()
  }, [selectedVendor, createCancelToken, handleError, project, service])

  useEffect(() => {
    selectedTab === CostAnomalyLimitsTab.PROJECTS
      ? setEntries(
          projects
            .filter(project =>
              showEnabled
                ? !project.costNotificationsDisabledAt
                : showDisabled
                  ? !!project.costNotificationsDisabledAt
                  : true
            )
            .filter(project => (showModified ? !project.isDefault : showUnmodified ? project.isDefault : true))
            .filter(project =>
              showSubscribed ? project.isSubscribedToEmail : showUnsubscribed ? !project.isSubscribedToEmail : true
            )
            .filter(
              project =>
                project.project.name.toLowerCase().includes(projectSearchText.toLowerCase()) ||
                project.project.id.toLowerCase().includes(projectSearchText.toLowerCase())
            )
        )
      : setEntries(
          services
            .filter(service => (showModified ? !service.isDefault : showUnmodified ? service.isDefault : true))
            .filter(service =>
              showSubscribed ? service.isSubscribedToEmail : showUnsubscribed ? !service.isSubscribedToEmail : true
            )
            .filter(service => service.service.toLowerCase().includes(serviceSearchText.toLowerCase()))
        )

    if (!entries.length) {
      setShowEnabled(false)
      setShowDisabled(false)
      setShowModified(false)
      setShowUnmodified(false)
      setShowSubscribed(false)
      setShowUnsubscribed(false)
    }
  }, [
    showEnabled,
    showDisabled,
    showModified,
    showUnmodified,
    showSubscribed,
    showUnsubscribed,
    selectedTab,
    projectSearchText,
    serviceSearchText,
    projects,
    services,
    entries.length
  ])

  useEffect(() => {
    if (entries.length > 0) {
      if (sortingTitle === t('userSettings.notifications.changePercentIncrease')) {
        setSortedEntries(
          (entries as CostAnomalyProjectLimits[]).sort((a, b) => {
            const sorting = a.dailyChangePercent.increase > b.dailyChangePercent.increase ? 1 : -1
            return sortAsc ? sorting : -sorting
          })
        )
      } else if (sortingTitle === t('userSettings.notifications.changePercentDecrease')) {
        setSortedEntries(
          (entries as CostAnomalyProjectLimits[]).sort((a, b) => {
            const sorting = a.dailyChangePercent.decrease > b.dailyChangePercent.decrease ? 1 : -1
            return sortAsc ? sorting : -sorting
          })
        )
      } else if (
        sortingTitle ===
        t('userSettings.notifications.monthlyImpactIncrease', { currency: getCurrency(entries[0].currency).symbol })
      ) {
        setSortedEntries(
          (entries as CostAnomalyProjectLimits[]).sort((a, b) => {
            const sorting = a.estMonthlyImpact.increase > b.estMonthlyImpact.increase ? 1 : -1
            return sortAsc ? sorting : -sorting
          })
        )
      } else if (
        sortingTitle ===
        t('userSettings.notifications.monthlyImpactDecrease', { currency: getCurrency(entries[0].currency).symbol })
      ) {
        setSortedEntries(
          (entries as CostAnomalyProjectLimits[]).sort((a, b) => {
            const sorting = a.estMonthlyImpact.decrease > b.estMonthlyImpact.decrease ? 1 : -1
            return sortAsc ? sorting : -sorting
          })
        )
      } else {
        setSortingTitle(title)
        setSortedEntries(
          entries.sort((a, b) => {
            const sorting =
              'project' in a && 'project' in b
                ? (a as CostAnomalyProjectLimits).project?.name?.toLowerCase() >
                  (b as CostAnomalyProjectLimits).project?.name?.toLowerCase()
                  ? 1
                  : -1
                : (a as CostAnomalyServiceLimits).service?.toLowerCase() >
                    (b as CostAnomalyServiceLimits).service?.toLowerCase()
                  ? 1
                  : -1
            return sortAsc ? sorting : -sorting
          })
        )
      }
    }
  }, [sortingTitle, sortAsc, title, selectedTab, entries, projectSearchText, serviceSearchText])

  const changeMultipleSettings = () => {
    if (newEntries.length) {
      const cancelToken = createCancelToken()
      setLoading(true)
      selectedTab === CostAnomalyLimitsTab.PROJECTS
        ? changeCostAnomalyProjectLimits(selectedVendor, newEntries as CostAnomalyProjectLimits[], cancelToken.token)
            .then(resp => {
              setProjects(resp)
              setNewEntries([])
              getCostAnomalyNotifications(cancelToken.token).then(setNotifications).catch(handleError)
              setMessage({
                type: MessageType.SUCCESS,
                message: t('notifications.costAnomalies.toastText.limitChangeSuccess')
              })
            })
            .catch(handleError)
        : changeCostAnomalyServiceLimits(selectedVendor, newEntries as CostAnomalyServiceLimits[], cancelToken.token)
            .then(resp => {
              setServices(resp)
              setNewEntries([])
              getCostAnomalyNotifications(cancelToken.token).then(setNotifications).catch(handleError)
              setMessage({
                type: MessageType.SUCCESS,
                message: t('notifications.costAnomalies.toastText.limitChangeSuccess')
              })
            })
            .catch(handleError)
      setLoading(false)
      setModal(null)
    }
  }

  useEffect(() => {
    modal && entries.length && openMultiEditModal()
  }, [newEntries])

  const openMultiEditModal = () => {
    setModal({
      header: t('userSettings.notifications.editMultiple'),
      body: (
        <>
          <CostAnomalyMultiEditModal
            options={(selectedTab === CostAnomalyLimitsTab.PROJECTS ? projects : services).map(row =>
              'project' in row
                ? {
                    label: row.project.name,
                    value: row.project.id
                  }
                : { label: row.service, value: row.service }
            )}
            entries={entries}
            setNewEntries={setNewEntries}
            selectedVendor={selectedVendor}
          />
          <ModalActions>
            <Button
              value={t('common.saveChanges')}
              clickHandler={() => changeMultipleSettings()}
              type={ButtonType.MODAL}
              disabled={loading || !newEntries?.length}
            />
          </ModalActions>
        </>
      )
    })
  }

  const restoreAllToDefaults = () => {
    const cancelToken = createCancelToken()
    setLoading(true)
    selectedTab === CostAnomalyLimitsTab.PROJECTS
      ? restoreAllDefaultCostAnomalyProjectLimits(selectedVendor, cancelToken.token)
          .then(resp => {
            setProjects(resp)
            getCostAnomalyNotifications(cancelToken.token).then(setNotifications).catch(handleError)
            setMessage({
              type: MessageType.SUCCESS,
              message: t('notifications.costAnomalies.toastText.limitsRestored')
            })
          })
          .catch(handleError)
      : restoreAllDefaultCostAnomalyServiceLimits(selectedVendor, cancelToken.token)
          .then(resp => {
            setServices(resp)
            getCostAnomalyNotifications(cancelToken.token).then(setNotifications).catch(handleError)
            setMessage({
              type: MessageType.SUCCESS,
              message: t('notifications.costAnomalies.toastText.limitsRestored')
            })
          })
          .catch(handleError)
    setLoading(false)
  }

  if (
    loading ||
    (!showModified &&
      !showUnmodified &&
      !showSubscribed &&
      !showUnsubscribed &&
      !entries.length &&
      projectSearchText === '' &&
      serviceSearchText === '')
  )
    return (
      <DimmedContainer className={'max-w-180 w-full'}>
        {loading ? <Loading height={64} paddingY={'3rem'} /> : <MissingDataNotification paddingY={16} />}
      </DimmedContainer>
    )

  return (
    <div className={'flex w-full justify-between items-start gap-3 flex-col-reverse xl:flex-row'}>
      <div className={'flex flex-col p-2 w-full xl:max-w-180'}>
        <SettingsListHeader
          selectedVendor={selectedVendor}
          entries={selectedTab === CostAnomalyLimitsTab.PROJECTS ? projects : services}
          searchText={selectedTab === CostAnomalyLimitsTab.PROJECTS ? projectSearchText : serviceSearchText}
          setSearchText={selectedTab === CostAnomalyLimitsTab.PROJECTS ? setProjectSearchText : setServiceSearchText}
          selectedTab={selectedTab}
          sortingTitle={sortingTitle}
          setSortingTitle={setSortingTitle}
          sortAsc={sortAsc}
          setSortAsc={setSortAsc}
          showModified={showModified}
          setShowModified={setShowModified}
          showUnmodified={showUnmodified}
          setShowUnmodified={setShowUnmodified}
          showSubscribed={showSubscribed}
          setShowSubscribed={setShowSubscribed}
          showUnsubscribed={showUnsubscribed}
          setShowUnsubscribed={setShowUnsubscribed}
          showEnabled={showEnabled}
          setShowEnabled={setShowEnabled}
          showDisabled={showDisabled}
          setShowDisabled={setShowDisabled}
          restoreAllToDefaults={restoreAllToDefaults}
          openMultiEditModal={openMultiEditModal}
        />
        <ScrollContainer className={'max-h-[70vh]'}>
          {!sortedEntries.length ? (
            <MissingDataNotification
              paddingY={16}
              icon={true}
              displayText={t('userSettings.notifications.noMatchingEntries', { title })}
            />
          ) : (
            sortedEntries.map(entry => (
              <DimmedListRow
                key={'project' in entry ? entry.project.id : entry.service}
                className={'flex-col py-5 px-6 last:border-b last:border-gray-800'}
              >
                <InputsContainer
                  key={JSON.stringify(entry)}
                  entry={entry}
                  setProjects={setProjects}
                  setServices={setServices}
                  selectedVendor={selectedVendor}
                />
              </DimmedListRow>
            ))
          )}
        </ScrollContainer>
      </div>
    </div>
  )
}

interface SettingsListHeaderProps {
  selectedTab: CostAnomalyLimitsTab
  selectedVendor: Vendor
  entries: CostAnomalyProjectLimits[] | CostAnomalyServiceLimits[]
  searchText: string
  setSearchText: (text: string) => void
  sortingTitle: string
  setSortingTitle: (title: string) => void
  sortAsc: boolean
  setSortAsc: (sortAsc: boolean) => void
  showModified: boolean
  setShowModified: (showModified: boolean) => void
  showUnmodified: boolean
  setShowUnmodified: (showUnmodified: boolean) => void
  showSubscribed: boolean
  setShowSubscribed: (showSubscribed: boolean) => void
  showUnsubscribed: boolean
  setShowUnsubscribed: (showUnsubscribed: boolean) => void
  showEnabled: boolean
  setShowEnabled: (showEnabled: boolean) => void
  showDisabled: boolean
  setShowDisabled: (showDisabled: boolean) => void
  restoreAllToDefaults: () => void
  openMultiEditModal: () => void
}

const SettingsListHeader = ({
  selectedTab,
  selectedVendor,
  entries,
  searchText,
  setSearchText,
  sortingTitle,
  setSortingTitle,
  sortAsc,
  setSortAsc,
  showModified,
  setShowModified,
  showUnmodified,
  setShowUnmodified,
  showSubscribed,
  setShowSubscribed,
  showUnsubscribed,
  setShowUnsubscribed,
  showEnabled,
  setShowEnabled,
  showDisabled,
  setShowDisabled,
  restoreAllToDefaults,
  openMultiEditModal
}: SettingsListHeaderProps) => {
  const { t } = useTranslation()
  const { userSettings } = useContext(UserSettingsContext)
  const title =
    selectedTab === CostAnomalyLimitsTab.PROJECTS
      ? t(`vendors.${selectedVendor}.projectPhrase_one`)
      : t('common.service_one')

  return (
    <DimmedRow className={'sticky top-0 gap-12 z-[1] backdrop-blur border-b border-gray-800'}>
      <div className={'flex flex-col items-center gap-2 w-full xl:flex-row xl:gap-6'}>
        <div className={'w-full'}>
          <SearchInput size={'small'} searchText={searchText} setSearchText={setSearchText} />
        </div>
        <SortingMenu
          options={[
            { label: title, value: title },
            {
              label: t('userSettings.notifications.changePercentDecrease'),
              value: t('userSettings.notifications.changePercentDecrease')
            },
            {
              label: t('userSettings.notifications.changePercentIncrease'),
              value: t('userSettings.notifications.changePercentIncrease')
            },
            {
              label: t('userSettings.notifications.monthlyImpactDecrease', {
                currency: getCurrency(userSettings.currency).symbol
              }),
              value: t('userSettings.notifications.monthlyImpactDecrease', {
                currency: getCurrency(userSettings.currency).symbol
              })
            },
            {
              label: t('userSettings.notifications.monthlyImpactIncrease', {
                currency: getCurrency(userSettings.currency).symbol
              }),
              value: t('userSettings.notifications.monthlyImpactIncrease', {
                currency: getCurrency(userSettings.currency).symbol
              })
            }
          ]}
          sortingTitle={sortingTitle}
          setSortingTitle={setSortingTitle}
          sortAsc={sortAsc}
          setSortAsc={setSortAsc}
        />
      </div>
      <ListActions
        entries={entries}
        showModified={showModified}
        setShowModified={setShowModified}
        showUnmodified={showUnmodified}
        setShowUnmodified={setShowUnmodified}
        showSubscribed={showSubscribed}
        setShowSubscribed={setShowSubscribed}
        showUnsubscribed={showUnsubscribed}
        setShowUnsubscribed={setShowUnsubscribed}
        showEnabled={showEnabled}
        setShowEnabled={setShowEnabled}
        showDisabled={showDisabled}
        setShowDisabled={setShowDisabled}
        restoreDefaults={restoreAllToDefaults}
        openMultiEditModal={openMultiEditModal}
      />
    </DimmedRow>
  )
}

interface ListActionsProps {
  entries: CostAnomalyProjectLimits[] | CostAnomalyServiceLimits[]
  showModified: boolean
  setShowModified: (showModified: boolean) => void
  showUnmodified: boolean
  setShowUnmodified: (showUnmodified: boolean) => void
  showSubscribed: boolean
  setShowSubscribed: (showSubscribed: boolean) => void
  showUnsubscribed: boolean
  setShowUnsubscribed: (showUnsubscribed: boolean) => void
  showEnabled: boolean
  setShowEnabled: (showEnabled: boolean) => void
  showDisabled: boolean
  setShowDisabled: (showDisabled: boolean) => void
  restoreDefaults: () => void
  openMultiEditModal: () => void
}

const ListActions = ({
  entries,
  showModified,
  setShowModified,
  showUnmodified,
  setShowUnmodified,
  showSubscribed,
  setShowSubscribed,
  showUnsubscribed,
  setShowUnsubscribed,
  showEnabled,
  setShowEnabled,
  showDisabled,
  setShowDisabled,
  restoreDefaults,
  openMultiEditModal
}: ListActionsProps) => {
  const { t } = useTranslation()
  const options = [
    {
      label: t('userSettings.notifications.editMultiple'),
      clickHandler: () => openMultiEditModal()
    },
    {
      label: t('userSettings.notifications.filterModified'),
      clickHandler: () => {
        showUnmodified && setShowUnmodified(false)
        setShowModified(!showModified)
      },
      radioGroup: 'modified',
      checked: showModified,
      disabled: entries.every(entry => entry.isDefault)
    },
    {
      label: t('userSettings.notifications.filterUnmodified'),
      clickHandler: () => {
        showModified && setShowModified(false)
        setShowUnmodified(!showUnmodified)
      },
      radioGroup: 'modified',
      checked: showUnmodified,
      disabled: entries.every(entry => !entry.isDefault)
    }
  ]

  entries.length > 0 &&
    'costNotificationsDisabledAt' in entries[0] &&
    options.push(
      {
        label: t('userSettings.notifications.filterEnabled'),
        clickHandler: () => {
          showDisabled && setShowDisabled(false)
          setShowEnabled(!showEnabled)
        },
        radioGroup: 'notifications',
        checked: showEnabled,
        disabled: entries.every(entry => 'costNotificationsDisabledAt' in entry && !!entry.costNotificationsDisabledAt)
      },
      {
        label: t('userSettings.notifications.filterDisabled'),
        clickHandler: () => {
          showEnabled && setShowEnabled(false)
          setShowDisabled(!showDisabled)
        },
        radioGroup: 'notifications',
        checked: showDisabled,
        disabled: entries.every(entry => 'costNotificationsDisabledAt' in entry && !entry.costNotificationsDisabledAt)
      }
    )

  options.push(
    {
      label: t('userSettings.notifications.filterSubscribed'),
      clickHandler: () => {
        showUnsubscribed && setShowUnsubscribed(false)
        setShowSubscribed(!showSubscribed)
      },
      radioGroup: 'emails',
      checked: showSubscribed,
      disabled: entries.every(entry => !entry.isSubscribedToEmail)
    },
    {
      label: t('userSettings.notifications.filterUnsubscribed'),
      clickHandler: () => {
        showSubscribed && setShowSubscribed(false)
        setShowUnsubscribed(!showUnsubscribed)
      },
      radioGroup: 'emails',
      checked: showUnsubscribed,
      disabled: entries.every(entry => entry.isSubscribedToEmail)
    }
  )

  return (
    <MeatballsMenu
      options={[
        ...options,
        {
          label: t('userSettings.notifications.restoreDefaults_other'),
          disabled: entries.every(entry => entry.isDefault),
          clickHandler: () => {
            restoreDefaults()
            setShowModified(false)
          }
        }
      ]}
      size={'small'}
    />
  )
}

interface InputContainerProps {
  selectedVendor: Vendor
  entry: CostAnomalyProjectLimits | CostAnomalyServiceLimits
  setProjects: (entries: CostAnomalyProjectLimits[]) => void
  setServices: (entries: CostAnomalyServiceLimits[]) => void
}

const InputsContainer = ({ selectedVendor, entry, setProjects, setServices }: InputContainerProps) => {
  const { t } = useTranslation()
  const { setMessage } = useContext(MessageContext)
  const { setNotifications } = useContext(NotificationsContext)
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const [percent, setPercent] = useState<LimitsByCostAnomalyState>(entry.dailyChangePercent)
  const [impact, setImpact] = useState<LimitsByCostAnomalyState>(entry.estMonthlyImpact)
  const [notificationsEnabled, setNotificationsEnabled] = useState(
    'costNotificationsDisabledAt' in entry && !entry.costNotificationsDisabledAt
  )
  const [emailsSubscribed, setEmailsSubscribed] = useState(entry.isSubscribedToEmail)

  const changeLimits = (newEntry: CostAnomalyProjectLimits | CostAnomalyServiceLimits) => {
    const cancelToken = createCancelToken()
    if ('project' in newEntry) {
      changeCostAnomalyProjectLimits(selectedVendor, [newEntry as CostAnomalyProjectLimits], cancelToken.token)
        .then(resp => {
          setProjects(resp)
          getCostAnomalyNotifications(cancelToken.token).then(setNotifications).catch(handleError)
          setMessage({
            type: MessageType.SUCCESS,
            message: t('notifications.costAnomalies.toastText.limitChangeSuccess')
          })
        })
        .catch(handleError)
    } else {
      changeCostAnomalyServiceLimits(selectedVendor, [newEntry as CostAnomalyServiceLimits], cancelToken.token)
        .then(resp => {
          setServices(resp)
          getCostAnomalyNotifications(cancelToken.token).then(setNotifications).catch(handleError)
          setMessage({
            type: MessageType.SUCCESS,
            message: t('notifications.costAnomalies.toastText.limitChangeSuccess')
          })
        })
        .catch(handleError)
    }
  }

  const restoreDefaults = (entry: CostAnomalyProjectLimits | CostAnomalyServiceLimits) => {
    const cancelToken = createCancelToken()
    if ('project' in entry) {
      restoreDefaultCostAnomalyProjectLimits(selectedVendor, entry.project.id, cancelToken.token)
        .then(resp => {
          setProjects(resp)
          getCostAnomalyNotifications(cancelToken.token).then(setNotifications).catch(handleError)
          setMessage({
            type: MessageType.SUCCESS,
            message: t('notifications.costAnomalies.toastText.limitsRestored')
          })
        })
        .catch(handleError)
    } else {
      restoreDefaultCostAnomalyServiceLimits(selectedVendor, entry.service, cancelToken.token)
        .then(resp => {
          setServices(resp)
          getCostAnomalyNotifications(cancelToken.token).then(setNotifications).catch(handleError)
          setMessage({
            type: MessageType.SUCCESS,
            message: t('notifications.costAnomalies.toastText.limitsRestored')
          })
        })
        .catch(handleError)
    }
  }

  const subscribeToEmail = (entry: CostAnomalyProjectLimits | CostAnomalyServiceLimits) => {
    const cancelToken = createCancelToken()
    if ('project' in entry) {
      subscribeProjectEmailNotifications(selectedVendor, entry.project.id, cancelToken.token)
        .then(resp => {
          setProjects(resp)
          getCostAnomalyNotifications(cancelToken.token).then(setNotifications).catch(handleError)
          setMessage({
            type: MessageType.SUCCESS,
            message: t('notifications.costAnomalies.toastText.emailsSubscribed')
          })
        })
        .catch(handleError)
    } else {
      subscribeServiceEmailNotifications(selectedVendor, entry.service, cancelToken.token)
        .then(resp => {
          setServices(resp)
          getCostAnomalyNotifications(cancelToken.token).then(setNotifications).catch(handleError)
          setMessage({
            type: MessageType.SUCCESS,
            message: t('notifications.costAnomalies.toastText.emailsSubscribed')
          })
        })
        .catch(handleError)
    }
  }

  const unsubscribeFromEmail = (entry: CostAnomalyProjectLimits | CostAnomalyServiceLimits) => {
    const cancelToken = createCancelToken()
    if ('project' in entry) {
      unsubscribeProjectEmailNotifications(selectedVendor, entry.project.id, cancelToken.token)
        .then(resp => {
          setProjects(resp)
          getCostAnomalyNotifications(cancelToken.token).then(setNotifications).catch(handleError)
          setMessage({
            type: MessageType.SUCCESS,
            message: t('notifications.costAnomalies.toastText.emailsUnsubscribed')
          })
        })
        .catch(handleError)
    } else {
      unsubscribeServiceEmailNotifications(selectedVendor, entry.service, cancelToken.token)
        .then(resp => {
          setServices(resp)
          getCostAnomalyNotifications(cancelToken.token).then(setNotifications).catch(handleError)
          setMessage({
            type: MessageType.SUCCESS,
            message: t('notifications.costAnomalies.toastText.emailsUnsubscribed')
          })
        })
        .catch(handleError)
    }
  }

  const enableNotifications = (entry: CostAnomalyProjectLimits) => {
    const cancelToken = createCancelToken()
    const data = [
      {
        vendor: selectedVendor,
        project: { ...entry.project }
      } as DisabledCostNotificationData
    ]
    enableCostNotifications(data, cancelToken.token)
      .then(() => {
        getCostAnomalyProjectLimits(selectedVendor, cancelToken.token)
          .then(resp => {
            setProjects(resp)
            setMessage({
              type: MessageType.SUCCESS,
              message: t('notifications.costAnomalies.toastText.notificationsEnableSuccess')
            })
          })
          .catch(handleError)
        getCostAnomalyNotifications(cancelToken.token).then(setNotifications).catch(handleError)
      })
      .catch(handleError)
  }

  const disableNotifications = (entry: CostAnomalyProjectLimits) => {
    const cancelToken = createCancelToken()
    const data = {
      vendor: selectedVendor,
      project: { ...entry.project }
    } as DisabledCostNotificationData
    disableCostNotifications(data, cancelToken.token)
      .then(() => {
        getCostAnomalyProjectLimits(selectedVendor, cancelToken.token)
          .then(resp => {
            setProjects(resp)
            setMessage({
              type: MessageType.SUCCESS,
              message: t('notifications.costAnomalies.toastText.notificationsDisableSuccess')
            })
          })
          .catch(handleError)
        getCostAnomalyNotifications(cancelToken.token).then(setNotifications).catch(handleError)
      })
      .catch(handleError)
  }

  useEffect(() => {
    ;(JSON.stringify(percent) !== JSON.stringify(entry.dailyChangePercent) ||
      JSON.stringify(impact) !== JSON.stringify(entry.estMonthlyImpact)) &&
      changeLimits({
        ...entry,
        dailyChangePercent: percent,
        estMonthlyImpact: impact
      })
  }, [percent, impact])

  return (
    <div className={'flex w-full'}>
      <InputRowsWrapper className={'pr-6'}>
        <Row>
          <div className={'flex gap-2 items-center'}>
            <WhiteText>
              {'project' in entry ? (
                <>
                  <div className={'text-100'}>{entry.project.name}</div>
                  <LightGrayText lowercase={true}>{entry.project.id}</LightGrayText>
                </>
              ) : (
                entry.service
              )}
            </WhiteText>
          </div>
          {!entry.isDefault && (
            <div className={'flex w-max gap-3'}>
              <CustomIcon
                iconType={IconType.VECTOR}
                styles={'w-5 h-5 bg-gray-400 hover:bg-gray-50'}
                path={revert}
                onClick={() => restoreDefaults(entry as CostAnomalyProjectLimits | CostAnomalyServiceLimits)}
                tooltipText={t('userSettings.notifications.restoreDefaults_one')}
                tooltipStyles={'min-w-max'}
              />
            </div>
          )}
        </Row>
        <CostAnomalySettingsInputs
          percent={percent}
          setPercent={setPercent}
          impact={impact}
          setImpact={setImpact}
          currency={entry.currency}
        />
      </InputRowsWrapper>
      <div className={'flex flex-col items-center justify-evenly w-max pl-6'}>
        {'costNotificationsDisabledAt' in entry && (
          <SwitchButton
            label={t('userSettings.notifications.notifications')}
            labelPosition={'top'}
            colorScale={'primary'}
            checked={notificationsEnabled}
            clickHandler={() => {
              if (notificationsEnabled) {
                disableNotifications(entry)
                setEmailsSubscribed(false)
                setNotificationsEnabled(false)
              } else {
                enableNotifications(entry)
                setNotificationsEnabled(true)
              }
            }}
          />
        )}
        <SwitchButton
          label={t('userSettings.notifications.emailNotifications')}
          labelPosition={'top'}
          colorScale={'primary'}
          checked={emailsSubscribed}
          tooltipText={t('userSettings.notifications.emailsTooltip')}
          tooltipStyles={'mt-4'}
          clickHandler={() => {
            if (!emailsSubscribed) {
              if ('costNotificationsDisabledAt' in entry && entry.costNotificationsDisabledAt !== null) {
                enableNotifications(entry)
              }
              subscribeToEmail(entry)
              setNotificationsEnabled(true)
              setEmailsSubscribed(true)
            } else {
              unsubscribeFromEmail(entry)
              setEmailsSubscribed(false)
            }
          }}
        />
      </div>
    </div>
  )
}

const InputRowsWrapper = styled.div`
  ${tw`flex flex-col w-full divide-y divide-gray-500`}
  & > div {
    ${tw`py-5 first:pt-0 last:pb-0`}
  }
`

const Row = styled.div`
  ${tw`flex w-full justify-between items-center gap-5 text-90 first:pt-0 last:pb-0`}
`
