import { UserRole } from '../../../../../api/auth'
import {
  getAwsAccountOptions,
  getAzureSubOptions,
  getGcpProjectOptions,
  OrganizationUser,
  PermittedAwsAccount,
  PermittedAzureSub,
  PermittedGcpProject,
  PermittedVendorProject,
  updateProjectPermission
} from '../../../../../api/admin/users'
import { ProjectAccess } from './ProjectAccess'
import { useCancelToken } from '../../../../../api/client'
import { useErrorHandling } from '../../../../../hooks/handleError'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Vendor } from '../../../../../utils/vendors'
import { useTranslation } from 'react-i18next'
import { MessageContext, MessageState, MessageType } from '../../../../../state/context/MessageContext'
import { Roles } from './Roles'

interface VendorAccessProps {
  vendor: Vendor
  editedUser: OrganizationUser
  setEditedUser: (user: OrganizationUser) => void
  permittedProjects: PermittedVendorProject[]
}

export const VendorAccess = ({ vendor, editedUser, setEditedUser, permittedProjects }: VendorAccessProps) => {
  const { setMessage } = useContext<MessageState>(MessageContext)
  const { t } = useTranslation()
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const [projectOptions, setProjectOptions] = useState<PermittedVendorProject[]>([])
  const [selectedProjects, setSelectedProjects] = useState<PermittedVendorProject[]>(permittedProjects)
  const isInitialMount = useRef(true)

  const vendorUserRole =
    vendor === Vendor.AWS ? UserRole.AWS_USER : vendor === Vendor.AZURE ? UserRole.AZURE_USER : UserRole.GCP_USER

  useEffect(() => {
    const cancelToken = createCancelToken()
    vendor === Vendor.AWS
      ? getAwsAccountOptions(cancelToken.token)
          .then(resp =>
            setProjectOptions(
              resp.map((account: PermittedAwsAccount) => ({
                vendorTenantId: account.masterAccountId,
                value: account.id,
                label: account.name
              }))
            )
          )
          .catch(handleError)
      : vendor === Vendor.AZURE
        ? getAzureSubOptions(cancelToken.token)
            .then(resp =>
              setProjectOptions(
                resp.map((sub: PermittedAzureSub) => ({
                  vendorTenantId: sub.azureTenantId,
                  value: sub.id,
                  label: sub.name
                }))
              )
            )
            .catch(handleError)
        : getGcpProjectOptions(cancelToken.token)
            .then(resp =>
              setProjectOptions(
                resp.map((project: PermittedGcpProject) => ({
                  vendorTenantId: project.organizationId,
                  value: project.id,
                  label: project.name
                }))
              )
            )
            .catch(handleError)

    return () => {
      cancelToken.cancel()
      isInitialMount.current = true
    }
  }, [vendor, handleError, createCancelToken])

  useEffect(() => {
    const cancelToken = createCancelToken()
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      updateProjectPermission(vendor, editedUser.id, selectedProjects, cancelToken.token)
        .then(user => {
          setEditedUser(user)
          setMessage({
            message: t('admin.tabs.rolesAndAccessRights.projectChangeSuccess'),
            type: MessageType.SUCCESS
          })
        })
        .catch(handleError)
    }
  }, [selectedProjects])

  return (
    <div>
      <Roles vendor={vendor} user={editedUser} setUser={setEditedUser} vendorUserRole={vendorUserRole} />
      <ProjectAccess
        vendor={vendor}
        editedUser={editedUser}
        vendorUserRole={vendorUserRole}
        projectOptions={projectOptions}
        selectedProjects={selectedProjects}
        setSelectedProjects={setSelectedProjects}
      />
    </div>
  )
}
