import React, { useContext, useEffect } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { logOut, UserRole } from '../api/auth'
import { Costs } from '../components/costs/Costs'
import { Compliance } from '../components/compliance/Compliance'
import { featuresFlags } from '../state/featuresFlags'
import { Tickets } from '../components/tickets/Tickets'
import { ConsentAzure } from '../components/login/ConsentAzure'
import { FederatedAuth, Login } from '../components/login/Login'
import { PrivateRoute } from './PrivateRoute'
import { Home } from '../components/home/Home'
import { Sustainability } from '../components/sustainability/Sustainability'
import { SystemAdmin } from '../components/SystemAdmin'
import { Infra } from '../components/infra/Infra'
import { AuthInfoContext, AuthInfoState, defaultAuthInfoState } from '../state/context/AuthInfoContext'
import { clearPersistedLoginInfo } from '../api/localStorage'
import { UserDetails } from '../components/admin/users/UserDetails'
import { ADMIN_ROLES } from '../components/admin/roleConstants'
import { Optimization } from '../components/optimization/Optimization'
import { UserList } from '../components/admin/users/UserList'
import { AdminIntegrations, NewIntegration } from '../components/admin/integrations/AdminIntegrations'
import { SubNavLayout } from './SubNav'
import { UserNotificationSettings } from '../components/user-profile/notifications/UserNotificationSettings'
import { Vendor, Vendors } from '../utils/vendors'
import { GcpIntegrationDetails } from '../components/admin/integrations/gcp/GcpIntegrationDetails'
import { AwsIntegrationDetails } from '../components/admin/integrations/aws/AwsIntegrationDetails'
import { SignUp } from '../components/login/SignUp'
import { useQueryParameters } from '../utils/router'
import { AzureIntegrationDetails } from '../components/admin/integrations/azure/AzureIntegrationDetails'
import { SystemAdminList } from '../components/master-admin/SystemAdminList'
import { SystemAdminDetails } from '../components/master-admin/SystemAdminDetails'
import { MasterAdminUsersList } from '../components/master-admin/MasterAdminUsersList'
import { MasterAdminUserDetails } from '../components/master-admin/MasterAdminUserDetails'
import { UserProfile } from '../components/user-profile/general/UserProfile'
import { Tenants } from '../components/master-admin/Tenants'

const Logout = () => {
  const history = useHistory()
  const { setAuthInfo } = useContext<AuthInfoState>(AuthInfoContext)

  useEffect(() => {
    logOut().then(() => {
      setAuthInfo(defaultAuthInfoState.authInfo)
      clearPersistedLoginInfo()
      sessionStorage.clear()
      history.push('/login')
    })
  }, [history, setAuthInfo])

  return null
}

const Routes = () => {
  const { t } = useTranslation()
  const { authInfo } = useContext<AuthInfoState>(AuthInfoContext)
  const isAdmin = authInfo.roles.some(role => ADMIN_ROLES.includes(role))

  return (
    <Switch>
      <Route exact path={'/'}>
        {authInfo.isLoggedIn ? <Redirect to={'/home'} /> : <Login />}
      </Route>
      <Route exact path={'/signup'}>
        <SignUp />
      </Route>
      <Route path={`/aws-marketplace/signup/:tenantId`}>
        <SignUp />
      </Route>
      <Route exact path={'/login'}>
        <Login />
      </Route>
      <Route exact path={'/federated-auth'}>
        <FederatedAuth />
      </Route>
      <Route exact path={'/azure-admin-consent'} component={ConsentAzure} />
      <PrivateRoute exact path={'/logout'}>
        <Logout />
      </PrivateRoute>
      {featuresFlags.home && (
        <PrivateRoute exact path={'/home'}>
          <Home />
        </PrivateRoute>
      )}
      {featuresFlags.costs && authInfo.costsAccess && (
        <PrivateRoute exact path={'/costs'}>
          <Costs />
        </PrivateRoute>
      )}
      {featuresFlags.optimization && authInfo.optimizationAccess && (
        <PrivateRoute exact path={'/optimization'}>
          <Optimization />
        </PrivateRoute>
      )}
      {featuresFlags.compliance && authInfo.complianceAccess && (
        <PrivateRoute exact path={'/compliance'}>
          <Compliance />
        </PrivateRoute>
      )}
      {featuresFlags.tickets && authInfo.ticketAccess && authInfo.freshIntegration && (
        <PrivateRoute exact path={'/tickets'}>
          <Tickets />
        </PrivateRoute>
      )}
      {featuresFlags.infra && authInfo.infraAccess && (
        <PrivateRoute exact path={'/infra'}>
          <Infra />
        </PrivateRoute>
      )}
      {featuresFlags.sustainability && authInfo.sustainabilityAccess && (
        <PrivateRoute exact path={'/sustainability'}>
          <Sustainability />
        </PrivateRoute>
      )}
      {featuresFlags.admin && isAdmin && <AdminRoutes path={'/admin'} />}
      {featuresFlags.systemAdmin &&
        authInfo.roles.some(role => role === UserRole.SYSTEM_ADMIN || role === UserRole.MASTER_ADMIN) && (
          <PrivateRoute exact path={'/system-admin'}>
            <SystemAdmin />
          </PrivateRoute>
        )}
      {featuresFlags.masterAdmin && authInfo.roles.includes(UserRole.MASTER_ADMIN) && (
        <MasterAdminRoutes path={'/master-admin'} />
      )}
      <UserRoutes path={'/profile'} />
      <PrivateRoute exact path={'*'}>
        <>{t('routes.notFound')}</>
      </PrivateRoute>
    </Switch>
  )
}

interface NestedRoutesProps {
  path: string
}

const AdminRoutes = ({ path }: NestedRoutesProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { authInfo } = useContext<AuthInfoState>(AuthInfoContext)
  const queryParams = useQueryParameters()
  const isFirst = Boolean(queryParams.get('first'))
  const hasIntegration =
    authInfo.awsIntegration.integration || authInfo.azureIntegration.integration || authInfo.gcpIntegration.integration
  const links = [
    {
      label: t('admin.subnav.users'),
      to: `${path}/users`
    },
    {
      label: t('admin.subnav.integrations'),
      to: `${path}/integrations`
    }
  ]

  useEffect(() => {
    history.location.pathname.includes('users') ? history.push(`${path}/users`) : history.push(`${path}/integrations`)
  }, [authInfo.tenantId])

  return (
    <>
      {history.location.pathname === `${path}` && (
        <Redirect to={isFirst || !hasIntegration ? `${links[1].to}` : `${links[0].to}`} />
      )}
      <PrivateRoute exact path={`${path}/users`}>
        <SubNavLayout links={links}>
          <UserList />
        </SubNavLayout>
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/users/:userId`}>
        <SubNavLayout links={links}>
          <UserDetails />
        </SubNavLayout>
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/integrations`}>
        {authInfo.awsIntegration.integration && authInfo.awsAccess ? (
          <Redirect to={`${path}/integrations/aws`} />
        ) : authInfo.azureIntegration.integration && authInfo.azureAccess ? (
          <Redirect to={`${path}/integrations/azure`} />
        ) : authInfo.gcpIntegration.integration && authInfo.gcpAccess ? (
          <Redirect to={`${path}/integrations/gcp`} />
        ) : (
          <>
            <Redirect to={`${path}/integrations?first=true`} />
            <SubNavLayout links={links}>
              <NewIntegration />
            </SubNavLayout>
          </>
        )}
      </PrivateRoute>
      {Vendors.map(vendor => (
        <PrivateRoute key={vendor} exact path={`${path}/integrations/${vendor.toLowerCase()}`}>
          <SubNavLayout links={links}>
            <AdminIntegrations selectedVendor={vendor} />
          </SubNavLayout>
        </PrivateRoute>
      ))}
      <PrivateRoute exact path={`${path}/integrations/aws/:masterAccountId`}>
        <SubNavLayout links={links}>
          <AwsIntegrationDetails />
        </SubNavLayout>
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/integrations/azure/:azureTenantId`}>
        <SubNavLayout links={links}>
          <AzureIntegrationDetails />
        </SubNavLayout>
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/integrations/gcp/:organizationId`}>
        <SubNavLayout links={links}>
          <GcpIntegrationDetails />
        </SubNavLayout>
      </PrivateRoute>
    </>
  )
}

const UserRoutes = ({ path }: NestedRoutesProps) => {
  const { t } = useTranslation()
  const { authInfo } = useContext<AuthInfoState>(AuthInfoContext)
  const history = useHistory()
  const hasIntegration =
    authInfo.awsIntegration.integration || authInfo.azureIntegration.integration || authInfo.gcpIntegration.integration
  const links = [{ label: t('subNav.userSettings.general'), to: `${path}/general` }]
  featuresFlags.notifications &&
    authInfo.notificationsAccess &&
    hasIntegration &&
    links.push({ label: t('subNav.userSettings.notifications'), to: `${path}/notifications` })

  return (
    <>
      {history.location.pathname === `${path}` && <Redirect to={`${links[0].to}`} />}
      <PrivateRoute exact path={`${path}/general`}>
        <SubNavLayout links={links}>
          <UserProfile />
        </SubNavLayout>
      </PrivateRoute>
      {featuresFlags.notifications && authInfo.notificationsAccess && (
        <>
          <PrivateRoute exact path={`${path}/notifications`}>
            {authInfo.awsAccess && authInfo.awsIntegration.integration ? (
              <Redirect to={`${path}/notifications/aws`} />
            ) : authInfo.azureAccess && authInfo.azureIntegration.integration ? (
              <Redirect to={`${path}/notifications/azure`} />
            ) : authInfo.gcpAccess && authInfo.gcpIntegration.integration ? (
              <Redirect to={`${path}/notifications/gcp`} />
            ) : (
              <Redirect to={`${path}/notifications/na`} />
            )}
          </PrivateRoute>
          <PrivateRoute exact path={`${path}/notifications/aws`}>
            <SubNavLayout links={links}>
              <UserNotificationSettings selectedVendor={Vendor.AWS} />
            </SubNavLayout>
          </PrivateRoute>
          <PrivateRoute exact path={`${path}/notifications/azure`}>
            <SubNavLayout links={links}>
              <UserNotificationSettings selectedVendor={Vendor.AZURE} />
            </SubNavLayout>
          </PrivateRoute>
          <PrivateRoute exact path={`${path}/notifications/gcp`}>
            <SubNavLayout links={links}>
              <UserNotificationSettings selectedVendor={Vendor.GCP} />
            </SubNavLayout>
          </PrivateRoute>
          <PrivateRoute exact path={`${path}/notifications/na`}>
            <SubNavLayout links={links}>
              <UserNotificationSettings selectedVendor={null} />
            </SubNavLayout>
          </PrivateRoute>
        </>
      )}
    </>
  )
}

export const MasterAdminRoutes = ({ path }: NestedRoutesProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const tenantsPath = `${path}/tenants`
  const systemAdminsPath = `${path}/system-admins`
  const usersPath = `${path}/users`
  const links = [
    { label: t('subNav.masterAdmin.tenants'), to: tenantsPath },
    { label: t('subNav.masterAdmin.systemAdmins'), to: systemAdminsPath },
    { label: t('subNav.masterAdmin.users'), to: usersPath }
  ]
  return (
    <>
      {history.location.pathname === `${path}` && <Redirect to={tenantsPath} />}
      <PrivateRoute exact path={tenantsPath}>
        <SubNavLayout links={links}>
          <Tenants />
        </SubNavLayout>
      </PrivateRoute>
      <PrivateRoute exact path={systemAdminsPath}>
        <SubNavLayout links={links}>
          <SystemAdminList />
        </SubNavLayout>
      </PrivateRoute>
      <PrivateRoute exact path={`${systemAdminsPath}/:userId`}>
        <SubNavLayout links={links}>
          <SystemAdminDetails />
        </SubNavLayout>
      </PrivateRoute>
      <PrivateRoute exact path={usersPath}>
        <SubNavLayout links={links}>
          <MasterAdminUsersList />
        </SubNavLayout>
      </PrivateRoute>
      <PrivateRoute exact path={`${usersPath}/:userId`}>
        <SubNavLayout links={links}>
          <MasterAdminUserDetails />
        </SubNavLayout>
      </PrivateRoute>
    </>
  )
}

export default Routes
