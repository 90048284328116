import React, { useEffect, useRef, useState } from 'react'
import { CostChartDataResponse, CostEntry, getCostChartData } from '../../api/costs'
import { useTranslation } from 'react-i18next'
import { formatMonthLong, formatMonthShortString, formatNumber, formatToYear, roundNumber } from '../../utils/formats'
import { DomainTuple, Tuple, VictoryAxis, VictoryGroup, VictoryLine, VictoryScatter } from 'victory'
import { gray300, gray500, primary500, primary600, primary700, primary800, small } from '../../design/constants'
import { DataContainer } from '../shared/containers/DataContainer'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useErrorHandling } from '../../hooks/handleError'
import { useCancelToken } from '../../api/client'
import { CustomTooltip } from '../shared/CustomTooltip'
import { Loading } from '../shared/Loading'
import { MissingDataNotification } from '../shared/MissingDataNotification'
import { FilterOption } from '../shared/filters/FilterSelect'
import { Vendor } from '../../utils/vendors'
import { Currency, CurrencyFlag, CurrencyId, getCurrency, KronaCurrencies } from '../../utils/Currency'
import { GradientText, Heading, LightGrayText, WhiteText } from '../shared/TextComponents'
import { CostChangeIndicator } from '../shared/indicators/CostChangeIndicator'
import { CountUp } from '../shared/CountUp'
import { SwitchButton } from '../shared/buttons/SwitchButton'
import { ChartZoomToggles, CustomVictoryChart, LineChartHeader } from '../shared/charts/CustomLineChart'

interface Props {
  selectedVendors: Vendor[]
  selectedProjects: string[]
  selectedResourceGroups: string[]
  selectedServices: string[]
  selectedTags: FilterOption[]
  selectedCurrency: CurrencyId
}

export const CostsChart = ({
  selectedVendors,
  selectedProjects,
  selectedResourceGroups,
  selectedServices,
  selectedTags,
  selectedCurrency
}: Props) => {
  const { createCancelToken } = useCancelToken()
  const { t } = useTranslation()
  const handleError = useErrorHandling()
  const [costChartData, setCostChartData] = useState<CostChartDataResponse | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [maxAmount, setMaxAmount] = useState(2000)
  const isInitialMount = useRef<boolean>(true)
  const minZoomDomain: DomainTuple = [0.5, 13.5]
  const [maxZoomDomain, setMaxZoomDomain] = useState<Tuple<number>>([11.5, 24.5])
  const [zoomDomain, setZoomDomain] = useState<Tuple<number>>(maxZoomDomain)
  const [compareLines, setCompareLines] = useState(true)

  useEffect(() => {
    if (isInitialMount.current) {
      setLoading(true)
    }
    const cancelToken = createCancelToken()
    getCostChartData(
      selectedVendors,
      selectedProjects,
      selectedResourceGroups,
      selectedServices,
      selectedTags.map(tag => ({
        vendor: tag.vendor ?? '',
        tagKey: tag.label,
        tagValues: tag.nest?.map(value => value.value) ?? []
      })),
      selectedCurrency,
      cancelToken.token
    )
      .then(resp => {
        setCostChartData(resp)
        if (isInitialMount.current) {
          isInitialMount.current = false
          setZoomDomain([
            resp.history.length + resp.forecast.length - 14.5,
            resp.history.length + resp.forecast.length - 1.5
          ])
          setMaxZoomDomain([
            resp.history.length + resp.forecast.length - 14.5,
            resp.history.length + resp.forecast.length - 1.5
          ])
        }
        setMaxAmount(Math.max(...resp.history.concat(resp.forecast).map(amount => amount.amount)))
      })
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      setLoading(false)
      cancelToken.cancel()
    }
  }, [
    selectedVendors,
    selectedProjects,
    selectedResourceGroups,
    selectedServices,
    selectedTags,
    selectedCurrency,
    createCancelToken,
    handleError
  ])

  if (loading)
    return (
      <DataContainer flexCol={true} looseSpacing={true}>
        <Heading>{t('costs.costsChart.title')}</Heading>
        <Loading paddingY={'7%'} />
      </DataContainer>
    )
  if (!costChartData)
    return (
      <DataContainer flexCol={true} looseSpacing={true}>
        <Heading>{t('costs.costsChart.title')}</Heading>
        <MissingDataNotification justify={'center'} />
      </DataContainer>
    )

  const currency = getCurrency(selectedCurrency)
  const currYearDates = costChartData.history.filter((_, index) => index >= 12).map(item => item.date)
  const compareHistoryData = costChartData.history
    .filter((_, index) => index < new Date().getMonth() + 1)
    .map((item, index) => ({
      ...item,
      date: currYearDates.find((_, i) => i === index)
    }))
  const legendData = [{ name: t('costs.costsChart.costsToDate'), symbol: { fill: primary500 } }]
  compareLines && legendData.unshift({ name: t('costs.costsChart.lastYear'), symbol: { fill: primary800 } })

  return (
    <div id={'qa-costs-chart-container'}>
      <DataContainer flexCol={true} looseSpacing={true}>
        <ChartHeader
          data={costChartData}
          currency={currency}
          setCompareLines={setCompareLines}
          compareLines={compareLines}
          setZoomDomain={setZoomDomain}
          zoomDomain={zoomDomain}
          minZoomDomain={minZoomDomain}
          maxZoomDomain={maxZoomDomain}
        />
        <CustomVictoryChart
          domain={[0, maxAmount > 1 ? maxAmount : 1]}
          zoomDomain={zoomDomain}
          tooltip={<CostsTooltip />}
          tooltipLabel={({ datum }) => datum.amount}
          legendData={legendData}
        >
          <VictoryAxis
            dependentAxis
            crossAxis={false}
            style={{
              axis: { stroke: 'none' },
              axisLabel: {},
              grid: { stroke: gray500 },
              ticks: { size: 0 },
              tickLabels: {
                fontSize: small,
                fontWeight: 200,
                padding: 20,
                fill: gray300
              }
            }}
          />
          <VictoryAxis
            style={{
              axis: { stroke: 'none' },
              axisLabel: {},
              grid: { stroke: 'none' },
              ticks: { size: 0 },
              tickLabels: {
                letterSpacing: '1px',
                fontSize: small,
                fontWeight: 200,
                padding: 20,
                fill: gray300
              }
            }}
            tickFormat={t => formatMonthShortString(t)}
          />
          <VictoryAxis
            style={{
              axis: { stroke: 'none' },
              tickLabels: {
                letterSpacing: '1px',
                fontSize: '12px',
                fontWeight: 200,
                padding: 40,
                fill: gray300
              }
            }}
            tickFormat={tick => (tick.toString().includes('01-') ? formatToYear(tick) : '')}
          />
          <VictoryLine
            data={costChartData.history}
            x={'date'}
            y={'amount'}
            style={{
              data: {
                stroke: primary500,
                strokeWidth: 1
              }
            }}
          />
          <VictoryScatter
            data={costChartData.history}
            x={'date'}
            y={'amount'}
            style={{ data: { fill: primary500 } }}
            size={({ active }) => (active ? 4.5 : 3)}
          />
          {compareLines && (
            <VictoryGroup>
              <VictoryLine
                data={compareHistoryData}
                x={'date'}
                y={'amount'}
                style={{
                  data: {
                    stroke: primary800,
                    strokeWidth: 1
                  }
                }}
              />
              <VictoryScatter
                data={compareHistoryData}
                x={'date'}
                y={'amount'}
                style={{ data: { fill: primary800 } }}
                size={({ active }) => (active ? 4.5 : 3)}
              />
            </VictoryGroup>
          )}
          <VictoryLine
            data={costChartData.initForecast}
            x={'date'}
            y={'amount'}
            style={{
              data: {
                stroke: primary600,
                strokeDasharray: '2',
                strokeWidth: 1
              }
            }}
          />
          <VictoryScatter
            data={costChartData.initForecast}
            x={'date'}
            y={'amount'}
            style={{ data: { fill: primary700 } }}
            size={({ active }) => (active ? 4.5 : 3)}
          />
          <VictoryLine
            data={costChartData.forecast}
            x={'date'}
            y={'amount'}
            style={{
              data: {
                stroke: primary500,
                strokeDasharray: '2',
                strokeWidth: 1
              }
            }}
          />
          <VictoryScatter
            data={costChartData.forecast}
            x={'date'}
            y={'amount'}
            style={{ data: { fill: primary600 } }}
            size={({ active }) => (active ? 4.5 : 3)}
          />
        </CustomVictoryChart>
      </DataContainer>
    </div>
  )
}

interface ChartHeaderProps {
  data: CostChartDataResponse
  currency: Currency
  setCompareLines: (value: boolean) => void
  compareLines: boolean
  setZoomDomain: (value: Tuple<number>) => void
  zoomDomain: Tuple<number>
  minZoomDomain: Tuple<number>
  maxZoomDomain: Tuple<number>
}

const ChartHeader = ({
  data,
  currency,
  setCompareLines,
  compareLines,
  setZoomDomain,
  zoomDomain,
  minZoomDomain,
  maxZoomDomain
}: ChartHeaderProps) => {
  const { t } = useTranslation()

  return (
    <LineChartHeader
      heading={t('costs.costsChart.title')}
      subheading={
        <>
          <WhiteText className={'first-letter:capitalize'}>
            {t('costs.costsChart.currentTotal')}
            <ChartHighlight className={'text-125'}>
              <CountUp id={'qa-costs-total-history-costs'} countTo={roundNumber(data.currentTotal, 0)} />
              <span className={'inline-flex text-112 gap-2 items-center'}>
                {currency.symbol}
                {KronaCurrencies.some(id => id === currency.id) && <CurrencyFlag currencyId={currency.id} size={4} />}
              </span>
            </ChartHighlight>
          </WhiteText>
          <div className={'flex gap-1'}>
            <CostChangeIndicator id={'qa-costs-change-from-last-year'} change={data.changeFromLastYear} />
            {data.changeFromLastYear && (
              <LightGrayText lowercase={true}>
                {data.changeFromLastYear === 0
                  ? t('costs.costsChart.noChange')
                  : data.changeFromLastYear > 0
                    ? t('costs.costsChart.increase')
                    : t('costs.costsChart.decrease')}
              </LightGrayText>
            )}
          </div>
          <LightGrayText className={'text-90 first-letter:capitalize'}>
            {t('costs.costsChart.lastYearCurrent', {
              month: formatMonthLong(new Date(new Date().setFullYear(new Date().getFullYear() - 1)), true)
            })}
            <ChartHighlight>
              <CountUp id={'qa-costs-last-year-history-costs'} countTo={roundNumber(data.lastYearCurrent, 0)} />
              <span className={'inline-flex gap-2 items-center'}>{currency.symbol}</span>
            </ChartHighlight>
          </LightGrayText>
        </>
      }
      actions={
        <>
          <SwitchButton
            label={t('costs.costsChart.compareLastYear')}
            clickHandler={() => setCompareLines(!compareLines)}
            checked={compareLines}
            colorScale={'gray'}
          />
          <ChartZoomToggles
            setDomain={setZoomDomain}
            domain={zoomDomain}
            minDomain={minZoomDomain}
            maxDomain={maxZoomDomain}
          />
        </>
      }
    />
  )
}

const ChartHighlight = styled(GradientText)`
  ${tw`inline-flex pl-2 gap-1 font-black from-primary-200 to-primary-300`}
`

interface TooltipProps {
  active?: boolean
  x?: number
  y?: number
  datum?: CostEntry
}

const CostsTooltip = ({ active, x, y, datum }: TooltipProps) => {
  if (!active) return null
  const width = 140
  const height = 54
  // @ts-ignore
  const posX = x - width / 2
  // @ts-ignore
  const posY = y - height - 1
  if (!datum) return null
  return (
    <g>
      <foreignObject x={posX} y={posY} width={width} height={height}>
        <CustomTooltip pointerDirection={'down'} showIcon={false}>
          {`${formatNumber(datum['amount'])} ${getCurrency(datum['currency']).symbol}`}
        </CustomTooltip>
      </foreignObject>
    </g>
  )
}
