import { NestedPageHeader } from '../../../shared/NestedPageHeader'
import { AdminContentWrapper } from '../../adminStyles'
import React, { useContext, useEffect, useState } from 'react'
import { useCancelToken } from '../../../../api/client'
import { useTranslation } from 'react-i18next'
import { MessageContext, MessageType } from '../../../../state/context/MessageContext'
import { ModalContext } from '../../../../state/context/ModalContext'
import { useErrorHandling } from '../../../../hooks/handleError'
import {
  addGcpBillingAccount,
  deleteGcpIntegration,
  editGcpBillingAccount,
  editGcpIntegration,
  GcpBillingAccount,
  GcpIntegrationData,
  GcpIntegrationRequest,
  getGcpIntegrationDetails,
  recoverGcpBillingAccount,
  recoverGcpIntegration,
  softDeleteGcpBillingAccount
} from '../../../../api/admin/integrations/gcp'
import { useHistory, useParams } from 'react-router-dom'
import {
  DetailedIntegrationModal,
  IntegrationDetailsInfoSection,
  IntegrationDetailsPageHeader,
  IntegrationDetailsWrapper,
  IntegrationsTab,
  IntegrationsTabs,
  LoadingIntegrationDetails
} from '../Shared'
import { GcpIntegrationsAddModal } from './GcpIntegrations'
import { UnsavedChangesPromptModal } from '../../../shared/modal/UnsavedChangesPropmtModal'
import { TabsContainer } from '../../../shared/tabs/TabSharedComponents'
import { GcpIntegrationAccounts } from './GcpIntegrationAccounts'
import { GcpIntegrationTimestamps } from './GcpIntegrationTimestamps'

export const GcpIntegrationDetails = () => {
  const { createCancelToken } = useCancelToken()
  const { t } = useTranslation()
  const history = useHistory()
  const { organizationId } = useParams<{
    organizationId: string
  }>()
  const { setMessage } = useContext(MessageContext)
  const { setModal } = useContext(ModalContext)
  const handleError = useErrorHandling()
  const [selectedIntegration, setSelectedIntegration] = useState<GcpIntegrationData | null>(null)

  useEffect(() => {
    getGcpIntegrationDetails(organizationId, createCancelToken().token)
      .then(resp => {
        setSelectedIntegration(resp)
      })
      .catch(handleError)
    return () => {
      setSelectedIntegration(null)
      createCancelToken().cancel()
    }
  }, [createCancelToken, handleError, organizationId])

  if (!selectedIntegration) return <LoadingIntegrationDetails />

  const editIntegration = (request: string) => {
    editGcpIntegration(selectedIntegration.organizationId, request, createCancelToken().token)
      .then(data => {
        setSelectedIntegration(data)
        setModal(null)
        setMessage({ type: MessageType.SUCCESS, message: t('admin.integrations.gcp.organizationEditSuccessToast') })
      })
      .catch(handleError)
  }

  const editBillingAccount = (request: GcpBillingAccount) => {
    editGcpBillingAccount(selectedIntegration.organizationId, request, createCancelToken().token)
      .then(data => {
        setSelectedIntegration(data)
        setModal(null)
        setMessage({ type: MessageType.SUCCESS, message: t('admin.integrations.gcp.accountEditSuccessToast') })
      })
      .catch(handleError)
  }

  const recoverIntegration = () => {
    recoverGcpIntegration(organizationId)
      .then(() => {
        getGcpIntegrationDetails(organizationId, createCancelToken().token)
          .then(resp => {
            setSelectedIntegration(resp)
            setModal(null)
            setMessage({
              type: MessageType.SUCCESS,
              message: t('admin.integrations.gcp.orgRecoverSuccessToast')
            })
          })
          .catch(handleError)
      })
      .catch(handleError)
  }

  const openAccountAddModal = (billingAccount?: GcpBillingAccount) => {
    const recoverAccount = (billingAccount: GcpBillingAccount) => {
      recoverGcpBillingAccount(organizationId, billingAccount, createCancelToken().token)
        .then(resp => {
          setSelectedIntegration(resp)
          setModal(null)
          setMessage({ type: MessageType.SUCCESS, message: t('admin.integrations.gcp.accountRecoverSuccessToast') })
        })
        .catch(handleError)
    }

    const addBillingAccount = (request: GcpIntegrationRequest) => {
      addGcpBillingAccount(request, createCancelToken().token)
        .then(resp => {
          const account = resp.billingAccounts.find(
            account => account.accountId === request.accountId && account.projectId === request.projectId
          )
          if (account?.deletedAt) {
            setModal({
              header: t('admin.integrations.gcp.accountRecoveryHeader'),
              body: (
                <DetailedIntegrationModal
                  description={t('admin.integrations.gcp.accountRecoveryMessage')}
                  detailRows={[
                    { label: t('admin.integrations.accountId'), value: account.accountId },
                    { label: t('admin.integrations.gcp.projectId'), value: account.projectId },
                    { label: t('admin.integrations.gcp.tableId'), value: account.tableId }
                  ]}
                  action={() => recoverAccount(account)}
                  actionLabel={t('admin.integrations.recover')}
                />
              )
            })
          } else {
            setSelectedIntegration(resp)
            setModal(null)
            setMessage({ type: MessageType.SUCCESS, message: t('admin.integrations.gcp.accountAddSuccessToast') })
          }
        })
        .catch(handleError)
    }

    if (billingAccount) {
      recoverAccount(billingAccount)
    } else {
      setModal({
        header: t('admin.integrations.gcp.setAccountDetails'),
        body: (
          <GcpIntegrationsAddModal
            selectedIntegration={selectedIntegration}
            submitAction={addBillingAccount}
            accountOnly={true}
          />
        )
      })
    }
  }

  const openOrganizationDeleteModal = () => {
    const deleteAction = () =>
      deleteGcpIntegration(selectedIntegration.organizationId)
        .then(() => {
          history.push('/admin/integrations/gcp')
          setModal(null)
          setMessage({
            type: MessageType.SUCCESS,
            message: t('admin.integrations.gcp.removeSuccessToast')
          })
        })
        .catch(handleError)

    setModal({
      header: t('admin.integrations.confirmRemoveRequest'),
      body: (
        <DetailedIntegrationModal
          description={t('admin.integrations.gcp.removeDescription')}
          detailRows={[
            { label: t('admin.integrations.gcp.organizationId'), value: selectedIntegration.organizationId },
            { label: t('admin.integrations.name'), value: selectedIntegration.name }
          ]}
          action={deleteAction}
          actionLabel={t('admin.integrations.remove')}
        />
      )
    })
  }

  const openAccountDeleteModal = (billingAccount: GcpBillingAccount) => {
    const deleteAction = () => {
      softDeleteGcpBillingAccount(selectedIntegration.organizationId, billingAccount)
        .then(resp => {
          setSelectedIntegration(resp)
          setModal(null)
          setMessage({
            type: MessageType.SUCCESS,
            message: t('admin.integrations.gcp.accountRemoveSuccessToast')
          })
        })
        .catch(handleError)
    }

    setModal({
      header: t('admin.integrations.confirmRemoveRequest'),
      body: (
        <DetailedIntegrationModal
          description={t('admin.integrations.gcp.removeAccountDescription')}
          detailRows={[
            { label: t('admin.integrations.gcp.projectId'), value: billingAccount.projectId },
            { label: t('admin.integrations.accountId'), value: billingAccount.accountId },
            { label: t('admin.integrations.gcp.tableId'), value: billingAccount.tableId }
          ]}
          action={deleteAction}
          actionLabel={t('admin.integrations.remove')}
        />
      )
    })
  }

  return (
    <div className={'flex flex-col w-full'}>
      <NestedPageHeader backButtonPath={'/admin/integrations/gcp'}>
        <IntegrationDetailsPageHeader
          name={selectedIntegration.name}
          lastIntegrationAt={selectedIntegration.lastIntegrationAt}
          buttonValue={selectedIntegration.deletedAt && t('admin.integrations.recover')}
          buttonAction={!!selectedIntegration?.deletedAt && recoverIntegration}
          deleteAction={!selectedIntegration?.deletedAt && openOrganizationDeleteModal}
        />
      </NestedPageHeader>
      <AdminContentWrapper className={'max-h-[80vh] xl:max-h-[86vh]'}>
        <IntegrationDetails
          key={selectedIntegration?.billingAccounts?.length}
          selectedIntegration={selectedIntegration}
          editIntegrationAction={editIntegration}
          editBillingAccountAction={editBillingAccount}
          openAccountAddModal={openAccountAddModal}
          openAccountDeleteModal={openAccountDeleteModal}
        />
      </AdminContentWrapper>
    </div>
  )
}

interface IntegrationDetailsProps {
  selectedIntegration: GcpIntegrationData
  openAccountAddModal: (account?: GcpBillingAccount) => void
  openAccountDeleteModal: (account: GcpBillingAccount) => void
  editIntegrationAction: (request: string) => void
  editBillingAccountAction: (request: GcpBillingAccount) => void
}

const IntegrationDetails = ({
  selectedIntegration,
  openAccountAddModal,
  openAccountDeleteModal,
  editIntegrationAction,
  editBillingAccountAction
}: IntegrationDetailsProps) => {
  const [showPrompt, setShowPrompt] = useState(false)
  const [selectedTab, setSelectedTab] = useState(IntegrationsTab.DETAILS)

  return (
    <TabsContainer>
      <IntegrationsTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <IntegrationDetailsWrapper>
        <UnsavedChangesPromptModal showModal={showPrompt} />
        <IntegrationDetailsInfoSection removeDate={selectedIntegration.deletedAt} selectedTab={selectedTab} />
        {selectedTab === IntegrationsTab.DETAILS ? (
          <GcpIntegrationAccounts
            selectedIntegration={selectedIntegration}
            openAccountAddModal={openAccountAddModal}
            openAccountDeleteModal={openAccountDeleteModal}
            editIntegrationAction={editIntegrationAction}
            editBillingAccountAction={editBillingAccountAction}
            setShowPrompt={setShowPrompt}
          />
        ) : (
          <GcpIntegrationTimestamps selectedIntegration={selectedIntegration} />
        )}
      </IntegrationDetailsWrapper>
    </TabsContainer>
  )
}
