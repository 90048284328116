import React from 'react'
import { useTranslation } from 'react-i18next'
import { CustomIcon, IconType } from './CustomIcon'
import skull from '../../assets/svg/objects/flat-colored/skull.svg'
import { CapitalizedText } from './TextComponents'

interface MissingDataNotificationProps {
  justify?: 'center' | 'start'
  paddingY?: number
  icon?: boolean
  displayText?: string
}

export const MissingDataNotification = ({
  justify = 'center',
  paddingY = 8,
  icon = true,
  displayText
}: MissingDataNotificationProps) => {
  const { t } = useTranslation()

  return (
    <div className={`flex items-center justify-${justify} gap-3 w-full py-${paddingY} text-gray-200`}>
      {icon && <CustomIcon iconType={IconType.FLAT} path={skull} />}
      <CapitalizedText>{displayText ?? t('error.noData')}</CapitalizedText>
    </div>
  )
}
