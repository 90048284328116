import { useTranslation } from 'react-i18next'
import React, { useContext, useState } from 'react'
import { MessageContext, MessageState, MessageType } from '../../state/context/MessageContext'
import { configureCognito, getLoginInfo, login } from '../../api/auth'
import { CustomInput, CustomLabel } from '../shared/filters/InputComponents'
import { Button, ButtonSize, ButtonStyle } from '../shared/buttons/Button'
import { CapitalizedText } from '../shared/TextComponents'
import { LoginFormContainer, LoginInstructions } from './Shared'
import { LoginFlowState } from './Login'
import { useForm } from 'react-hook-form'
import { getPersistedLoginInfo, persistLoginInfo } from '../../api/localStorage'
import { CognitoUser } from '@aws-amplify/auth'
import { emailRegExp } from '../../utils/formats'

interface SignUpLoginProps {
  email: string | null
  setEmail: (email: string) => void
  setCognitoUser: (cognitoUser: CognitoUser) => void
  setLoginFlow: (state: LoginFlowState) => void
}

export interface LoginInputs {
  emailAddress: string
  password: string
}

export const SignUpLogin = ({ email, setEmail, setCognitoUser, setLoginFlow }: SignUpLoginProps) => {
  const { t } = useTranslation()
  const { setMessage } = useContext<MessageState>(MessageContext)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const activateAccount = (data: LoginInputs) => {
    setIsSubmitting(true)
    getLoginInfo(data.emailAddress)
      .then(loginInfo => {
        setEmail(loginInfo.emailAddress)
        persistLoginInfo(loginInfo)
        configureCognito(getPersistedLoginInfo())
        login(data.emailAddress, data.password)
          .then(cognitoUser => {
            setCognitoUser(cognitoUser)
            setLoginFlow(LoginFlowState.ACTIVATE_ACCOUNT)
            setIsSubmitting(false)
          })
          .catch(e => {
            setMessage({
              message: e.message,
              type: MessageType.ERROR
            })
          })
      })
      .catch(e => {
        setMessage({
          message: e.message,
          type: MessageType.ERROR
        })
      })
  }

  return (
    <div className={'flex flex-col gap-4 items-center'}>
      <LoginInstructions title={t('login.activateAccount')} instructions={t('login.signUp.checkEmail')} />
      <SignUpLoginForm email={email} activateAccount={activateAccount} isSubmitting={isSubmitting} />
      <div className={'flex flex-col'}>
        <CapitalizedText className={'text-gray-300 text-center text-80'}>{t('login.helpLinkTitle')}</CapitalizedText>
        <Button
          value={t('login.helpLink')}
          buttonStyle={ButtonStyle.GHOST}
          size={ButtonSize.SMALL}
          clickHandler={() => setLoginFlow(LoginFlowState.RESET_PASSWORD)}
          disabled={isSubmitting}
        />
      </div>
    </div>
  )
}

interface SignUpLoginFormProps {
  email: string | null
  isSubmitting: boolean
  activateAccount: (data: LoginInputs) => void
}

const SignUpLoginForm = ({ email, isSubmitting, activateAccount }: SignUpLoginFormProps) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    register,
    formState: { isValid }
  } = useForm<LoginInputs>({
    defaultValues: { emailAddress: email || '', password: '' }
  })

  const onSubmit = (data: LoginInputs) => {
    activateAccount(data)
  }

  return (
    <LoginFormContainer>
      <div>
        <CustomLabel>{t('common.email')}</CustomLabel>
        <CustomInput
          type={'email'}
          autoComplete={'username'}
          {...register('emailAddress', {
            required: true,
            validate: value => emailRegExp.test(value)
          })}
        />
      </div>
      <div>
        <CustomLabel>{t('login.password')}</CustomLabel>
        <CustomInput
          autoFocus={true}
          type={'password'}
          autoComplete={'current-password'}
          {...register('password', {
            required: true
          })}
        />
      </div>
      <Button value={t('login.login')} disabled={!isValid || isSubmitting} clickHandler={handleSubmit(onSubmit)} />
    </LoginFormContainer>
  )
}
