import { SideDrawer } from '../layout/SideDrawer'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalContext, ModalState } from '../../../state/context/ModalContext'
import { FilterOption, FilterSelect, filterSelectAllOption, GroupedOptions } from './FilterSelect'
import tw from 'twin.macro'
import styled from 'styled-components'
import { ModalActions, ModalText } from '../modal/Modal'
import { Button, ButtonSize, ButtonStyle, ButtonType } from '../buttons/Button'
import { Vendor, Vendors } from '../../../utils/vendors'
import closeIcon from '../../../assets/svg/actions/close.svg'
import { GrayText } from '../TextComponents'
import { useKeyPress } from '../../../hooks/useKeyPress'
import { CustomInput, CustomLabel } from './InputComponents'
import { Loading } from '../Loading'
import { CurrencyId } from '../../../utils/Currency'
import { AccessibleVendors } from '../../../api/auth'
import { NotificationsDrawerContext } from '../../../state/context/NotificationsContext'
import { FiltersContext } from '../../../state/context/FiltersContext'

export const getVendorFilterOptions = () => {
  return [
    {
      label: Vendor.AWS,
      value: Vendor.AWS
    },
    {
      label: Vendor.AZURE,
      value: Vendor.AZURE
    },
    {
      label: Vendor.GCP,
      value: Vendor.GCP
    }
  ].filter(vendor => AccessibleVendors().includes(vendor.label))
}

export interface CurrencyFilterOption {
  label: string
  value: CurrencyId
}

export enum FilterType {
  CURRENCY = 'currency',
  PRESETS = 'presets',
  PROJECTS = 'projects',
  REGIONS = 'regions',
  RECOMMENDATIONS = 'recommendations',
  RESOURCE_GROUPS = 'resourceGroups',
  RESOURCE_TYPES = 'resourceTypes',
  SERVICES = 'services',
  STANDARDS = 'standards',
  TAGS = 'tags',
  TIMEFRAME = 'timeframe',
  VENDORS = 'vendors'
}

export interface FilterMenu {
  type: FilterType
  value: FilterOption[]
  options: FilterOption[] | GroupedOptions[]
  onChange: (args: any) => void
}

export interface SelectedFilterOption {
  options: FilterOption[]
  clickHandler: (args: any) => void
  type: FilterType | string
}

const tagColors = [
  'bg-primary-500 hover:bg-primary-400',
  'bg-gray-500 hover:bg-gray-400',
  'bg-gray-400 hover:bg-gray-400',
  'bg-gray-400 hover:bg-gray-300',
  'bg-gray-300 hover:bg-gray-200',
  'bg-gray-200 hover:bg-gray-200',
  'bg-gray-200 hover:bg-gray-100',
  'bg-gray-100 hover:bg-gray-100',
  'bg-gray-100 hover:bg-gray-50'
]

interface FilterProps {
  filterMenuOptions: FilterMenu[]
  selectedOptions: SelectedFilterOption[]
  saveAction: (name: string) => void
  deleteAction: (name: string) => void
  selectedPreset: FilterOption | null
  setSelectedPreset: (option: FilterOption | null) => void
  savedPresets: string[]
  loading: boolean
  topLevelAction?: React.ReactNode
}

export const Filters = ({
  filterMenuOptions,
  selectedOptions,
  saveAction,
  deleteAction,
  selectedPreset,
  setSelectedPreset,
  savedPresets,
  loading,
  topLevelAction
}: FilterProps) => {
  const { activeFilters, setActiveFilters, filtersOpen, setFiltersOpen } = useContext(FiltersContext)
  selectedOptions = selectedOptions.filter(option => option.type !== FilterType.CURRENCY)
  const [showTags, setShowTags] = useState(true)
  const { t } = useTranslation()
  const { notificationsOpen } = useContext(NotificationsDrawerContext)
  const { setModal } = useContext<ModalState>(ModalContext)
  const customSelection = selectedOptions.filter(
    tag =>
      (tag.type === 'vendors' && tag.options.length !== AccessibleVendors().length) ||
      (tag.type !== 'vendors' && tag.options.length > 0)
  )

  useEffect(() => {
    setActiveFilters(customSelection.length)
  }, [customSelection])

  const openDeleteModal = (presetName: string) =>
    setModal({
      header: t('filters.modalDeletePresetHeader'),
      body: <DeletePresetModal deleteAction={deleteAction} selectedPreset={presetName} />
    })

  return (
    <SideDrawer
      id={'filters-drawer'}
      drawerOpen={!notificationsOpen ? filtersOpen : false}
      setDrawerOpen={setFiltersOpen}
      paddingX={3}
      transitionStyles={'origin-right right-0'}
      content={
        <div className={'flex flex-col gap-4'}>
          {loading ? (
            <Loading />
          ) : (
            <>
              {topLevelAction}
              {filterMenuOptions.map((select, index) => (
                <FilterSelect
                  key={`filter-${select.type}`}
                  type={select.type}
                  header={t(`filters.${select.type}`)}
                  isMulti={select.type !== FilterType.CURRENCY}
                  placeholder={t('common.search')}
                  value={select.value}
                  indicatorColor={tagColors[index]}
                  options={select.options}
                  // @ts-ignore react-selects value is unknown
                  onChange={selected => select.onChange(selected)}
                />
              ))}
              <FilterSelect
                deletePresetAction={openDeleteModal}
                type={FilterType.PRESETS}
                header={t('filters.presets')}
                value={selectedPreset}
                placeholder={t('common.search')}
                indicatorColor={'bg-primary-700 hover:bg-primary-600'}
                options={savedPresets.map(preset => ({
                  label: preset,
                  value: preset
                }))}
                // @ts-ignore
                onChange={selected => setSelectedPreset(selected)}
              />
            </>
          )}
          {activeFilters > 0 && (
            <div className={'flex w-full items-center justify-between'}>
              <Button
                buttonStyle={ButtonStyle.GHOST}
                size={ButtonSize.XSMALL}
                value={showTags ? t('filters.hideTags') : t('filters.showTags')}
                clickHandler={() => setShowTags(!showTags)}
              />
              <div className={'flex w-full justify-end'}>
                <Button
                  buttonStyle={ButtonStyle.SECONDARY}
                  size={ButtonSize.XSMALL}
                  value={t('filters.savePreset')}
                  clickHandler={() =>
                    setModal({
                      header: t('filters.modalHeader'),
                      body: (
                        <SavePresetModal
                          saveAction={saveAction}
                          selectedPreset={selectedPreset?.value ?? ''}
                          savedPresets={savedPresets}
                        />
                      )
                    })
                  }
                />
                <Button
                  buttonStyle={ButtonStyle.GHOST}
                  size={ButtonSize.XSMALL}
                  value={t('filters.clearAll')}
                  clickHandler={() => {
                    setSelectedPreset(null)
                    filterMenuOptions
                      .filter(option => option.type !== FilterType.CURRENCY)
                      .forEach(option => {
                        option.onChange([])
                      })
                  }}
                />
              </div>
            </div>
          )}
          <div className={'flex flex-col w-full divide-y divide-gray-500'}>
            {selectedOptions.map((tag, index) => (
              <div key={`tag-${tag.type}`} className={'flex flex-col gap-2 py-3'}>
                <GrayText className={'text-80'}>{t(`filters.${tag.type}`)}</GrayText>
                <TagContainer
                  showTags={showTags}
                  color={tagColors[index]}
                  clickHandler={tag.clickHandler}
                  options={tag.options.filter(option => option.value !== filterSelectAllOption.value)}
                  type={tag.type}
                />
              </div>
            ))}
          </div>
        </div>
      }
    />
  )
}

interface TagContainerProps {
  showTags?: boolean
  clickHandler: (options: FilterOption[]) => void
  options: FilterOption[]
  type: string
  color: string
}

const TagContainer = ({ showTags, clickHandler, options, type, color }: TagContainerProps) => {
  return (
    <div className={'flex flex-wrap gap-2'}>
      {showTags ? (
        options.map((option, index) =>
          option.nest && option.nest.length > 0 ? (
            option.nest.map(tagValue => (
              <Tag
                className={color}
                key={`${type}-${option.value}-${tagValue.value}-${index}`}
                onClick={() => {
                  clickHandler(
                    option.nest!!.length > 1
                      ? (options = options
                          .map(o => ({
                            vendor: o.vendor,
                            value: o.value,
                            label: o.label,
                            nest: o.nest?.filter(n => {
                              if (o.vendor === option.vendor && o.value === option.value)
                                return n.value !== tagValue.value
                              else return true
                            })
                          }))
                          .filter(o => o.nest?.length !== 0))
                      : (options = options.filter(o => JSON.stringify(o) !== JSON.stringify(option)))
                  )
                }}
              >
                <div className={'flex divide-x-2 gap-2'}>
                  <TagLabel>{option.label}</TagLabel>
                  <TagLabel isTag={true}>{tagValue.label}</TagLabel>
                </div>
                <TagIcon />
              </Tag>
            ))
          ) : (
            <Tag
              className={color}
              key={`${type}-${option.value}-${index}`}
              onClick={() => clickHandler(options.filter(o => o.value !== option.value))}
            >
              <TagLabel isVendor={Vendors.includes(option.label as Vendor)}>{option.label}</TagLabel>
              <TagIcon />
            </Tag>
          )
        )
      ) : (
        <GrayText className={'text-90'}>{options.length} selected</GrayText>
      )}
    </div>
  )
}

const Tag = styled.span`
  ${tw`inline-flex h-fit py-1.5 px-3 max-w-max rounded-full text-80 hover:cursor-pointer gap-1 leading-none items-center justify-center`}
`

interface TagLabelProps {
  isVendor?: boolean
  isTag?: boolean
}

const TagLabel = styled.div<TagLabelProps>`
  ${tw`text-center text-gray-50 inline`}
  ${props => props.isVendor && tw`lowercase`}
  ${props => props.isTag && tw`pl-2`}
`

const TagIcon = styled.div`
  mask: url(${closeIcon}) no-repeat center;
  ${tw`min-w-2 w-2 h-2 mx-2 bg-gray-50`}
`

interface SavePresetModalProps {
  saveAction: (filterName: string) => void
  selectedPreset: string
  savedPresets: string[]
}

const SavePresetModal = ({ saveAction, selectedPreset, savedPresets }: SavePresetModalProps) => {
  const { setModal } = useContext(ModalContext)
  const [newPresetName, setNewPresetName] = useState<string>(selectedPreset)
  const { t } = useTranslation()
  const [requestInFlight, setRequestInFlight] = useState<boolean>(false)
  const [nameIsValid, setNameIsValid] = useState(false)

  useEffect(() => {
    const regExp = new RegExp('[a-öA-Ö0-9+?!@#$%^&*-_.:;]')
    setNameIsValid(regExp.test(newPresetName))
  }, [newPresetName])

  const saveButtonText = () =>
    nameIsValid && savedPresets.includes(newPresetName)
      ? t('filters.modalSaveOld', { name: newPresetName })
      : t('filters.modalSaveNew')

  const savePresetAction = () => {
    setRequestInFlight(true)
    saveAction(newPresetName)
  }

  useKeyPress(() => savePresetAction(), ['Enter'])

  return (
    <>
      <div>
        <CustomLabel>{t('filters.modalNameLabel')} *</CustomLabel>
        <CustomInput
          required={true}
          autoFocus={true}
          placeholder={''}
          type={'text'}
          value={newPresetName}
          onChange={e => setNewPresetName(e.currentTarget.value)}
        />
      </div>
      <ModalActions>
        <Button
          disabled={requestInFlight || !newPresetName || !nameIsValid}
          value={t('common.cancel')}
          clickHandler={() => setModal(null)}
          type={ButtonType.MODAL}
          buttonStyle={ButtonStyle.SECONDARY}
        />
        <Button
          disabled={requestInFlight || !newPresetName || !nameIsValid}
          value={saveButtonText()}
          clickHandler={savePresetAction}
          type={ButtonType.MODAL}
        />
      </ModalActions>
    </>
  )
}

interface DeletePresetModalProps {
  deleteAction: (filterName: string) => void
  selectedPreset: string
}

const DeletePresetModal = ({ deleteAction, selectedPreset }: DeletePresetModalProps) => {
  const { t } = useTranslation()
  const [requestInFlight, setRequestInFlight] = useState<boolean>(false)

  return (
    <>
      <div className={'flex flex-col gap-2'}>
        <ModalText>{t('filters.modalDeletePresetText')}:</ModalText>
        <div className={'text-center text-gray-50 font-semibold'}>{selectedPreset}</div>
      </div>
      <ModalActions>
        <Button
          disabled={requestInFlight}
          value={t('common.delete')}
          clickHandler={() => {
            setRequestInFlight(true)
            deleteAction(selectedPreset)
          }}
        />
      </ModalActions>
    </>
  )
}
