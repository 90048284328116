import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { CostSummary } from './CostSummary'
import { TicketSummary } from './TicketSummary'
import { ComplianceSummary } from './ComplianceSummary'
import { AccountsSummary } from './AccountsSummary'
import { ViewWrapper } from '../shared/layout/ViewWrapper'
import { SustainabilitySummary } from './SustainabilitySummary'
import { InfraSummary } from './InfraSummary'
import { AuthInfoContext, AuthInfoState } from '../../state/context/AuthInfoContext'
import { IntegrationSummary } from './IntegrationSummary'
import { AdminContacts } from './AdminContacts'
import { OptimizationSummary } from './OptimizationSummary'
import { firstLogin, removeFirstLogin } from '../../state/storage'
import { ModalContext } from '../../state/context/ModalContext'
import { Button, ButtonSize } from '../shared/buttons/Button'
import { useTranslation } from 'react-i18next'
import { CapitalizedText } from '../shared/TextComponents'
import { ADMIN_ROLES } from '../admin/roleConstants'
import { HelpTooltipInfoBlock, HelpTooltipModal } from '../shared/tooltips/HelpTooltip'
import keyIcon from '../../assets/svg/objects/key.svg'
import adminIcon from '../../assets/svg/sidebar/admin-icon.svg'
import profileIcon from '../../assets/svg/sidebar/user-settings-icon.svg'
import envelope from '../../assets/svg/objects/envelope.svg'

export const Home = () => {
  const { t } = useTranslation()
  const { authInfo } = useContext<AuthInfoState>(AuthInfoContext)
  const { setModal } = useContext(ModalContext)
  const hasAnyAccess =
    authInfo.awsAccess || authInfo.gcpAccess || authInfo.azureAccess || authInfo.ticketAccess || authInfo.infraAccess

  useEffect(() => {
    firstLogin() &&
      setModal({
        header: t('common.greeting'),
        body: <FirstLoginModal />
      })
  }, [setModal])

  return (
    <ViewWrapper>
      {!hasAnyAccess && <AdminContacts />}
      {authInfo.costsAccess ? <AccountsSummary /> : <div className={'p-0'}></div>}
      <HomeGrid>
        {authInfo.costsAccess && <CostSummary />}
        {authInfo.optimizationAccess && <OptimizationSummary />}
        {authInfo.infraAccess && <InfraSummary />}
        {authInfo.sustainabilityAccess && <SustainabilitySummary />}
        {authInfo.freshIntegration && authInfo.ticketAccess && <TicketSummary />}
        {authInfo.complianceAccess && <ComplianceSummary />}
        {hasAnyAccess && <IntegrationSummary />}
      </HomeGrid>
    </ViewWrapper>
  )
}

const HomeGrid = styled.div`
  ${tw`grid auto-rows-12rem w-full gap-4 lg:auto-rows-15rem lg:grid-cols-2`}
`

const FirstLoginModal = () => {
  const { t } = useTranslation()
  const { setModal } = useContext(ModalContext)
  const { authInfo } = useContext<AuthInfoState>(AuthInfoContext)
  const contentRows = [
    t('home.firstLoginModal.text_1'),
    <HelpTooltipInfoBlock iconPath={keyIcon} text={t('home.firstLoginModal.text_2')} />
  ]
  authInfo.roles.some(role => ADMIN_ROLES.includes(role)) &&
    contentRows.push(<HelpTooltipInfoBlock iconPath={adminIcon} text={t('home.firstLoginModal.text_admin')} />)
  contentRows.push(
    <HelpTooltipInfoBlock iconPath={profileIcon} text={t('home.firstLoginModal.text_3')} />,
    <HelpTooltipInfoBlock iconPath={envelope} text={t('home.firstLoginModal.text_4')} />,
    <CapitalizedText className={'text-primary-500'}>{t('home.firstLoginModal.text_last')}</CapitalizedText>
  )

  return (
    <HelpTooltipModal
      contentRows={contentRows}
      actions={
        <Button
          value={t('home.firstLoginModal.startUsing')}
          size={ButtonSize.XSMALL}
          clickHandler={() => {
            removeFirstLogin()
            setModal(null)
          }}
        />
      }
    />
  )
}
