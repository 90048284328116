import styled from 'styled-components'
import tw from 'twin.macro'
import { CountUp } from './CountUp'
import React from 'react'
import { roundNumber } from '../../utils/formats'
import { CapitalizedText, GrayText } from './TextComponents'

export const PageHeaderWrapper = styled.div`
  ${tw`flex flex-col gap-6 items-center justify-between rounded-b-md bg-gray-600 shadow-container p-6 lg:flex-row lg:pt-9`}
`

export const PageTitle = styled(CapitalizedText)`
  ${tw`text-gray-300 tracking-wide text-center lg:text-left`}
`

export const HeaderCountersWrapper = styled.div`
  ${tw`flex flex-row items-center justify-center lg:justify-start gap-10`}
`

interface HeaderCounterProps {
  value: number
  description: string
  id?: string
}

export const HeaderCounter = ({ value, description, id }: HeaderCounterProps) => {
  return (
    <div className={'flex flex-col min-w-20 text-center lg:text-left'}>
      <div className={'text-200 text-gray-50'}>
        <CountUp id={id} countTo={roundNumber(value, 0)} />
      </div>
      <GrayText className={'text-90'}>{description}</GrayText>
    </div>
  )
}
