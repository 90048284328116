import { client } from './client'
import { CancelToken } from 'axios'
import { FilterOption, GroupedOptions } from '../components/shared/filters/FilterSelect'
import { Vendor } from '../utils/vendors'
import { DataBlockData } from '../components/shared/containers/DataBlock'
import { TimeframeOption } from '../utils/classes'

export const getSustainabilityFilterData = async (cancelToken: CancelToken): Promise<SustainabilityFilterData> => {
  const resp = await client.get('sustainability/filters/options', {
    cancelToken: cancelToken
  })
  return resp.data
}

export const saveSustainabilityFilterPreset = async (
  filter: SustainabilityFilterPreset,
  cancelToken: CancelToken
): Promise<SustainabilityFilterPreset> => {
  const resp = await client.post('sustainability/filters/presets', filter, {
    cancelToken
  })
  return resp.data
}

export const getSavedSustainabilityFilterPresets = async (
  cancelToken: CancelToken
): Promise<SustainabilityFilterPreset[]> => {
  const resp = await client.get('sustainability/filters/presets', { cancelToken })
  return resp.data
}

export const deleteSustainabilityFilterPreset = async (filterName: string, cancelToken: CancelToken): Promise<void> => {
  const resp = await client.delete(`sustainability/filters/${filterName}`, {
    cancelToken
  })
  return resp.data
}

export const getSustainabilityTotalSummary = async (
  vendors: Vendor[],
  projects: string[],
  resourceGroups: string[],
  services: string[],
  regions: string[],
  timeframe: TimeframeOption,
  cancelToken: CancelToken
): Promise<SustainabilityTotalSummary> => {
  const filters = {
    vendors,
    projects,
    resourceGroups,
    services,
    regions
  }
  return await client
    .post('/sustainability/total-summary', { filters, timeframe }, { cancelToken })
    .then(resp => resp.data)
}

export const getSustainabilityChartData = async (
  vendors: Vendor[],
  projects: string[],
  resourceGroups: string[],
  services: string[],
  regions: string[],
  cancelToken: CancelToken
): Promise<SustainabilityChartData[]> => {
  return await client
    .post(
      'sustainability/chart-data',
      {
        vendors,
        projects,
        resourceGroups,
        services,
        regions
      },
      { cancelToken }
    )
    .then(resp => resp.data)
}

export const getSustainabilityConsumptionTabData = async (
  vendors: Vendor[],
  projects: string[],
  resourceGroups: string[],
  services: string[],
  regions: string[],
  timeframe: TimeframeOption,
  cancelToken: CancelToken
): Promise<SustainabilityConsumptionTabData> => {
  const filters = {
    vendors,
    projects,
    resourceGroups,
    services,
    regions
  }
  return await client
    .post('/sustainability/tabs/consumption', { filters, timeframe }, { cancelToken })
    .then(resp => resp.data)
}

export const getSustainabilityVolumeTabData = async (
  vendors: Vendor[],
  projects: string[],
  resourceGroups: string[],
  services: string[],
  regions: string[],
  timeframe: TimeframeOption,
  cancelToken: CancelToken
): Promise<SustainabilityVolumeTabData> => {
  const filters = {
    vendors,
    projects,
    resourceGroups,
    services,
    regions
  }
  return await client
    .post('/sustainability/tabs/volume', { filters, timeframe }, { cancelToken })
    .then(resp => resp.data)
}

export interface SustainabilityFilterData {
  projects: GroupedOptions[]
  resourceGroups: GroupedOptions[]
  services: GroupedOptions[]
  regions: GroupedOptions[]
}

export interface SustainabilityFilterPreset {
  name: string
  vendors: FilterOption[]
  projects: FilterOption[]
  resourceGroups: FilterOption[]
  services: FilterOption[]
  regions: FilterOption[]
}

export interface SustainabilityTotalSummary {
  totals: ConsumptionSummary
  breakdown: EmissionBreakdown[]
}

export interface ConsumptionSummary {
  cloudEmissions: number | null
  kwhSpend: number | null
  emissionSavings: number | null
  savingsEquivalency: EmissionEquivalency | null
}

export interface EmissionBreakdown {
  category: string
  percent: number | null
}

export interface EmissionEquivalency {
  value: number
  equivalent: string
}

export enum ConsumptionData {
  cloudEmissions = 'cloudEmissions',
  kwhSpend = 'kwhSpend'
}

export enum EmissionEquivalent {
  BEER = 'beer',
  BREAD = 'bread',
  CAR = 'car',
  CHEESE = 'cheese',
  GOOGLESEARCH = 'googleSearch',
  IPHONE = 'iPhone',
  MEAT = 'meat',
  MILK = 'milk',
  PLANE = 'plane',
  STREAMING = 'streaming',
  TREES = 'trees'
}

export interface SustainabilityChartData {
  vendor: Vendor
  chartEntries: SustainabilityChartEntry[]
}

export interface SustainabilityChartEntry {
  vendor: Vendor
  date: string
  cloudEmissions: number | null
  kwhSpend: number | null
}

export interface SustainabilityConsumptionTabData {
  projects: SustainabilityConsumptionTabColumn
  services: SustainabilityConsumptionTabColumn
  regions: SustainabilityConsumptionTabColumn
}

export interface SustainabilityConsumptionTabColumn {
  emissions: DataBlockData[]
  kwhSpends: DataBlockData[]
}

export interface SustainabilityVolumeTabData {
  projects: SustainabilityVolumeTabColumn
  services: SustainabilityVolumeTabColumn
  regions: SustainabilityVolumeTabColumn
}

export interface SustainabilityVolumeTabColumn {
  compute: DataBlockData[]
  storage: DataBlockData[]
  networking: DataBlockData[]
}

export enum UsageTypeCategory {
  COMPUTE = 'COMPUTE',
  NETWORKING = 'NETWORKING',
  MEMORY = 'MEMORY',
  STORAGE = 'STORAGE'
}
