import React, { useState } from 'react'
import { InfraMap } from './InfraMap'
import { InfraFilters } from './InfraFilters'
import { FilterOption } from '../shared/filters/FilterSelect'
import { getVendorFilterOptions } from '../shared/filters/Filters'
import { ViewWrapper } from '../shared/layout/ViewWrapper'
import { InfraTabs } from './tabs/InfraTabs'
import { Vendor } from '../../utils/vendors'

export const Infra = () => {
  const [selectedVendors, setSelectedVendors] = useState<FilterOption[]>([])
  const [selectedRegions, setSelectedRegions] = useState<FilterOption[]>([])
  const [selectedProjects, setSelectedProjects] = useState<FilterOption[]>([])
  const [selectedResourceGroups, setSelectedResourceGroups] = useState<FilterOption[]>([])
  const [selectedResources, setSelectedResources] = useState<FilterOption[]>([])

  if (!selectedVendors.length) setSelectedVendors(getVendorFilterOptions())

  return (
    <ViewWrapper>
      <InfraFilters
        selectedVendors={selectedVendors}
        selectedRegions={selectedRegions}
        selectedProjects={selectedProjects}
        selectedResourceGroups={selectedResourceGroups}
        selectedResources={selectedResources}
        setSelectedVendors={setSelectedVendors}
        setSelectedRegions={setSelectedRegions}
        setSelectedProjects={setSelectedProjects}
        setSelectedResourceGroups={setSelectedResourceGroups}
        setSelectedResources={setSelectedResources}
      />
      <InfraMap
        selectedVendors={selectedVendors.map(v => v.value as Vendor)}
        selectedRegions={selectedRegions.map(r => r.value)}
        selectedProjects={selectedProjects.map(p => p.value)}
        selectedResourceGroups={selectedResourceGroups.map(rg => rg.value)}
        selectedResources={selectedResources.map(r => r.value)}
      />
      <InfraTabs
        selectedVendors={selectedVendors.map(v => v.value as Vendor)}
        selectedRegions={selectedRegions.map(r => r.value)}
        selectedProjects={selectedProjects.map(p => p.value)}
        selectedResourceGroups={selectedResourceGroups.map(rg => rg.value)}
        selectedResources={selectedResources.map(r => r.value)}
      />
    </ViewWrapper>
  )
}
