import { Vendor } from '../../utils/vendors'
import { CancelToken } from 'axios'
import { client } from '../client'
import { CloudProject, CloudRegion } from '../../utils/classes'
import { CurrencyEntry, CurrencyId } from '../../utils/Currency'

export const getOptimizationAwsTabData = async (
  vendors: Vendor[],
  projects: string[],
  recommendations: string[],
  currency: CurrencyId,
  cancelToken: CancelToken
): Promise<AwsRecommendations> => {
  const resp = await client.post<AwsRecommendations>(
    'optimization/tabs/aws-recommendations',
    {
      vendors,
      projects,
      recommendations,
      currency
    },
    { cancelToken }
  )
  return {
    ...resp.data,
    utilization: resp.data.utilization.map(check => ({
      ...check,
      recommendations: check.recommendations.map(recommendation => ({
        ...recommendation,
        updatedAt: recommendation.updatedAt && new Date(recommendation.updatedAt)
      }))
    }))
  }
}

export interface AwsRecommendations {
  commitments: AwsCommitmentsSummary
  rightsizing: AwsRightsizingSummary[]
  utilization: AwsUtilizationSummary[]
}

export interface AwsCommitmentsSummary {
  recommendationCount: number
  potentialAnnualSavings: CurrencyEntry
  recommendations: AwsCommitments
}

export interface AwsCommitments {
  reservedInstances: AwsReservedInstanceRecommendations[]
  savingsPlans: AwsSavingPlans[]
}

export interface AwsReservedInstanceRecommendations {
  recommendation: string
  potentialAnnualSavings: CurrencyEntry
  recommendations: AwsReservationsByAccount[]
}

export interface AwsReservationsByAccount {
  account: CloudProject
  potentialAnnualSavings: CurrencyEntry
  recommendations: AwsReservation[]
}

export interface AwsReservation {
  account: CloudProject
  instanceDetails: AwsInstanceDetails
  quantity: number
  region: CloudRegion
  monthlyCost: number
  monthlySavings: number
  paymentOption: string
  upfrontCost: number | null
  currency: CurrencyId
  breakEvenMonths: number
}

export interface AwsSavingPlans {
  recommendation: string
  potentialAnnualSavings: CurrencyEntry
  recommendations: AwsSavingPlansByAccount[]
}

export interface AwsSavingPlansByAccount {
  account: CloudProject
  potentialAnnualSavings: CurrencyEntry
  recommendations: AwsSavingsPlan[]
}

export interface AwsSavingsPlan {
  region: CloudRegion
  instanceFamily: string
  monthlyCost: number
  monthlySavings: number
  paymentOption: string
  upfrontCost: number | null
  currency: CurrencyId
  roi: number
}

export interface AwsRightsizingSummary {
  account: CloudProject
  instanceCount: number
  potentialAnnualSavings: CurrencyEntry
  recommendations: AwsRightsizingRecommendation[]
}

export interface AwsRightsizingRecommendation {
  currentInstance: AwsInstanceDetails
  targetInstance: AwsInstanceDetails | null
  region: CloudRegion
  monthlyCost: number
  monthlySavings: number
  currency: CurrencyId
  savingPercent: number
}

interface AwsInstanceDetails {
  resourceId: string
  name: string
  reasons: string[]
  family: string
  type: string
  platform: string
  platformDifferences: string[]
  region: string
}

export interface AwsUtilizationSummary {
  checkName: string
  category: string
  annualSavings: number | null
  currency: CurrencyId | null
  description: LinksByLabel
  recommendation: LinksByLabel
  additionalResources: HtmlLink[]
  recommendations: AwsUtilization[]
}

export interface AwsUtilization {
  account: CloudProject
  flaggedResources: number
  monthlySavings: number | null
  annualSavings: number | null
  currency: CurrencyId | null
  status: string
  updatedAt: Date | null
}

interface LinksByLabel {
  label: string
  resources: HtmlLink[]
}

export interface HtmlLink {
  text: string
  href: string
}
