import React, { useContext } from 'react'
import { AuthInfoContext, AuthInfoState } from '../../../state/context/AuthInfoContext'
import { useTranslation } from 'react-i18next'
import { CustomIcon, IconType } from '../../shared/CustomIcon'
import { GrayText, LightGrayText, WhiteText } from '../../shared/TextComponents'
import { SubNavContentWrapper } from '../../../layout/SubNav'
import { NestedPageHeader, NestedPageHeaderActions, NestedPageHeaderTitles } from '../../shared/NestedPageHeader'
import { Link } from 'react-router-dom'
import userIcon from '../../../assets/svg/objects/user.svg'
import { NavbarLinkId } from '../../../layout/navigation/Sidenav'
import signOut from '../../../assets/svg/actions/sign-out.svg'
import check from '../../../assets/svg/symbols/check-heavy.svg'
import { changeUserPreferredCurrency } from '../../../api/user/profile'
import { Currencies, CurrencyFlag, CurrencyId, getCurrency } from '../../../utils/Currency'
import { getCostAnomalyNotifications } from '../../../api/notifications'
import { UserPermissions } from './UserPermissions'
import { UserSettingsContext } from '../../../state/context/UserSettingsContext'
import { useCancelToken } from '../../../api/client'
import { NotificationsContext } from '../../../state/context/NotificationsContext'
import { useErrorHandling } from '../../../hooks/handleError'
import { UserRole } from '../../../api/auth'
import { ADMIN_ROLES } from '../../admin/roleConstants'
import { MessageContext, MessageType } from '../../../state/context/MessageContext'

export const UserProfile = () => {
  const { t } = useTranslation()
  const { authInfo } = useContext<AuthInfoState>(AuthInfoContext)

  return (
    <>
      <NestedPageHeader>
        <NestedPageHeaderTitles
          children={
            <div className={'flex gap-4 items-center'}>
              <CustomIcon iconType={IconType.VECTOR} path={userIcon} alt={'user-icon'} styles={'w-8 h-8 bg-gray-50'} />
              <div>
                <div className={'text-gray-50 font-semibold text-125'}>
                  {`${authInfo.firstName} ${authInfo.lastName}`}
                </div>
                <GrayText>{authInfo.tenantName}</GrayText>
              </div>
            </div>
          }
        />
        <NestedPageHeaderActions>
          <Link id={NavbarLinkId.LOGOUT} to={'/logout'} className={'flex items-center gap-2 group'}>
            <CustomIcon
              path={signOut}
              styles={'w-5 h-5 bg-gray-200 group-hover:bg-gray-50'}
              alt={t('sideNav.userSettings.signOut')}
            />
            <LightGrayText className={'text-80 group-hover:text-gray-50'}>
              {t('sideNav.userSettings.signOut')}
            </LightGrayText>
          </Link>
        </NestedPageHeaderActions>
      </NestedPageHeader>
      <SubNavContentWrapper divider={true}>
        <UserPermissions />
        <UserCurrency />
      </SubNavContentWrapper>
    </>
  )
}

const UserCurrency = () => {
  const { t } = useTranslation()
  const { authInfo } = useContext(AuthInfoContext)
  const { userSettings, setUserSettings } = useContext(UserSettingsContext)
  const { setMessage } = useContext(MessageContext)
  const { createCancelToken } = useCancelToken()
  const { setNotifications } = useContext(NotificationsContext)
  const handleError = useErrorHandling()

  const handleUserCurrencyChange = (selectedCurrency: CurrencyId) => {
    const cancelToken = createCancelToken()
    changeUserPreferredCurrency(selectedCurrency, cancelToken.token)
      .then(resp => {
        setUserSettings(resp)
        authInfo.roles.some(role => ADMIN_ROLES.includes(role) || role === UserRole.COSTS_USER) &&
          getCostAnomalyNotifications(cancelToken.token).then(setNotifications).catch(handleError)
        setMessage({
          type: MessageType.SUCCESS,
          message: t('userSettings.currencyChangeSuccess')
        })
      })
      .catch(handleError)
  }

  return (
    <div className={'flex flex-col gap-2'}>
      <WhiteText className={'first-letter:capitalize font-semibold text-90'}>
        {t('userSettings.currencyHeading')}
      </WhiteText>
      <LightGrayText>{t('userSettings.supportedCurrencies')}</LightGrayText>
      <div className={'flex flex-col gap-2.5 max-w-max'}>
        {Currencies.map(currencyId => (
          <div
            key={currencyId}
            className={`flex gap-3 items-center justify-between px-5 py-2.5 rounded transition-all ease duration-200 cursor-pointer ${userSettings.currency === currencyId ? 'border border-gray-50/80 shadow-sm' : 'border border-gray-50/20 hover:border-gray-50/60'}`}
            onClick={() => handleUserCurrencyChange(currencyId)}
          >
            <div className={'flex gap-3 items-center'}>
              <CurrencyFlag currencyId={currencyId} size={8} />
              <div className={'text-80'}>
                <WhiteText className={'font-semibold'}>{t(`common.currency.${currencyId}`)}</WhiteText>
                <LightGrayText>{getCurrency(currencyId).symbolLong}</LightGrayText>
              </div>
            </div>
            <CustomIcon
              path={check}
              styles={`w-4 h-4 ${currencyId === userSettings.currency ? 'bg-gray-50' : 'bg-transparent'}`}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
