import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import caretDown from '../../../assets/svg/directional/caret.svg'
import { TicketTable } from './TicketTable'
import { FreshTicket, getTicketsByStatus } from '../../../api/tickets'
import { Loading } from '../../shared/Loading'
import { useTranslation } from 'react-i18next'
import { CountUp } from '../../shared/CountUp'
import { useErrorHandling } from '../../../hooks/handleError'
import { useCancelToken } from '../../../api/client'
import { CustomIcon, IconType } from '../../shared/CustomIcon'
import { Button, ButtonStyle, ButtonType } from '../../shared/buttons/Button'

interface Props {
  status: string
}

export const TicketStatusSummary = ({ status }: Props) => {
  const [selected, setSelected] = useState(false)
  const { t } = useTranslation()
  const handleError = useErrorHandling()
  const { createCancelToken } = useCancelToken()
  const [loading, setLoading] = useState<boolean>(false)
  const [ticketData, setTicketData] = useState<FreshTicket[]>([])

  useEffect(() => {
    setLoading(true)
    const cancelToken = createCancelToken()
    getTicketsByStatus(status, cancelToken.token)
      .then(setTicketData)
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      cancelToken.cancel()
      setLoading(false)
      setTicketData([])
    }
  }, [status, handleError, createCancelToken])

  const incidentCount = ticketData.filter(ticket => ticket.type === 'Incident').length
  const serviceRequestCount = ticketData.filter(ticket => ticket.type === 'Service Request').length

  return (
    <>
      <Container showLoading={loading} onClick={() => setSelected(!selected)}>
        <ColouredEdge status={status} />
        {loading ? (
          <Loading />
        ) : (
          <Wrapper>
            <div className={'flex items-center gap-4'}>
              <Button
                clickHandler={() => setSelected(!selected)}
                type={ButtonType.ICON}
                buttonStyle={ButtonStyle.GHOST}
                value={
                  <CustomIcon
                    iconType={IconType.VECTOR}
                    path={caretDown}
                    styles={`w-6 h-6 bg-gray-50 transition-all ease ${selected ? 'rotate-180' : 'rotate-0'}`}
                    alt={t('tickets.toggleMenu')}
                  />
                }
              />
              <StatusSummary>
                <InnerWrapper>
                  <TicketStatus>{status}</TicketStatus>
                  <TicketTypes>
                    {incidentCount} {t('tickets.ticketTypes.incidents')}
                    {' - '}
                    {serviceRequestCount} {t('tickets.ticketTypes.serviceRequests')}
                  </TicketTypes>
                </InnerWrapper>
              </StatusSummary>
            </div>
            <StatusCount status={status}>
              <CountUp countTo={ticketData.length} id={`qa-tickets-${status}-count`} />
            </StatusCount>
          </Wrapper>
        )}
      </Container>
      {selected && <TicketTable tickets={ticketData} />}
    </>
  )
}

interface ColorProps {
  status: string
}

const ColouredEdge = styled.div<ColorProps>`
  ${tw`w-full rounded-t-md pb-1`}
  ${props =>
    props.status === 'OPEN'
      ? tw`bg-primary-500`
      : props.status === 'PENDING'
        ? tw`bg-primary-800`
        : props.status === 'RESOLVED'
          ? tw`bg-success-500`
          : props.status === 'CLOSED'
            ? tw`bg-gray-200`
            : tw`bg-primary-100`}
`

const StatusCount = styled.div<ColorProps>`
  ${tw`py-1.5 px-3 text-gray-50 text-center rounded-3xl`}
  ${props =>
    props.status === 'OPEN'
      ? tw`bg-primary-500`
      : props.status === 'PENDING'
        ? tw`bg-primary-800`
        : props.status === 'RESOLVED'
          ? tw`bg-success-500`
          : props.status === 'CLOSED'
            ? tw`bg-gray-200`
            : tw`bg-primary-100`}
`

interface ContainerProps {
  showLoading: boolean
}

const Container = styled.div<ContainerProps>`
  ${tw`flex flex-col bg-gray-600 relative rounded-md gap-1 cursor-pointer`}
  ${props => props.showLoading && tw`h-24 justify-center items-center cursor-default`}
`

const Wrapper = styled.div`
  ${tw`flex justify-between items-center gap-5 px-6 py-4 w-full`}
`

const StatusSummary = styled.div`
  ${tw`flex flex-row items-center justify-start gap-3`}
`

const InnerWrapper = styled.div`
  ${tw`flex flex-col text-left`}
`

const TicketStatus = styled.h3`
  ${tw`text-gray-50 text-112 font-medium`}
`

const TicketTypes = styled.div`
  ${tw`text-gray-200 text-100 font-light`}
`
