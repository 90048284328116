import React, { useContext, useEffect, useRef, useState } from 'react'
import { FilterOption } from '../shared/filters/FilterSelect'
import { useCancelToken } from '../../api/client'
import { useErrorHandling } from '../../hooks/handleError'
import {
  CurrencyFilterOption,
  FilterMenu,
  Filters,
  FilterType,
  getVendorFilterOptions,
  SelectedFilterOption
} from '../shared/filters/Filters'
import {
  CostFilterData,
  CostFilterPreset,
  deleteCostFilter,
  getCostFilterData,
  getCostFilterPresets,
  saveCostFilterPreset
} from '../../api/costs'
import { ModalContext, ModalState } from '../../state/context/ModalContext'
import { Vendor } from '../../utils/vendors'
import { AuthInfoContext, AuthInfoState } from '../../state/context/AuthInfoContext'
import { useTranslation } from 'react-i18next'
import { MessageContext, MessageState, MessageType } from '../../state/context/MessageContext'
import { useLocation } from 'react-router-dom'
import { CostNotificationDataKey } from '../shared/Enums'

interface Props {
  selectedVendors: FilterOption[]
  setSelectedVendors: (selectedVendors: FilterOption[]) => void
  selectedProjects: FilterOption[]
  setSelectedProjects: (selectedProjects: FilterOption[]) => void
  selectedResourceGroups: FilterOption[]
  setSelectedResourceGroups: (selectedResourceGroups: FilterOption[]) => void
  selectedServices: FilterOption[]
  setSelectedServices: (selectedServices: FilterOption[]) => void
  selectedTags: FilterOption[]
  setSelectedTags: (selectedTags: FilterOption[]) => void
  selectedCurrency: CurrencyFilterOption
  setSelectedCurrency: (selectedCurrency: CurrencyFilterOption) => void
}

export const CostFilters = ({
  selectedVendors,
  selectedProjects,
  selectedResourceGroups,
  selectedServices,
  setSelectedVendors,
  setSelectedProjects,
  setSelectedResourceGroups,
  setSelectedServices,
  selectedTags,
  setSelectedTags,
  selectedCurrency,
  setSelectedCurrency
}: Props) => {
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const location = useLocation()
  const { t } = useTranslation()
  const { authInfo } = useContext<AuthInfoState>(AuthInfoContext)
  const { setModal } = useContext<ModalState>(ModalContext)
  const { setMessage } = useContext<MessageState>(MessageContext)
  const [loading, setLoading] = useState<boolean>(true)
  const [initFilterData, setInitFilterData] = useState<CostFilterData | null>(null)
  const [filterOptions, setFilterOptions] = useState<CostFilterData | null>()
  const [savedPresets, setSavedPresets] = useState<CostFilterPreset[]>([])
  const [selectedPreset, setSelectedPreset] = useState<FilterOption | null>(null)
  const isInitialMount = useRef<boolean>(true)
  const storedVendor = sessionStorage.getItem(CostNotificationDataKey.VENDOR)
  const storedProjectId = sessionStorage.getItem(CostNotificationDataKey.PROJECT_ID)
  const storedProjectName = sessionStorage.getItem(CostNotificationDataKey.PROJECT_NAME)
  const storedService = sessionStorage.getItem(CostNotificationDataKey.SERVICE)
  const locationState = location.state as { from: string }
  const vendorOptions = getVendorFilterOptions()

  useEffect(() => {
    const cancelToken = createCancelToken()
    setLoading(true)
    Promise.all([
      getCostFilterData(cancelToken.token).then(filterData => {
        setInitFilterData(filterData)
        setFilterOptions(filterData)
      }),
      getCostFilterPresets(cancelToken.token).then(setSavedPresets)
    ])
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      cancelToken.cancel()
      setLoading(false)
      setInitFilterData(null)
      setFilterOptions(null)
      setSavedPresets([])
    }
  }, [createCancelToken, handleError, locationState?.from, setSelectedCurrency])

  useEffect(() => {
    if (selectedVendors.length > 0 && initFilterData) {
      const projectOptions = initFilterData.projectOptions.filter(project =>
        selectedVendors.map(vendor => vendor.value).includes(project.label)
      )

      const resourceGroupOptions = selectedVendors.map(vendor => vendor.value).includes(Vendor.AZURE)
        ? initFilterData.resourceGroupOptions
        : []

      const serviceOptions = initFilterData.serviceOptions.filter(service =>
        selectedVendors.map(vendor => vendor.value).includes(service.label)
      )

      const tagOptions = initFilterData.tagOptions.filter(tag =>
        selectedVendors.map(vendor => vendor.value).includes(tag.label)
      )

      setFilterOptions({
        projectOptions,
        resourceGroupOptions,
        serviceOptions,
        tagOptions,
        currencyOptions: initFilterData.currencyOptions
      })
    } else {
      setFilterOptions(initFilterData)
    }
  }, [initFilterData, selectedVendors])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      const chosenPreset = savedPresets.find(filter => filter.name === selectedPreset?.value)
      setSelectedVendors(chosenPreset?.vendors ?? vendorOptions)
      setSelectedProjects(chosenPreset?.projects ?? [])
      setSelectedResourceGroups(chosenPreset?.resourceGroups ?? [])
      setSelectedServices(chosenPreset?.services ?? [])
      setSelectedTags(chosenPreset?.tags ?? [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPreset])

  useEffect(() => {
    if (storedVendor) {
      setSelectedVendors([{ label: storedVendor, value: storedVendor }])
      sessionStorage.removeItem(CostNotificationDataKey.VENDOR)
    }
    if (storedProjectId && storedProjectName) {
      setSelectedProjects([{ label: storedProjectName, value: storedProjectId }])
      sessionStorage.removeItem(CostNotificationDataKey.PROJECT_ID)
      sessionStorage.removeItem(CostNotificationDataKey.PROJECT_NAME)
    }
    if (storedService) {
      setSelectedServices([{ label: storedService, value: storedService }])
      sessionStorage.removeItem(CostNotificationDataKey.SERVICE)
    }
  }, [storedVendor, storedProjectId, storedProjectName, storedService, setSelectedProjects, setSelectedServices])

  const saveFilter = (name: string) => {
    const filter: CostFilterPreset = {
      name: name,
      vendors: selectedVendors,
      projects: selectedProjects.map(entry => ({
        value: entry.value,
        label: entry.label
      })),
      resourceGroups: selectedResourceGroups.map(entry => ({
        value: entry.value,
        label: entry.label
      })),
      services: selectedServices.map(entry => ({
        value: entry.value,
        label: entry.label
      })),
      tags: selectedTags.map(entry => ({
        vendor: entry.vendor,
        value: entry.value,
        label: entry.label,
        count: entry.nest?.length,
        nest: entry.nest?.map(tag => ({
          vendor: tag.vendor,
          value: tag.value,
          label: tag.label
        }))
      })),
      currency: selectedCurrency
    }
    saveCostFilterPreset(filter, createCancelToken().token)
      .then(savedPreset => {
        setSavedPresets([...savedPresets].filter(preset => preset.name !== savedPreset.name).concat(savedPreset))
        setSelectedPreset({
          label: savedPreset.name,
          value: savedPreset.name
        })
        setMessage({
          message: t('filters.presetSaveSuccess'),
          type: MessageType.SUCCESS
        })
      })
      .catch(handleError)
      .finally(() => setModal(null))
  }

  const deleteFilter = (name: string) => {
    deleteCostFilter(name, createCancelToken().token)
      .then(() => {
        setSelectedPreset(null)
        setSavedPresets([...savedPresets].filter(preset => preset.name !== name))
        setMessage({
          message: t('filters.presetDeleteSuccess'),
          type: MessageType.SUCCESS
        })
      })
      .catch(handleError)
      .finally(() => setModal(null))
  }

  const options: FilterMenu[] = [
    {
      type: FilterType.VENDORS,
      value: selectedVendors,
      options: vendorOptions,
      onChange: setSelectedVendors
    },
    {
      type: FilterType.PROJECTS,
      value: selectedProjects,
      options: filterOptions?.projectOptions ?? [],
      onChange: setSelectedProjects
    }
  ]

  const selectedOptions: SelectedFilterOption[] = [
    {
      type: FilterType.VENDORS,
      options: selectedVendors.length > 0 ? selectedVendors : vendorOptions,
      clickHandler: setSelectedVendors
    },
    {
      type: FilterType.PROJECTS,
      options: selectedProjects,
      clickHandler: setSelectedProjects
    }
  ]

  if (authInfo.azureIntegration) {
    options.push({
      type: FilterType.RESOURCE_GROUPS,
      value: selectedResourceGroups,
      options: filterOptions?.resourceGroupOptions ?? [],
      onChange: setSelectedResourceGroups
    })

    selectedOptions.push({
      type: FilterType.RESOURCE_GROUPS,
      options: selectedResourceGroups,
      clickHandler: setSelectedResourceGroups
    })
  }

  options.push(
    {
      type: FilterType.SERVICES,
      value: selectedServices,
      options: filterOptions?.serviceOptions ?? [],
      onChange: setSelectedServices
    },
    {
      type: FilterType.TAGS,
      value: selectedTags,
      options: filterOptions?.tagOptions ?? [],
      onChange: setSelectedTags
    },
    {
      type: FilterType.CURRENCY,
      value: [selectedCurrency],
      options: filterOptions?.currencyOptions ?? [],
      onChange: setSelectedCurrency
    }
  )

  selectedOptions.push(
    {
      type: FilterType.SERVICES,
      options: selectedServices,
      clickHandler: setSelectedServices
    },
    {
      type: FilterType.TAGS,
      options: selectedTags,
      clickHandler: setSelectedTags
    },
    {
      type: FilterType.CURRENCY,
      options: [selectedCurrency],
      clickHandler: setSelectedCurrency
    }
  )

  return (
    <Filters
      filterMenuOptions={options}
      selectedOptions={selectedOptions}
      saveAction={saveFilter}
      deleteAction={deleteFilter}
      selectedPreset={selectedPreset}
      setSelectedPreset={setSelectedPreset}
      savedPresets={savedPresets.map(preset => preset.name)}
      loading={loading}
    />
  )
}
