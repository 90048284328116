import React, { useEffect, useState } from 'react'
import { VendorTabs } from '../../shared/tabs/VendorTabs'
import { TabContent, TabsContainer } from '../../shared/tabs/TabSharedComponents'
import { Vendor } from '../../../utils/vendors'
import { OptimizationSummaryTab } from './OptimizationSummaryTab'
import { OptimizationVendorTab } from './OptimizationVendorTab'
import { getTopRecommendations, TopRecommendations } from '../../../api/optimization/shared'
import axios from 'axios'
import { useCancelToken } from '../../../api/client'
import { useErrorHandling } from '../../../hooks/handleError'
import { AwsRecommendations, getOptimizationAwsTabData } from '../../../api/optimization/aws'
import { AzureRecommendations, getOptimizationAzureTabData } from '../../../api/optimization/azure'
import { GcpRecommendations, getOptimizationGcpTabData } from '../../../api/optimization/gcp'
import { CurrencyId } from '../../../utils/Currency'

interface TabsProps {
  selectedVendors: Vendor[]
  selectedProjects: string[]
  selectedRecommendations: string[]
  selectedCurrency: CurrencyId
}

export const OptimizationTabs = ({
  selectedVendors,
  selectedProjects,
  selectedRecommendations,
  selectedCurrency
}: TabsProps) => {
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const [loading, setLoading] = useState(true)
  const [topRecommendations, setTopRecommendations] = useState<TopRecommendations | null>(null)
  const [awsRecommendations, setAwsRecommendations] = useState<AwsRecommendations | null>(null)
  const [azureRecommendations, setAzureRecommendations] = useState<AzureRecommendations | null>(null)
  const [gcpRecommendations, setGcpRecommendations] = useState<GcpRecommendations | null>(null)
  const [selectedTab, setSelectedTab] = useState<Vendor>(Vendor.ALL)
  const isSummaryTab = selectedTab === Vendor.ALL

  useEffect(() => {
    const cancelToken = createCancelToken()
    setLoading(true)
    Promise.all([
      getTopRecommendations(
        selectedVendors,
        selectedProjects,
        selectedRecommendations,
        selectedCurrency,
        cancelToken.token
      ).then(setTopRecommendations),
      getOptimizationAwsTabData(
        selectedVendors,
        selectedProjects,
        selectedRecommendations,
        selectedCurrency,
        cancelToken.token
      ).then(setAwsRecommendations),
      getOptimizationAzureTabData(
        selectedVendors,
        selectedProjects,
        selectedRecommendations,
        selectedCurrency,
        cancelToken.token
      ).then(setAzureRecommendations),
      getOptimizationGcpTabData(
        selectedVendors,
        selectedProjects,
        selectedRecommendations,
        selectedCurrency,
        cancelToken.token
      ).then(setGcpRecommendations)
    ])
      .catch(error => {
        if (!axios.isCancel(error)) handleError(error)
        setLoading(false)
      })
      .finally(() => setLoading(false))

    return () => {
      setLoading(false)
      cancelToken.cancel()
    }
  }, [createCancelToken, handleError, selectedProjects, selectedRecommendations, selectedVendors, selectedCurrency])

  return (
    <TabsContainer>
      <VendorTabs
        id={'qa-optimization'}
        showSummaryTab={true}
        selectedVendor={selectedTab}
        handleSelection={setSelectedTab}
      />
      <TabContent verticalPadding={isSummaryTab}>
        {isSummaryTab ? (
          <OptimizationSummaryTab loading={loading} recommendations={topRecommendations} />
        ) : (
          <OptimizationVendorTab
            vendor={selectedTab}
            selectedVendors={selectedVendors}
            awsRecommendations={awsRecommendations}
            azureRecommendations={azureRecommendations}
            gcpRecommendations={gcpRecommendations}
          />
        )}
      </TabContent>
    </TabsContainer>
  )
}
